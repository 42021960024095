/* eslint-disable react/no-unknown-property */
import { useThree } from '@react-three/fiber';
import { useMemo } from 'react';
import createStyledText from 'canvas-text';
import { CanvasTexture, SRGBColorSpace, sRGBEncoding } from 'three';
import { Plane } from '@react-three/drei';

function getLines(ctx, text, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = ctx.measureText(currentLine + ' ' + word).width;
    if (width < maxWidth) {
      currentLine += ' ' + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}

export function RenderText({
  fontSizeRatio = 1,
  fontSize = 22,
  aspect = 2,
  border = false,
  prefix = '',
  text = `Which year did T-Mobile merged with Sprint?`,
  hScale = 0.18,
  children = null,
  weight = 700,
  lineSpacing = 15,
  hover,
  constrainWidth
}) {
  const { texture } = useMemo(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 512 * aspect;
    canvas.height = 512 * hScale;

    if (hover) {
      context.fillStyle = '#E20074';
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    const primary = {
      size: fontSize * fontSizeRatio,
      fillStyle: 'black',
      weight: weight,
    };

    let parsedText = text;
    if (prefix){
      parsedText = `${prefix} ${text}`;
    }
    let lines = [];
    if (constrainWidth) {
      context.font = `${primary.weight} ${primary.size}px "teleneo_office", ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif`;
      lines = getLines(context, text, constrainWidth);
      parsedText = lines.join('\n');
    }

    const chunks = [
      {
        ...primary,
        text: parsedText,
      }
    ];

    // if (prefix) {
    //   chunks.unshift({
    //     prefix: true,
    //     text: prefix,
    //     size: 22 * fontSizeRatio,
    //     fillStyle: hover ? '#ffffff' : '#E20074',
    //     strokeStyle: hover ? '#ffffff' : '#E20074',
    //   });
    // }

    const styledText = createStyledText(context, chunks, {
      lineSpacing: lineSpacing,
      family: '"Ubuntu", sans-serif',
    });

    const texture = new CanvasTexture(canvas);
    texture.generateMipmaps = true;
    texture.encoding = sRGBEncoding;
    // we can tailor the chunk rendering to our application
    // e.g. animations, fancy underlines, etc
    function renderText(context, str, x, y, textWidth, lineHeight, attribute) {
      context.fillStyle = attribute.fillStyle;

      // if (attribute.underline) {
      //   context.beginPath();
      //   context.strokeStyle = attribute.strokeStyle || attribute.fillStyle;
      //   context.lineWidth = 1;
      //   context.lineCap = 'round';
      //   context.lineJoin = 'round';

      //   // const offY = y + 13;
      //   // dashLine(context, [x, offY + lineHeight], [x + textWidth, offY + lineHeight], 8);
      //   context.stroke();
      // }

      context.font = `${attribute.weight} ${attribute.size}px "teleneo_office", ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif`;

      const halfWidth = canvas.width / 2;
      const halfHeight = canvas.height / 2;

      console.info('attribute:', attribute);

      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(
        str,
        x * 1 + halfWidth + (attribute.prefix ? textWidth : 0),
        y + halfHeight + (attribute.size * 0.2)
      );
    }

    styledText.render(0, 0, renderText);

    if (border) {
      const bw = canvas.width;
      const bh = 100;
      context.strokeStyle = 'black';
      context.strokeWidth = 8;
      const px = 6;
      const py = 6;
      context.strokeRect(px, py, canvas.width - px, canvas.height - py);
    }

    texture.needsUpdate = true;
    return {
      canvas,
      texture,
    };
  }, [text, border, aspect, fontSize, fontSizeRatio, hover, constrainWidth, weight, lineSpacing]);

  return (
    <Plane args={[aspect * 1.0, 1 * hScale]} scale={0.5} position={[0, 0, 0.07]}>
      <meshBasicMaterial color="white" map={texture} transparent={true} depthTest={false} opacity={1} />
      {children}
    </Plane>
  );
}

export const heightAtDepth = (depth, camera) => {
  // compensate for cameras not positioned at z=0
  // const cameraOffset = camera.position.z;
  // if (depth < cameraOffset) depth -= cameraOffset;
  // else depth += cameraOffset;

  // vertical fov in radians
  const vFOV = (camera.fov * Math.PI) / 180;

  // Math.abs to ensure the result is always positive
  return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
};

export const widthAtDepth = (depth, camera) => {
  const height = heightAtDepth(depth, camera);
  return height * camera.aspect;
};
