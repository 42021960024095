import { Center, Plane } from '@react-three/drei';
import { Interactive, useXR } from '@react-three/xr';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CanvasTexture, DoubleSide, FrontSide, Vector2 } from 'three';
import createStyledText from 'canvas-text';

export function VRGenericButton({ onSelectHandler, fontSize = 25, text, size, bgColor, color, ...props }) {

  const btnSize = useMemo(() => {
    if (size instanceof Vector2){
      return size;
    } else {
      return (new Vector2(size.x, size.y));
    }
  }, [size]);

  const [canvas, setCanvas] = useState();
  useEffect(() => {
    const c = document.createElement('canvas');
    const aspect = btnSize.y / btnSize.x;
    c.width = 256;
    c.height = 256 * aspect;
    setCanvas(c);
  }, [btnSize, setCanvas]);

  const { texture } = useMemo(() => {
    if (!canvas) return {};
    const context = canvas.getContext('2d');

    const primary = {
      size: fontSize,
      fillStyle: color,
      weight: 500,
    };

    const chunks = [
      {
        ...primary,
        marginLeft: 0,
        fillStyle: color,
        text: text,
      }
    ];

    const family = `800 ${primary.size}px "teleneo_office", ui-sans-serif, system-ui, -apple-system,${`
    `}BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif`;

    const styledText = createStyledText(context, chunks, {
      lineSpacing: 0,
      family: family,
      //fontWeight: 800
    });

    const texture = new CanvasTexture(canvas);
    texture.generateMipmaps = true;
    // we can tailor the chunk rendering to our application
    // e.g. animations, fancy underlines, etc
    function renderText(context, str, x, y, textWidth, lineHeight, attribute) {
      context.fillStyle = attribute.fillStyle;

      // if (attribute.underline) {
      //   context.beginPath();
      //   context.strokeStyle = attribute.strokeStyle || attribute.fillStyle;
      //   context.lineWidth = 1;
      //   context.lineCap = 'round';
      //   context.lineJoin = 'round';

      //   // const offY = y + 13;
      //   // dashLine(context, [x, offY + lineHeight], [x + textWidth, offY + lineHeight], 8);
      //   context.stroke();
      // }

      context.font = family;
      context.fillStyle = attribute.fillStyle;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.letterSpacing = '2px';
      context.fillText(
        str,
        attribute.marginLeft + x + canvas.width / 2,
        y + canvas.height / 2 + 2
      );
    }
    context.fillStyle = bgColor;
    context.fillRect(0, 0, canvas.width, canvas.height);

    styledText.render(0, 0, renderText);

    // if (border) {
    //   const bw = canvas.width;
    //   const bh = 100;
    //   context.strokeStyle = 'black';
    //   context.strokeWidth = 3;
    //   const px = 6;
    //   const py = 6;
    //   context.strokeRect(px, py, canvas.width - px, canvas.height - py);
    // }

    texture.needsUpdate = true;

    return {
      canvas,
      texture,
    };
  }, [canvas, bgColor, color, text]);

  const material = useRef();

  //
  return (
    <group {...props}>
      <Interactive
        //
        onBlur={(ev) => {
          material.current.opacity = 1;
        }}
        onHover={(ev) => {
          material.current.opacity = 0.7;
        }}
        onSelect={() => {
          onSelectHandler && onSelectHandler();
        }}
      >
        <Plane position={[0, 0, 0]} args={[btnSize.x, btnSize.y]}>
          {texture && (
            <meshBasicMaterial
              ref={material}
              side={FrontSide}
              color={'#ffffff'}
              transparent={true}
              map={texture} />
          )}
        </Plane>
      </Interactive>
    </group>
  );
}
