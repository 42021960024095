import { useTexture } from '@react-three/drei';
import { useEffect } from 'react';
import { EquirectangularReflectionMapping, sRGBEncoding } from 'three';

export default function useEquirectTexture(url){
  const tex = useTexture(url);

  useEffect(() => {
    if (tex) {
      tex.mapping = EquirectangularReflectionMapping;
      tex.encoding = sRGBEncoding;
    }
  }, [tex]);

  return tex;
}
