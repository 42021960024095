export function createSharePopUp(e) {
  const popupSize = {
    width: 780,
    height: 550,
  };
  const verticalPos = Math.floor((window.innerWidth - popupSize.width) / 2);
  const horisontalPos = Math.floor((window.innerHeight - popupSize.height) / 2);

  const popup = window.open(
    e.target.getAttribute('href'),
    'social',
    'width=' +
      popupSize.width +
      ',height=' +
      popupSize.height +
      ',left=' +
      verticalPos +
      ',top=' +
      horisontalPos +
      ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1'
  );

  if (popup) {
    popup.focus();
    e.preventDefault();
  }
}
