import { Center, Plane } from '@react-three/drei';
import { createPortal, useFrame, useThree } from '@react-three/fiber';
import { Interactive, useXR } from '@react-three/xr';
import { useMemo, useRef } from 'react';
import { Object3D, Vector3 } from 'three';
import { heightAtDepth, RenderText, widthAtDepth } from '../RenderText/RenderText';

export function VRStickyUI({ children, lerp = 0.1 }) {
  const ref = useRef();
  const camera = useThree((s) => s.camera);
  const { target, targetGP, camGP } = useMemo(() => {
    const target = new Object3D();

    target.position.z = -0.07;
    camera.add(target);
    const targetGP = new Vector3();
    const camGP = new Vector3();
    return { target, targetGP, camGP };
  }, [camera]);

  useFrame(() => {
    camera.getWorldPosition(camGP);
    target.getWorldPosition(targetGP);
    // targetGP.y = camera.position.y;
    ref.current.position.lerp(targetGP, lerp);
    ref.current.lookAt(camGP.x, camGP.y, camGP.z);
  });

  return (
    <group>
      <group ref={ref}>{children}</group>
    </group>
  );
}
