import { useCallback, useEffect, useRef } from 'react';
import { Color, Fog, Vector2 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { useControls } from 'leva';
import { useDetectGPU } from '@react-three/drei';
import useBus from 'use-bus';
import useEquirectTexture from '@hooks/use-equirect-texture.js';
import { TEX_SNOWFALL_HDRI_URL, TEX_STUDIO_HDRI_URL } from '@settings/settings.urls.js';
import { commonMaterials } from '@components/canvas/common-materials.js';
import { useCanvasStore } from '@store/index.js';
import { useRafPerf } from '@hooks/use-raf-perf.js';
import useAppStore from '@store/_app.js';
import usePerformanceSettings from '@hooks/use-performance-settings.js';
import { EVENT_GL_COMPILE } from '@settings/settings.events.js';
// import DrawCallInspector from '../../vendor/three/DrawCallInspector.js';

// originally 70
const CAM_FOV = 55;

export default function CanvasSetup() {
  const camera = useThree((s) => s.camera);
  const scene = useThree((s) => s.scene);
  const gl = useThree((s) => s.gl);
  const invalidate = useThree((s) => s.invalidate);
  const view = useAppStore((state) => state.view);

  const addEnvMap = useCanvasStore((s) => s.addEnvMap);

  const perfSettings = usePerformanceSettings();

  useEffect(() => {
    console.log('CANVAS MOUNTED');
  }, []);

  const studioHDRI = useEquirectTexture(TEX_STUDIO_HDRI_URL);
  useEffect(() => {
    if (studioHDRI) {
      commonMaterials.magenta.envMap = studioHDRI;
      commonMaterials.magenta.needsUpdate = true;
      scene.environment = studioHDRI;
    }
  }, [studioHDRI, scene]);

  useEffect(() => {
    const fogColor = new Color('#ed51a0').offsetHSL(0, 0, 0.15);
    const environmentColor = new Color('#000000');
    scene.fog = new Fog(fogColor, perfSettings.camera.far * 0.6, perfSettings.camera.far);
    //scene.background = environmentColor;
    scene.background = fogColor;
  }, [scene, perfSettings]);

  const { fov } = useControls('Camera', {
    fov: {
      value: perfSettings.camera.fov,
      min: 20,
      max: 150,
      step: 1,
    },
  });

  useEffect(() => {
    camera.fov = fov;
    camera.near = 0.1;
    camera.far = perfSettings.camera.far;
    camera.updateProjectionMatrix();
  }, [fov, camera, perfSettings]);

  // useFrame(() => {
  // camera.near = 0.5;
  // camera.far = 35;
  // camera.fov = 90;
  // camera.updateProjectionMatrix();

  // camera.near = 1.0;
  // camera.far = 35;
  // camera.fov = 70;
  // camera.updateProjectionMatrix();
  // });

  // const dciInstance = useRef();
  // useEffect(() => {
  //   const dci = new DrawCallInspector( gl, scene, camera, {} );
  //   dci.mount();
  //   dciInstance.current = dci;
  // }, []);

  const rafLoop = useCallback(
    function mainRenderLoop() {
      invalidate();
    },
    [invalidate]
  );
  const perfLoop = useCallback(function mainRafPerfPerformance(ratio) {
    if (ratio < 0.9) {
      console.info('ratio:', ratio);
    }
  }, []);
  // const [start, stop] = useRafPerf(rafLoop, 60, perfLoop);
  // useEffect(() => {
  //   start && start();
  //   return () => {
  //     stop();
  //   };
  // }, [start, stop]);

  const { hologramActive } = useAppStore((state) => ({
    hologramActive: state.hologramActive,
  }));

  // Compile scene and camera
  useBus(EVENT_GL_COMPILE, () => {
    gl.compile(scene, camera);
  }, [scene, camera, gl]);

  useFrame(function mainCanvasLoop({ gl, camera, scene }) {
    if (view !== 'metaverse' && view !== 'onboarding'){
      // Skip rendering when not on the metaverse section
      return;
    }
    if (hologramActive && hologramActive.playerAPI) {
      hologramActive.playerAPI.beginRenderFrame();
      hologramActive.playerAPI.update();
      if (!hologramActive.playerAPI.render()) {
        // console.warn('!!! failed to render player');
      }
      gl.render(scene, camera);
      hologramActive.playerAPI.endRenderFrame();
      //console.log('RENDER PLAYER');
    } else {
      gl.render(scene, camera);
      //console.log('RENDER');
    }
  }, 1);

  return <></>;
}
