import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './IconTwitter.scss';

const propTypes = {
  className : PropTypes.string,
};

const defaultProps = {
  className : '',
};

const IconTwitter = (props) => {
  const { className } = props;

  return (
    <div className={classnames('IconTwitter', className)}>
      <svg width="18" height="14"
        viewBox="0 0 18 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.1683 3.4747C16.8728 2.96867 17.5068 2.36145 18 1.65301C17.3659 1.92289 16.6262 2.1253 15.8865 2.19277C16.6614 1.75422 17.225 1.07952 17.5068 0.236145C16.8023 0.640964 15.9922 0.944578 15.182 1.11325C14.4775 0.404819 13.5264 0 12.4697 0C10.4266 0 8.77104 1.58554 8.77104 3.54217C8.77104 3.81205 8.80626 4.08193 8.87671 4.35181C5.81213 4.18313 3.06458 2.76626 1.23288 0.640964C0.915851 1.14699 0.739726 1.75422 0.739726 2.42892C0.739726 3.64337 1.37378 4.72289 2.3953 5.36386C1.79648 5.33012 1.19765 5.19518 0.704501 4.9253V4.95904C0.704501 6.67952 1.9726 8.09639 3.6634 8.43373C3.3816 8.5012 3.02935 8.56867 2.71233 8.56867C2.46575 8.56867 2.2544 8.53494 2.00783 8.5012C2.46575 9.91807 3.83953 10.9301 5.45988 10.9639C4.19178 11.9084 2.60665 12.4819 0.880626 12.4819C0.563601 12.4819 0.2818 12.4482 0 12.4145C1.62035 13.4265 3.55773 14 5.67123 14C12.4697 14 16.1683 8.63614 16.1683 3.94699C16.1683 3.77831 16.1683 3.64337 16.1683 3.4747Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

IconTwitter.propTypes = propTypes;
IconTwitter.defaultProps = defaultProps;

export default IconTwitter;
