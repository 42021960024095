import { Box } from '@react-three/drei';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { DoubleSide, FrontSide, Ray, Vector3 } from 'three';
import { dispatch } from 'use-bus';
import { useRafPerf, useFramePerf } from '@hooks/use-raf-perf.js';
import useAppStore from '@store/_app.js';
import { EVENT_NAVIGATE_TO_MARKER } from '@settings/settings.events.js';
import { DEBUG_MODE } from '@settings/settings.params.js';
import { ControlState } from '@components/canvas/Controls/PlayerViewControls.jsx';

const BOX_SIZE = 0.2;

export default function PlayerViewControlsDebug({ controls }){

  const { setHologramActive } = useAppStore((state) => ({
    setHologramActive: state.setHologramActive,
  }));

  const rootGroupRef = useRef();
  const targetDummy = useRef();
  const rayDummy = useRef();
  const cameraRay = useMemo(() => {
    return new Ray();
  }, []);
  const navigationDummy = useRef();
  const orbitDummy = useRef();

  const dummyTargetPos = useMemo(() => {
    return new Vector3();
  }, []);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === 'c') {
        // Find the ray direction of the camere and give us the target that is
        // 0.01 away from the camera in the look direction
        cameraRay.origin.copy(controls.current.camera.position);
        controls.current.camera.getWorldDirection(cameraRay.direction);
        const targetPosition = cameraRay.at(0.01, rayDummy.current.position);
        console.log('Cam Position:', controls.current.camera.position.toArray().toString());
        console.log('Target Position:', targetPosition.toArray().toString());
      }
    };
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  const rafLoop = useCallback(() => {
    if (controls.current){
      targetDummy.current && controls.current.getTarget(targetDummy.current.position);
    }
  }, [cameraRay]);
  const [start, stop] = useFramePerf(rafLoop, { fps: 30, name: 'view-controls-debug' });
  useEffect(() => {
    start && start();
    return () => {
      stop();
    };
  }, [start, stop]);

  const setHologramAsActive = useCallback(() => {
    const center = new Vector3();
    orbitDummy.current.geometry.computeBoundingBox();
    orbitDummy.current.geometry.boundingBox.getCenter(center);
    orbitDummy.current.localToWorld(center);

    setHologramActive({
      id: 'debug',
      boundObj: orbitDummy.current,
      centerPos: center,
      rotation: orbitDummy.current.rotation.toArray(),
    });
  }, [setHologramActive, orbitDummy]);

  const onNavigationMarkerClick = useCallback((ev) => {
    console.info('navigationDummy.current:', navigationDummy.current);
    const position = new Vector3();
    navigationDummy.current.getWorldPosition(position);
    const payload = { position: position.toArray(), direction: ev.ray.direction };
    console.info('payload:', payload);
    dispatch({ type: EVENT_NAVIGATE_TO_MARKER,
      payload });
  }, [navigationDummy]);

  return (
    <>
      <Box args={[BOX_SIZE, BOX_SIZE, BOX_SIZE]} ref={rayDummy}>
        <meshBasicMaterial color={'yellow'} />
      </Box>
      {/*<Box args={[BOX_SIZE, BOX_SIZE, BOX_SIZE]} ref={targetDummy}>*/}
      {/*  <meshBasicMaterial color={'red'} />*/}
      {/*</Box>*/}
      <group ref={rootGroupRef} position={[15, 2, -7]}>
        <Box onPointerDown={(ev) => {
          onNavigationMarkerClick(ev);
        }} position={[2, 0, 0]} args={[BOX_SIZE, BOX_SIZE * 2, BOX_SIZE]} ref={navigationDummy}>
          <meshBasicMaterial color={'green'} />
        </Box>
        <Box rotation={[0, 1, 0]} onPointerDown={(ev) => {
          setHologramAsActive(ev);
        }} position={[-3, 1, 0]} args={[BOX_SIZE, BOX_SIZE * 3, BOX_SIZE]} ref={orbitDummy}>
          <meshBasicMaterial color={'purple'} />
        </Box>
      </group>
    </>
  );
}
