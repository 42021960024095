import { Box } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Object3D, Ray, Vector3 } from 'three';
import gsap from 'gsap';
import { button, monitor, useControls } from 'leva';
import useBus, { dispatch } from 'use-bus';
import useEffectWithPrevious from 'use-effect-with-previous';
import { useCanvasStore, useUserSessionStore } from '@store/index.js';
import useAppStore from '@store/_app.js';
import { CameraControls, CameraControlsClass } from '@components/canvas/CanvasControls/CameraControls';
import { DEBUG_MODE, WALK_MODE } from '@settings/settings.params';
import {
  EVENT_ANIMATE_IN_TO_INITIAL_CAMERA_POS,
  EVENT_NAVIGATE_TO_MARKER, EVENT_UPDATE_CAMERA
} from '@settings/settings.events.js';
import { ROUTES } from '@settings/settings.app.js';
import { useRafPerf, useFramePerf } from '@hooks/use-raf-perf.js';
import PlayerViewControlsDebug from '@components/canvas/Controls/PlayerViewControlsDebug';
import usePerformanceSettings from '@hooks/use-performance-settings.js';
import deviceSpecificSettings from '@settings/settings.device-specific.js';

export const ControlState = {
  keyForward: false,
  keyBackward: false,
  keyLeft: false,
  keyRight: false,
  keyE: false,
  keyQ: false,
};

const getCamTargetPosition = function(origin, target, out, offset = 0.01){
  out = out || new Vector3();
  const ray = new Ray(origin);
  ray.lookAt(target);
  ray.at(offset, out);
  return out;
};

const getCamTargetFromDirection = function(position, direction, out, offset = 0.01){
  out = out || new Vector3();
  const ray = new Ray(position, direction);
  ray.at(offset, out);
  return out;
};

const ANIM_IN_CAMERA_POSITION = new Vector3(
  -3.6838401658180633, 4.340918084464466, 0.4672242711130876);
const ANIM_IN_CAMERA_TARGET = new Vector3(-3.675146218230042, 4.339676528416749, 0.46244161349235857);

const CAM_INITIAL_POS = new Vector3(-0.5373466680880438, 3.665435088331071, 0.17842031829973462);
const CAM_INITIAL_TARGET = new Vector3(-0.5280218138659372, 3.6627151040433974, 0.17604360224364568);
const CONTROLS_ROTATION_SPEED = deviceSpecificSettings.viewControlsRotationSpeed;

export function PlayerViewControls() {
  const camera = useThree((s) => s.camera);
  const cameraControls = useRef();
  const [animatedIn, setAnimatedIn] = useState(false);
  const setLastMarkerPosition = useUserSessionStore(s => s.setLastMarkerPosition);
  const perfSettings = usePerformanceSettings();

  const [lastMarkerCamPosition, lastMarkerTargetPosition] = useMemo(() => {
    return [new Vector3(), new Vector3()];
  }, []);

  const prevNavMarkerCamPos = useMemo(() => {
    return new Object3D();
  }, []);

  const view = useAppStore((state) => state.view);
  const isExperienceLoaded = useAppStore((state) => state.isExperienceLoaded);
  const { setCameraIsAnimating } = useCanvasStore((state) => ({
    setCameraIsAnimating: state.setCameraIsAnimating,
  }));

  useEffect(() => {
    console.info('view:', view);
    console.info('isExperienceLoaded:', isExperienceLoaded);
    if (!animatedIn && isExperienceLoaded && view === 'metaverse'){
      dispatch(EVENT_ANIMATE_IN_TO_INITIAL_CAMERA_POS);
    }
  }, [isExperienceLoaded, view, animatedIn]);

  // Setup initial CameraControls state
  useEffect(() => {
    if (cameraControls && camera){
      const cc = cameraControls.current;
      // gestures
      cc.mouseButtons.wheel = CameraControlsClass.ACTION.NONE;
      if (!DEBUG_MODE) {
        cc.touches.two = CameraControlsClass.ACTION.NONE;
        cc.touches.three = CameraControlsClass.ACTION.NONE;
      }
      cc.minDistance = cc.maxDistance = 0.01;
      cc.dampingFactor = 0.08; // Animation speed (The damping inertia. The value must be between Math.EPSILON to 1 inclusive. Setting 1 to disable smooth transitions.)
      cc.draggingDampingFactor = 0.15; // The damping inertia while dragging. The value must be between Math.EPSILON to 1 inclusive. Setting 1 to disable smooth transitions.
      cc.minZoom = 1;
      cc.maxZoom = 1;
      cc.azimuthRotateSpeed = - CONTROLS_ROTATION_SPEED; // negative value to invert rotation direction
      cc.polarRotateSpeed   = - CONTROLS_ROTATION_SPEED; // negative value to invert rotation direction
      // cc.setOrbitPoint(ANIM_IN_CAMERA_TARGET.x, ANIM_IN_CAMERA_TARGET.y, ANIM_IN_CAMERA_TARGET.z, false);
      const lastMarkerPosition = useUserSessionStore.getState().lastMarkerPosition;
      if (!useUserSessionStore.getState().activeHolograms.includes('MIKESIEVERT') && lastMarkerPosition && lastMarkerPosition.position && lastMarkerPosition.target){
        //console.info('lastMarkerPosition:', lastMarkerPosition);
        cc.setPosition( lastMarkerPosition.position.x,
          lastMarkerPosition.position.y, lastMarkerPosition.position.z, false );
        cc.setTarget( lastMarkerPosition.target.x, lastMarkerPosition.target.y, lastMarkerPosition.target.z, false );
      } else {
        cc.setPosition(ANIM_IN_CAMERA_POSITION.x, ANIM_IN_CAMERA_POSITION.y, ANIM_IN_CAMERA_POSITION.z, false);
        cc.setTarget(ANIM_IN_CAMERA_TARGET.x, ANIM_IN_CAMERA_TARGET.y, ANIM_IN_CAMERA_TARGET.z, false);
      }
      // cc.rotatePolarTo( ANIM_IN_CAMERA_POLAR_ANGLE, false );
      // cc.rotateAzimuthTo( ANIM_IN_CAMERA_AZIMUTH_ANGLE, false );
      //cc.truck( 0, -1, false );
      cc.update();
      window._cc = cameraControls.current;
    }
  }, [cameraControls, camera]);

  const animateInTransition = useCallback(() => {
    const lastMarkerPosition = useUserSessionStore.getState().lastMarkerPosition;
    if (lastMarkerPosition && lastMarkerPosition.position && lastMarkerPosition.target){
      return;
    }
    if (!animatedIn || DEBUG_MODE) {
      console.log('animateInTransition');
      transitionCameraToPivot({
        position: CAM_INITIAL_POS,
        target: CAM_INITIAL_TARGET
      });
      setAnimatedIn(true);
    }
  }, [animatedIn, setAnimatedIn]);

  // ANIMATE IN
  useBus(EVENT_ANIMATE_IN_TO_INITIAL_CAMERA_POS, () => {
    animateInTransition();
  }, [animateInTransition]);
  useEffectWithPrevious((deps) => {
    const [prevView] = deps;
    // console.info('prevView:', prevView);
    // console.info('view:', view);
    if (prevView === 'landing-end'){
      gsap.delayedCall(0.2, animateInTransition);
    }
  }, [view]);

  useControls( 'CameraControls', {
    camPosition: monitor(() => {
      if (DEBUG_MODE && cameraControls && cameraControls.current) {
        const pos = cameraControls.current.getPosition();
        return `${pos.x},\n${pos.y},\n${pos.z}`;
      }
    }),
    horizontalCamAzimuth: monitor(() => {
      if (DEBUG_MODE && cameraControls && cameraControls.current) {
        return `${cameraControls.current.azimuthAngle}`;
      }
    }),
    verticalCamPolar: monitor(() => {
      if (DEBUG_MODE && cameraControls && cameraControls.current) {
        return `${cameraControls.current.polarAngle}`;
      }
    }),
    animIn: button(() => {
      dispatch(EVENT_ANIMATE_IN_TO_INITIAL_CAMERA_POS);
    })
  } );

  const { hologramActive } = useAppStore((state) => ({
    hologramActive: state.hologramActive,
  }));

  useEffect(() => {
    const unsub = useCanvasStore.subscribe(
      (state) => state.transformControlsActive,
      (value) => {
        if (cameraControls && cameraControls.current){
          cameraControls.current.enabled = !value;
        }
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const orbitStartPosition = useMemo(() => {
    return new Object3D();
  }, []);
  const orbitLookRay = useMemo(() => {
    return new Ray();
  }, []);

  const gl = useThree((s) => s.gl);

  const transitionCameraToPivot = function({ position, target, direction }) {
    const cc = cameraControls.current;
    const currentTarget = new Vector3();
    // Calculate target position
    const targetPos = new Object3D();
    if (target) {
      targetPos.position.copy(target);
    } else if (!target && direction) {
      // targetPos.position.copy(position);
      // const distance = 1;
      // targetPos.translateX(direction.x * distance);
      // targetPos.translateY(direction.y * distance);
      // targetPos.translateZ(direction.z * distance);
      getCamTargetFromDirection(position, direction, targetPos.position);
    }
    cc.getTarget(currentTarget);
    cc.minDistance = cc.maxDistance = 0.01;
    cc.minAzimuthAngle = -Infinity;
    cc.maxAzimuthAngle = Infinity;
    cc.azimuthRotateSpeed = -CONTROLS_ROTATION_SPEED; // negative value to invert rotation direction
    cc.polarRotateSpeed = -CONTROLS_ROTATION_SPEED; // negative value to invert rotation direction
    // gsap.to(cameraControls.current.camera.position, {
    //   x: position.x,
    //   y: position.y,
    //   z: position.z,
    //   duration: 5
    // });
    // gsap.to(cameraControls.current.getTarget(), {
    //   x: targetPos.position.x,
    //   y: targetPos.position.y,
    //   z: targetPos.position.z,
    //   duration: 5
    // });

    setCameraIsAnimating(true);
    cc.lerpLookAt(
      // Camera from
      camera.position.x,
      camera.position.y,
      camera.position.z,
      // Target From
      currentTarget.x,
      currentTarget.y,
      currentTarget.z,
      // Camera to
      position.x,
      position.y,
      position.z,
      // Target To
      targetPos.position.x,
      targetPos.position.y,
      targetPos.position.z,
      1, true).then(() => {
      setCameraIsAnimating(false);
      cc.minAzimuthAngle = -Infinity;
      cc.maxAzimuthAngle = Infinity;
    });
    return [position, targetPos.position];
  };

  // Switch to orbit mode
  useEffectWithPrevious(([prevHologramActive]) => {
    // console.info('prevHologramActive:', prevHologramActive);
    // console.info('hologramActive:', hologramActive);
    if (hologramActive && prevHologramActive && hologramActive.id === prevHologramActive.id){
      return;
    }
    if (hologramActive && hologramActive.centerPos) {
      if (cameraControls) {
        const ORBIT_DISTANCE = 2;
        const cc = cameraControls.current;

        const newTarget = new Vector3();
        newTarget.copy(hologramActive.centerPos);

        const currentTarget = cc.getTarget();

        // Save previous pos/rotation
        prevNavMarkerCamPos.position.copy(camera.position);
        prevNavMarkerCamPos.userData.azimuthAngle = cc.azimuthAngle;
        prevNavMarkerCamPos.userData.polarAngle = cc.polarAngle;
        prevNavMarkerCamPos.userData.prevTarget = (new Vector3()).copy(currentTarget);

        const orbitStartObj = orbitStartPosition;
        orbitLookRay.copy(hologramActive.ray);
        orbitLookRay.origin.y += 0.1; // Raise up the origin a bit
        // Create a ray to look slightly up
        const rayLookUpLookAt = (new Vector3());
        hologramActive.ray.at(ORBIT_DISTANCE, rayLookUpLookAt);
        rayLookUpLookAt.y += 0.5;
        // Change the direction of the original ray
        orbitLookRay.lookAt(rayLookUpLookAt);
        orbitLookRay.at(ORBIT_DISTANCE, orbitStartObj.position);

        cc.azimuthRotateSpeed = CONTROLS_ROTATION_SPEED; // negative value to invert rotation direction
        cc.polarRotateSpeed = CONTROLS_ROTATION_SPEED; // negative value to invert rotation direction

        // Debug
        // orbitStartPosRef.current.position.copy(orbitStartObj.position);

        cc.minDistance = cc.maxDistance = ORBIT_DISTANCE;
        setCameraIsAnimating(true);
        cc.lerpLookAt(
          camera.position.x,
          camera.position.y,
          camera.position.z,
          currentTarget.x,
          currentTarget.y,
          currentTarget.z,
          orbitStartObj.position.x,
          orbitStartObj.position.y,
          orbitStartObj.position.z,
          newTarget.x,
          newTarget.y,
          newTarget.z,
          1,
          true
        ).then(() => {
          setCameraIsAnimating(false);
          if (hologramActive.limitOrbitRange) {
            const halfRange = hologramActive.limitOrbitRange / 2;
            cc.minAzimuthAngle = cc.azimuthAngle - halfRange;
            cc.maxAzimuthAngle = cc.azimuthAngle + halfRange;
          } else if (perfSettings.maxOrbitAngle) {
            const halfRange = perfSettings.maxOrbitAngle / 2;
            cc.minAzimuthAngle = cc.azimuthAngle - halfRange;
            cc.maxAzimuthAngle = cc.azimuthAngle + halfRange;
          } else {
            cc.minAzimuthAngle = -Infinity;
            cc.maxAzimuthAngle = Infinity;
          }
        });
      }
    } else {
      if (prevNavMarkerCamPos && prevNavMarkerCamPos.userData.prevTarget) {
        transitionCameraToPivot({
          position: prevNavMarkerCamPos.position,
          target: prevNavMarkerCamPos.userData.prevTarget,
        });
        prevNavMarkerCamPos.userData.prevTarget = null;
      }
    }
  }, [hologramActive]);

  const navigationMarkerMoveVectorDummy = useMemo(() => {
    return new Vector3();
  }, []);
  useBus(EVENT_NAVIGATE_TO_MARKER, (ev) => {
    if (cameraControls.current){
      const payload = ev.payload;
      if (payload.position) {
        const position = Array.isArray(payload.position) ?
          navigationMarkerMoveVectorDummy.fromArray(payload.position)
          : payload.position;
        const origMarkerPosition = position.clone();
        // Move the camera
        position.y += 1.8;
        //console.info('position:', position);
        // get angle between player and marker position
        const angle = getAngleBetweenTwoPoints(camera.position.x, camera.position.z, position.x, position.z);
        const [newCamPos, newCamTarget] = transitionCameraToPivot({
          position: position,
          azimuthAngle: angle,
          polarAngle: cameraControls.current.polarAngle,
          direction: payload.direction
        });
        // Set last marker position
        lastMarkerCamPosition.copy(newCamPos);
        lastMarkerTargetPosition.copy(newCamTarget);
        setLastMarkerPosition({
          position: lastMarkerCamPosition,
          target: lastMarkerTargetPosition,
          markerPosition: origMarkerPosition,
          rotationY: cameraControls.current.azimuthAngle
        });
      }
    }
  }, [cameraControls, setLastMarkerPosition]);

  const getAngleBetweenTwoPoints = function(ax, ay, bx, by){
    const angleRadians = Math.atan2(by - ay, bx - ax);
    return angleRadians;
  };

  const cameraUpdateLoop = useCallback(function cameraUpdateLoopCB(){

    setTimeout(() => {
      if (cameraControls.current) {
        dispatch({
          type: EVENT_UPDATE_CAMERA,
          payload: {
            position: camera.position,
            direction: camera.getWorldDirection(new Vector3()),
            azimuthAngle: cameraControls.current.azimuthAngle
          },
        });
      }
    }, 0);
  }, [camera, cameraControls]);
  const [start, stop] = useFramePerf(cameraUpdateLoop, { fps: 5, name: 'cam-pos-update' });
  useEffect(() => {
    start && start();
    return () => {
      stop();
    };
  }, [start, stop]);

  useEffect(() => {
    gl.domElement.style.touchAction = 'none';
    gl.domElement.style.userSelect = 'none';
  }, [gl]);

  useEffect(() => {
    if (DEBUG_MODE || WALK_MODE) {
      const downHandler = ({ key }) => {
        if (key === 'w') {
          ControlState.keyForward = true;
        }
        if (key === 's') {
          ControlState.keyBackward = true;
        }

        if (key === 'a') {
          ControlState.keyLeft = true;
        }
        if (key === 'd') {
          ControlState.keyRight = true;
        }

        if (key === 'e') {
          ControlState.keyE = true;
        }
        if (key === 'q') {
          ControlState.keyQ = true;
        }
      };
      const upHandler = ({ key }) => {
        if (key === 'w') {
          ControlState.keyForward = false;
        }
        if (key === 's') {
          ControlState.keyBackward = false;
        }
        if (key === 'a') {
          ControlState.keyLeft = false;
        }
        if (key === 'd') {
          ControlState.keyRight = false;
        }
        if (key === 'e') {
          ControlState.keyE = false;
        }
        if (key === 'q') {
          ControlState.keyQ = false;
        }
      };
      window.addEventListener('keyup', upHandler);
      window.addEventListener('keydown', downHandler);
      return () => {
        window.removeEventListener('keyup', upHandler);
        window.removeEventListener('keydown', downHandler);
      };
    }
  }, []);

  // Navigate using keys
  useFrame(function navigateUsingKeys(_, dt){

    if ((DEBUG_MODE || WALK_MODE) && cameraControls) {

      const cc = cameraControls.current;
      const MOVE_SPEED = 0.1;

      if (ControlState.keyForward) {
        cc.forward(MOVE_SPEED, true);
      }

      if (ControlState.keyBackward) {
        cc.forward(-MOVE_SPEED, true);
      }

      if (ControlState.keyLeft) {
        cc.truck(-MOVE_SPEED, 0, true);
      }
      if (ControlState.keyRight) {
        cc.truck(MOVE_SPEED, 0, true);
      }

      if (ControlState.keyE) {
        cc.truck(0, -MOVE_SPEED, true);
      }
      if (ControlState.keyQ) {
        cc.truck(0, MOVE_SPEED, true);
      }
    }
  });

  // const orbitStartPosRef = useRef();
  const dummyTargetPos = useRef();

  return (
    <group>
      <CameraControls ref={cameraControls} />
      {DEBUG_MODE && <PlayerViewControlsDebug controls={cameraControls} />}
      {/*Debugging*/}
      {/*<Box ref={orbitStartPosRef} args={[0.1, 0.1, 0.1]}>*/}
      {/*  <meshBasicMaterial color="blue" />*/}
      {/*</Box>*/}
      {/*  */}
    </group>
  );
}
