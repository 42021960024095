import create from 'zustand';
import { subscribeWithSelector, devtools } from 'zustand/middleware';
import { DEBUG_MODE, VR_DEBUG } from '@settings/settings.params.js';

const useAppStore = create(
  subscribeWithSelector((set) => ({
    sound: true,
    setSound: (value = true) => set({ sound: value }),
    toggleSound: () => set((state) => ({ sound: !state.sound })),
    view: 'loading',
    setView: (view) => set({ view: view }),
    ccIsOpen: false,
    setCcIsOpen: (open) => set({ ccIsOpen: open }),
    inXRSession: false,
    setInXRSession: (val) => set({ inXRSession: val }),
    isLoading: true,
    setIsLoading: (value) => set({ isLoading: value }),
    isCanvasAssetsLoaded: false,
    setIsCanvasAssetsLoaded: (value) => set({ isCanvasAssetsLoaded: value }),
    isExperienceLoaded: false,
    setIsExperienceLoaded: (value) => set({ isExperienceLoaded: value }),
    question: '',
    setQuestion: (slug) => set({ question: slug }),
    questionTip: '',
    setQuestionTip: (slug) => set({ questionTip: slug }),
    displayTriviaModal: '',
    setDisplayTriviaModal: (slug) => set({ displayTriviaModal: slug }),
    displayHologram: '',
    setDisplayHologram: (slug) => set({ displayHologram: slug }),
    onBoardingCompleted: true,
    setOnBoardingCompleted: (value = true) => set({ onBoardingCompleted: value }),
    introCompleted: false,
    setIntroCompleted: () => set({ introCompleted: true }),
    triviaHologramComplete: null,
    setTriviaHologramComplete: (slug) => set({ triviaHologramComplete: slug }),
    hologramActive: null,
    setHologramActive: (value) => set({ hologramActive: value }),
    loadedHolograms: [],
    setLoadedHologram: (id) => set((s) => ({ loadedHolograms: [id, ...s.loadedHolograms] })),
    modalActive: false,
    setModalActive: (value) => set({ modalActive: value }),
    pointsAddedActive: false,
    setPointsAddedActive: (value) => set({ pointsAddedActive: value }),
    widgetOpen: true,
    setWidgetOpen: (value) => set({ widgetOpen: value }),
  }))
);

export default useAppStore;
