import './WidgetMap.scss';
import React, { useRef } from 'react';
import classnames from 'classnames';
import { MathUtils } from 'three';
import useBus from 'use-bus';
import gsap from 'gsap';
import hologramsData from '@data/holograms';
import { bounds } from '@data/bounds';
import { EVENT_UPDATE_CAMERA } from '@settings/settings.events.js';
import useUserSessionStore from '@store/_user-session';

const WidgetMap = (props) => {
  const { className } = props;
  const WidgetMapPerson = useRef();
  const WidgetMapPersonView = useRef();
  const WidgetMapRef = useRef();

  const activeHolograms = useUserSessionStore((state) => state.activeHolograms);

  useBus(
    EVENT_UPDATE_CAMERA,
    ({ payload }) => {
      const positionX = MathUtils.mapLinear(
        payload.position.z,
        bounds.leftTop[2],
        bounds.rightTop[2],
        0,
        WidgetMapRef.current.clientWidth
      );
      const positionY = MathUtils.mapLinear(
        payload.position.x,
        bounds.leftBottom[0],
        bounds.leftTop[0],
        0,
        WidgetMapRef.current.clientHeight
      );

      //const cameraAngle = MathUtils.radToDeg(Math.atan2(payload.direction.x, payload.direction.z));
      const cameraAngle = MathUtils.radToDeg(- payload.azimuthAngle - Math.PI / 2);
      // WidgetMapPersonView.current.style = `transform: translateX(-50%) rotate(${-cameraAngle + 90}deg);`;
      // WidgetMapPerson.current.style = `transform: translate(${positionX}px, -${positionY}px);`;
      gsap.to(WidgetMapPersonView.current, { rotationZ: `${cameraAngle}_short`, duration: 0.2, ease: 'power1.inOut' });
      gsap.to(WidgetMapPerson.current, { x: positionX, y: -positionY, duration: 0.2, ease: 'power1.inOut' });
    },
    []
  );

  function Locations() {
    const listItems = hologramsData.map((hologram) => {
      const positionX = MathUtils.mapLinear(hologram.position[2], bounds.leftTop[2], bounds.rightTop[2], 0, 100);
      const positionY = MathUtils.mapLinear(hologram.position[0], bounds.leftBottom[0], bounds.leftTop[0], 0, 100);

      return (
        <div
          key={hologram.id}
          className={classnames('WidgetMap__location', {
            'WidgetMap__location--completed': !activeHolograms.includes(hologram.id),
          })}
          style={{ bottom: `${positionY}%`, left: `${positionX}%` }}
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="0.5" y="0.5" width="11" height="11" stroke="#E20074" />
            <rect x="4" y="4" width="4" height="4" fill="#E20074" />
          </svg>
        </div>
      );
    });
    return <div className="WidgetMap__locations">{listItems}</div>;
  }

  return (
    <div className={classnames('WidgetMap', className)}>
      <div className="WidgetMap__inner">
        <svg
          className="WidgetMap__map__bg"
          enableBackground="new 0 0 516 1106.2"
          viewBox="0 0 516 1106.2"
          xmlns="http://www.w3.org/2000/svg"
          ref={WidgetMapRef}
        >
          <path
            clipRule="evenodd"
            d="m468 344v-203.6h-20.4v-61.1h-81.4-20.4-40.7-122-61.1v20.4h-61.1v320.6h16.3v66.1h-16.3v205h20.4v2.5 9.6 9.4h20.4v99.3h-40.8v244.3h181.1v-74.9h103.9v74.8h122.1v-183.2h-20.4v-79.2h-20.4v-144.7h40.8v-162.9h-40.7v-32.6h24.4v-28.5h-11.5-12.9-40.7v.1h-24.4v28.5h24.4v32.6h-40.7v-.1-40.7h-40.7-40.7-82.3v40.7h-15.4v-66.1h16.3v-218.8h122.1 57v61.1h-57v81.4h81.4v32.6h-24.4v28.4h12.1 12.3 40.7 12.9 11.5v-28.5h-24.4v-32.5zm-244.3 368.8v-63.6h122.3 40.7v144.8h-40.7v146.8h-103.9v-22.8h29.1v-105.9h-88.1-40.7v-99.3z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
        <div className="WidgetMap__person" ref={WidgetMapPerson}>
          <div className="WidgetMap__person__view" ref={WidgetMapPersonView}>
            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0L16 24L32 0H0Z" fill="url(#paint0_radial_587_2149)" />
              <defs>
                <radialGradient
                  id="paint0_radial_587_2149"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(16 24) rotate(-90) scale(26.6667 35.5556)"
                >
                  <stop offset="0.0833333" stopColor="#861B54" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
                </radialGradient>
              </defs>
            </svg>
          </div>
          <div className="WidgetMap__person__marker">
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="8" width="8" height="8" transform="rotate(-90 0 8)" fill="#E20074" />
              <rect x="2" y="6" width="4" height="4" transform="rotate(-90 2 6)" fill="white" />
            </svg>
          </div>
        </div>
        <Locations />
      </div>
    </div>
  );
};

export default WidgetMap;
