import './QuestionModal.scss';
import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { motion, MotionConfig, AnimatePresence } from 'framer-motion';
import { dispatch } from 'use-bus';
import ReactHowler from 'react-howler';
import useAppStore from '@store/_app';
import useUserSessionStore from '@store/_user-session';
import { Sleep } from '@utils/sleep';
import { expoOut } from '@utils/eases';
import { EVENT_ANSWER_CORRECT, EVENT_ANSWER_WRONG, EVENT_WIN_POINTS } from '@settings/settings.events';
import { detection } from '@utils/device';
import Button from '../Button/Button';

const QuestionModal = ({ className, questionProp }) => {
  const hintSound = useRef();
  const hintTimeout = useRef();
  const closeTimeout = useRef();
  const modalActiveTimeout = useRef();
  const answerTimeout = useRef();
  const [active, setActive] = useState(false);
  const [pointerEvents, setPointerEvents] = useState(false);

  const [tipActive, setTipActive] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(true);
  const [answeredQuestion, setAnsweredQuestion] = useState('');
  const [questionIsCorrect, setQuestionIsCorrect] = useState(false);
  const [hintSoundPlaying, setHintSoundPlaying] = useState(false);

  const setQuestion = useAppStore((state) => state.setQuestion);
  const setTriviaHologramComplete = useAppStore((state) => state.setTriviaHologramComplete);
  const setModalActive = useAppStore((state) => state.setModalActive);

  const trackUserAnswer = useUserSessionStore((state) => state.trackUserAnswer);
  const triviaQuestions = useUserSessionStore((state) => state.triviaQuestions);
  const increasePoints = useUserSessionStore((state) => state.increasePoints);
  const trackUserHolograms = useUserSessionStore((state) => state.trackUserHolograms);
  const setUserCharityChoice = useUserSessionStore((state) => state.setUserCharityChoice);

  const close = async () => {
    if (active) {
      clearTimeout(hintTimeout.current);

      setActive(false);
      setTipActive(false);
      setQuestion('');

      await Sleep(100);

      setShowQuestion(false);
      setShowSuccessMessage(false);
    }
  };

  const handleOnClick = (answerSlug) => {
    const triviaQuestion = triviaQuestions.find((question) => question.slug === questionProp.slug);
    if (triviaQuestion.answered) return;

    setAnsweredQuestion(answerSlug);

    console.log('setAnsweredQuestion');

    answerTimeout.current = setTimeout(() => {
      setAnsweredQuestion('');
    }, 500);

    if (triviaQuestion.correct === answerSlug || triviaQuestion.correct === '') {
      clearTimeout(hintTimeout.current);

      dispatch(EVENT_ANSWER_CORRECT);
      setQuestionIsCorrect(true);
      setTriviaHologramComplete(triviaQuestion.slug);
      trackUserHolograms(triviaQuestion.slug);
      setPointerEvents(true);
      setShowQuestion(false);
      setShowSuccessMessage(true);

      console.log('setShowSuccessMessage');

      // charity choice
      if (triviaQuestion.correct === '') {
        const charityChoice = triviaQuestion.answers.find((answer) => answer.label === answerSlug);
        setUserCharityChoice(charityChoice.answer);
      }

      // close modal
      modalActiveTimeout.current = setTimeout(() => {
        console.log('modalActiveTimeout');
        dispatch(EVENT_WIN_POINTS);
        increasePoints(1);
        setModalActive(false);
        trackUserAnswer(triviaQuestion.slug);
      }, 1000);

      // close message
      closeTimeout.current = setTimeout(() => {
        console.log('closeTimeout');

        close();
      }, 3500);
    } else {
      dispatch(EVENT_ANSWER_WRONG);
      clearTimeout(hintTimeout.current);
      hintTimeout.current = setTimeout(() => {
        setHintSoundPlaying(true);
        setTipActive(true);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!active) return;

    clearTimeout(hintTimeout.current);
    clearTimeout(closeTimeout.current);
    clearTimeout(modalActiveTimeout.current);

    setShowQuestion(true);
    setShowSuccessMessage(false);

    hintTimeout.current = setTimeout(() => {
      setHintSoundPlaying(true);
      setTipActive(true);
    }, 7000);

    return () => {
      clearTimeout(hintTimeout.current);
      setHintSoundPlaying(false);
    };
  }, [active]);

  useEffect(() => {
    const unsubscribeActiveCheck = useAppStore.subscribe(
      (state) => state.question,
      (value) => {
        if (value !== questionProp.slug) return;
        setActive(true);
        setPointerEvents(false);
        setModalActive(true);
      }
    );

    const triviaQuestion = triviaQuestions.find((question) => question.slug === questionProp.slug);
    if (triviaQuestion.answered) setQuestionIsCorrect(true);

    return () => {
      unsubscribeActiveCheck();
    };
  }, []);

  useEffect(() => {
    document.body.classList[showQuestion ? 'add' : 'remove']('modal-is-open');
  }, [showQuestion]);

  return (
    <MotionConfig transition={{ duration: 1.8, ease: expoOut }}>
      <AnimatePresence>
        {active && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}e
            exit={{ opacity: 0, duration: 0.5, ease: 'linear' }}
            className={classnames('QuestionModal', { 'QuestionModal--active': !pointerEvents })}
          >
            <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} className="QuestionModal__outer">
              {showQuestion && (
                <motion.div
                  initial={{ paddingBottom: '4rem' }}
                  animate={{ paddingBottom: tipActive ? '10rem' : '4rem' }}
                  className="QuestionModal__inner"
                >
                  <div className="QuestionModal__content">
                    <>
                      <h3>{questionProp.question}</h3>
                      <ul className={`QuestionModal__ul ${questionProp.answered ? 'is-answered' : ''}`}>
                        {questionProp.answers.map((answer) => (
                          <li
                            className={`QuestionModal__li
                                ${questionIsCorrect && answer.label === questionProp.correct ? 'is-active' : ''}
                                ${
                          answeredQuestion === answer.label && answer.label !== questionProp.correct
                            ? 'is-wrong'
                            : ''
                          }
                              `}
                            key={answer.label}
                          >
                            <Button onClick={() => handleOnClick(answer.label)}>
                              <span>{answer.label}.</span>
                              {answer.answer}
                            </Button>
                          </li>
                        ))}
                      </ul>
                      {!tipActive && (
                        <div className="QuestionModal__timer">
                          <div className="QuestionModal__timer__progress"></div>
                        </div>
                      )}
                      <AnimatePresence>
                        {tipActive && (
                          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="QuestionModal__tip">
                            <div className="QuestionModal__tip__label">Hint</div>
                            <div className="QuestionModal__tip__text">{questionProp.tip}</div>
                            <ReactHowler
                              ref={hintSound}
                              src={[
                                `/sounds/hints/webm/${questionProp.slug}.webm`,
                                `/sounds/hints/mp3/${questionProp.slug}.mp3`
                              ]}
                              playing={hintSoundPlaying}
                              onEnd={() => setHintSoundPlaying(false)}
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </>
                  </div>
                </motion.div>
              )}
              <AnimatePresence>
                {showSuccessMessage && (
                  <motion.div
                    className="QuestionModal__message"
                    initial={{ opacity: 0, y: 25 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <div className="QuestionModal__message__inner">
                      <h3>{questionProp.correctTitle}</h3>
                      {Array.isArray(questionProp.correctAnswer)
                        ? (
                          <p>
                            {questionProp.correctAnswer.map((correctAnswer) => {
                              if (correctAnswer.id === answeredQuestion) {
                                return correctAnswer.message;
                              }
                            })}
                          </p>
                        )
                        : (
                          <p>{questionProp.correctAnswer}</p>
                        )}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </MotionConfig>
  );
};

export default QuestionModal;
