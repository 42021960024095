import { useState } from 'react';
import ReactGA from 'react-ga4';
import useEffectWithPrevious from 'use-effect-with-previous';
import useAppStore from '@store/_app.js';

export default function useSetupGA(){
  const view = useAppStore(s => s.view);
  useEffectWithPrevious(([prevView]) => {
    if (prevView !== view){
      if (process.env.ENV !== 'dev') {
        ReactGA.send('pageview');
      }
    }
  }, [view]);
}
