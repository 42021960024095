const triviaContent = [
  {
    slug: 'MIKESIEVERT',
    question: 'Which was the very first smart phone introduced in the U.S.?',
    tip: 'It slid open, and all the cool kids had it',
    correct: 'c',
    correctTitle: 'That\'s right!',
    correctAnswer:
      'The Sidekick was the very first smartphone, and we give it a nod in the name of our on-campus pub, Sidekicks. Go look for more T-Mobile execs for more chances to win prizes!',
    incorrectAnswer: 'Nope, that\'s not it.',
    answered: false,
    answers: [
      { label: 'a', answer: 'iPhone' },
      { label: 'b', answer: 'Blackberry' },
      { label: 'c', answer: 'Sidekick' }
    ],
  },
  {
    slug: 'NESTOR',
    question: 'T-Mobile merged with Sprint to Supercharge the Un-Carrier in what year?',
    correct: 'b',
    correctTitle: 'That\'s right!',
    correctAnswer: '',
    incorrectAnswer: 'You\'re two years off. Try again!',
    answered: false,
    tip: 'It’s also the name of a show that airs on ABC',
    answers: [
      { label: 'a', answer: '2018' },
      { label: 'b', answer: '2020' },
      { label: 'c', answer: '2022' }
    ],
  },
  {
    slug: 'MARCUSEAST',
    question:
      'Since being introduced in 2013, T-Mobile’s Simple Global program has been one of the most popular incentives for travelers. It allows free texting and data from ___ countries and destinations.',
    correct: 'c',
    answered: false,
    tip: 'Hint: It’s pretty much all of them',
    correctTitle: 'That\'s right!',
    correctAnswer: 'From Afghanistan to Zambia, we\'ve got you covered with our Magenta plans.',
    incorrectAnswer: 'Why stop there? Guess higher.',
    answers: [
      { label: 'a', answer: '25' },
      { label: 'b', answer: '50' },
      { label: 'c', answer: '215+' }
    ],
  },
  {
    slug: 'ULFEWALDSSON',
    question:
      'Every wireless provider talks about how great they are, which is why we like to look at third-party sources to give unbiased results. How many awards has T-Mobile received for having the best 5G network?',
    correct: 'c',
    correctTitle: 'Nice!',
    correctAnswer: 'Yeah, it\'s kind of embarrassing for the other guys.',
    incorrectAnswer: 'We did better than that.',
    answered: false,
    tip: 'Hint: Aim high',
    answers: [
      { label: 'a', answer: 'None of them' },
      { label: 'b', answer: 'Some of them' },
      { label: 'c', answer: 'Nearly all of them' }
    ],
  },
  {
    slug: 'PETEREWENS',
    question:
      'We recently announced a partnership that points to a future with "no more dead zones" with which company that makes rockets and spacecraft?',
    correct: 'b',
    correctTitle: 'That\'s right!',
    correctAnswer:
      'T-Mobile and SpaceX have a vision to give customers a crucial additional layer of connectivity in areas previously unreachable by cell signals from any provider.',
    incorrectAnswer: 'Nope. Try again.',
    answered: false,
    tip: 'Hint: it\'s not a color or a person',
    answers: [
      { label: 'a', answer: 'Blue Origin' },
      { label: 'b', answer: 'SpaceX' },
      { label: 'c', answer: 'Bill Nye' }
    ],
  },
  {
    slug: 'CALLIE',
    question: 'Which of the following became T-Mobile for Business customers in 2022?',
    correct: 'c',
    correctTitle: 'That\'s right!',
    correctAnswer:
      'And organizations of all sizes are learning how T-Mobile can help them reimagine what\'s possible with our 5G network.',
    incorrectAnswer: 'Well, that\'s kind of right - but we didn\'t stop there. Pick again!',
    answered: false,
    tip: 'Here\'s a hint: None of these are NOT customers',
    answers: [
      { label: 'a', answer: 'BMW, Alaska Airlines, Lucid Drones' },
      { label: 'b', answer: 'Caribou Coffee, Battle Motors, Spoke Bicycles' },
      { label: 'c', answer: 'All of the Above' }
    ],
  },
  {
    slug: 'JONFREIER',
    question:
      'A big part of our company\'s success in 2022 came from opening up stores and serving customers in "SMRA" - can you guess what that stands for?',
    correct: 'b',
    correctTitle: 'You got it!',
    correctAnswer:
      'T-Mobile covers towns like yours and 99% of people in America. We’re investing in bringing our amazing network and fast, affordable service to small towns and rural areas, because no one should miss out on our best network yet.',
    incorrectAnswer: 'So many wrong answers!',
    answered: false,
    tip: 'Here\'s a hint: It\'s the only one that makes sense',
    answers: [
      { label: 'a', answer: 'Sun Moon Rain Astroids' },
      { label: 'b', answer: 'Small Market Rural Areas' },
      { label: 'c', answer: 'Seems Magical Rarely Arbitrary' }
    ],
  },
  {
    slug: 'JANICEKAPNER',
    question:
      'No trivia from me! We want to donate to one of our favorite charities on your behalf. Where should we make the donation? Your choice!',
    correct: '',
    correctTitle: 'Thanks!',
    correctAnswer: [
      {
        id: 'a',
        message: 'Thanks for supporting our digital empowerment partner, Big Brothers, Big Sisters',
      },

      {
        id: 'b',
        message: 'Thanks for supporting our sustainability partner, Conservation International',
      },

      {
        id: 'c',
        message: 'Thanks for supporting our DE&I partner, The National Urban League',
      }
    ],
    incorrectAnswer: '',
    answered: false,
    tip: 'Go ahead… pick one',
    answers: [
      { label: 'a', answer: 'Big Brothers, Big Sisters' },
      { label: 'b', answer: 'Conservation International' },
      { label: 'c', answer: 'National Urban League' }
    ],
  },
  {
    slug: 'MIKEKATZ',
    question:
      'We thank our customers ever single week with the biggest rewards program of its kind. What is it called?',
    correct: 'b',
    correctTitle: 'Nailed it!',
    correctAnswer:
      'And if you\'re a T-Mobile customer, you can download the Tuesdays app right now and get free stuff every single week!',
    incorrectAnswer: 'Err! Try again.',
    answered: false,
    tip: 'I\'ll give you a hint: Not on Mondays',
    answers: [
      { label: 'a', answer: 'Open Innovation Lab' },
      { label: 'b', answer: 'T-Mobile Tuesdays' },
      { label: 'c', answer: 'Magenta Max' }
    ],
  },
  {
    slug: 'BRIANKING',
    question:
      'We are so confident in our 5G network that we allow people with different wireless providers to try T-Mobile on their existing phone for _____?',
    correct: 'c',
    correctTitle: 'That\'s right.',
    correctAnswer:
      'Seriously, you can try our network, for 90 days, for free, on your eSim-enabled smartphone right now. Just download the T-Mobile app to try it out!',
    incorrectAnswer: 'How about even more days?',
    answered: false,
    tip: 'Hint: Bigger is better',
    answers: [
      { label: 'a', answer: '30 days' },
      { label: 'b', answer: '60 days' },
      { label: 'c', answer: '90 days' }
    ],
  },
  {
    slug: 'DEEANNEKING',
    question:
      'T-Mobile prides itself on Diversity, Equity and Inclusion. We have six unique employee resource groups, an extensive Equity in Action plan, ongoing learning and motto that encourages employees to _____?',
    correct: 'b',
    correctTitle: 'Nice!',
    correctAnswer: 'Yes, #BeYou is our invitation for every kind of person to build their career at T-Mobile.',
    incorrectAnswer: 'How about even more days?',
    answered: false,
    tip: 'I\'ll give you a hint: We often use it as a hashtag',
    answers: [
      { label: 'a', answer: 'Be Bold' },
      { label: 'b', answer: '#BeYou' },
      { label: 'c', answer: 'Be Different' }
    ],
  },
  {
    slug: 'SUSANLOOSEMORE',
    question: 'What\'s our favorite color here at T-Mobile?',
    correct: 'b',
    correctTitle: 'That\'s right',
    correctAnswer: 'Magenta is everywhere on our campus, in our stores, and our team members. We love it!',
    incorrectAnswer: 'How about even more days?',
    answered: false,
    tip: 'Here\'s a hint: it starts with an M',
    answers: [
      { label: 'a', answer: 'Pink' },
      { label: 'b', answer: 'Magenta' },
      { label: 'c', answer: 'Strawberry' }
    ],
  },
  {
    slug: 'MARKNELSON',
    question: 'As the Un-carrier, T-Mobile works to smash customer _____ points',
    correct: 'a',
    correctTitle: 'Correct!',
    correctAnswer: 'The other carriers make it such a pain. We think there\'s a better way.',
    incorrectAnswer: 'Nope. Try again.',
    answered: false,
    tip: 'Here\'s a hint: It hurts us as much as it hurts you',
    answers: [
      { label: 'a', answer: 'Pain' },
      { label: 'b', answer: 'Passion' },
      { label: 'c', answer: 'Access' }
    ],
  },
  {
    slug: 'PETEROSVALDIK',
    question:
      'We call ourselves the Un-carrier because we want to Un-do all of the BS that the other "carriers" establish. What do we call someone who comes to T-Mobile from one of the other guys because they want the best network, best value, and best experience?',
    correct: 'a',
    correctTitle: 'That\'s right!',
    correctAnswer: 'And there are more and more of them every day!',
    incorrectAnswer: 'That\'s not it. Try switching your answer.',
    answered: false,
    tip: 'Here\'s a hint: No dance moves here',
    answers: [
      { label: 'a', answer: 'Switcher' },
      { label: 'b', answer: 'Mover' },
      { label: 'c', answer: 'Shaker' }
    ],
  },
  {
    slug: 'NEVILLERAY',
    question: 'T-Mobile had the fastest growing _______ business in 2022',
    correct: 'a',
    correctTitle: 'Correctomundo!',
    correctAnswer: 'Our reliable 5G home internet service is just $50/month with autopay.',
    incorrectAnswer: 'Really? Come on - this one\'s easy',
    answered: false,
    tip: 'Do you really need a hint?',
    answers: [
      { label: 'a', answer: 'Home Internet Provider' },
      { label: 'b', answer: 'Fidget Spinner' },
      { label: 'c', answer: 'Grilled Cheese Sandwich' }
    ],
  },
  {
    slug: 'ABDULSAAD',
    question:
      'When you\'re connected to our super-fast 5G network, the icon on your phone reads "5G UC". What does the UC stand for?',
    correct: 'b',
    correctTitle: 'That\'s right!',
    correctAnswer: 'And it is smokin\' fast.',
    incorrectAnswer: 'UnCorrect',
    answered: false,
    tip: 'Hint: The one with the most syllables',
    answers: [
      { label: 'a', answer: 'Un-Carrier' },
      { label: 'b', answer: 'Ultra Capacity' },
      { label: 'c', answer: 'Uncle Charlie' }
    ],
  },
  {
    slug: 'JOHNSAW',
    question:
      'T-Mobile hosted the very first TV broadcast shot entirely on mobile devices over a 5G network prior to what event?',
    correct: 'b',
    correctTitle: 'You hit it out of the park!',
    correctAnswer:
      'We\'re proud to be a sponsor of the MLB, and can\'t wait for the All Star Game to come to T-Mobile Park in Seattle in 2023!',
    incorrectAnswer: 'No, but that would have been cool, too',
    answered: false,
    tip: 'Here\'s a hint: It\'s America\'s favorite pasttime',
    answers: [
      { label: 'a', answer: 'Senior League Dodgeball Tournament' },
      { label: 'b', answer: 'MLB Home Run Derby' },
      { label: 'c', answer: 'Regional Thumbwrestling Championship' }
    ],
  }
];

export default triviaContent;
