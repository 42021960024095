import './DesignToggle.scss';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

export const DesignToggle = (props) => {
  const { screen } = props;

  const [active, setActive] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const handleKeyDown = useCallback((e) => {
    //if (e.keyCode === 68) { // d was interfering with AWSD controls
    if (e.key === 'f') { // o for overlay
      if (opacity < 1) {
        setOpacity(opacity + 0.25);
        setActive(true);
      } else {
        setOpacity(0);
        setActive(true);
      }
    }
  }, [setOpacity, opacity]);
  useEffect(() => {
    addEventListener('keydown', handleKeyDown);
    return () => {
      removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div tabIndex={0} onKeyDown={handleKeyDown}
      style={{ opacity }} className="Designtoggle">
      {active && <img src={`/dev/${screen}.webp`} />}
    </div>
  );
};

DesignToggle.propTypes = {
  screen: PropTypes.string,
};
