import RafPerf from 'raf-perf';

// returns a random integer from min to max
function irand(min, max)
{
  return Math.floor((min || 0) + Math.random() * ((max + 1 || 100) - (min || 0)));
}

// returns a random float from min to max
function frand(min, max)
{
  return (min || 0) + Math.random() * ((max || 1) - (min || 0));
}

function clamp(value, min, max)
{
  return Math.min(Math.max(value, min), max);
}

// Two component vector class
function Vector2(x, y)
{
  this.x = x || 0;
  this.y = y || 0;

  this.add = function(other)
  {
    this.x += other.x;
    this.y += other.y;
  };

  this.magnitude = function()
  {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  };
}

function Color(r, g, b)
{
  this.r = r || 0;
  this.g = g || 0;
  this.b = b || 0;
}

//var options = {};
const options = {
  'amount': 100,
  'size': [8, 20],
  'rotation': [1, 5],
  'speed': [40, 80],
  'swing': [0.1, 1],
  'amplitude': [30, 50],
  'alpha': [0.1, 0.95],
  'images': ['http://i.imgur.com/jbSVFgy.png', 'http://i.imgur.com/TT2lmN4.png', 'http://i.imgur.com/do8589m.png', 'http://i.imgur.com/3BxEO8i.png']
};

window.addEventListener('resize', function()
{
  // CanvasSnow.resize();
  // CanvasSnow.init( 'particle_canvas', options );
}, false);

// window.addEventListener('load', function()
// {
//   CanvasSnow.init( 'particle_canvas', options );
//   CanvasSnow.start();
// }, false);

function jsParticle(origin, velocity, size, amplitude, rspeed, alpha, image)
{
  this.origin = origin;
  this.position = new Vector2(origin.x, origin.y);
  this.velocity = velocity || new Vector2(0, 0);
  this.size = size;
  this.rspeed = rspeed;
  this.amplitude = amplitude;
  this.alpha = alpha;
  this.image = image;

  this.dx = Math.random() * 100;
  this.rotation = Math.random() * 360;

  this.update = function(delta_time)
  {
    this.dx += this.velocity.x * delta_time;
    this.position.y += this.velocity.y * delta_time;
    this.position.x = this.origin.x + (this.amplitude * Math.sin(this.dx));
    this.rotation += this.rspeed * delta_time;
  };
}

export class CanvasSnow {
  constructor() {
    this.canvas = null;
    this.ctx = null;
    this.particles = [];
    this.running = false;
    this.pImageObjects = [];
    this.start_time = 0;
    this.frame_time = 0;

    // We're passing these to native functions so need to scope them properly
    this._loop = (dt) => {
      this._clear();
      this._update(dt);
      this._draw();
    };
    this.resize = () => {
      this.canvas.width = window.innerWidth * this.dpr;
      this.canvas.height = window.innerHeight * this.dpr;
    };

    this.engine = new RafPerf({ fps: 30 });
    this.engine.on('tick', this._loop);
  }

  init( canvasEl, options, dpr = 1 )
  {
    // use the container width and height
    this.canvas = canvasEl;
    this.ctx = this.canvas.getContext('2d');
    this.dpr = dpr;
    this.resize(window.innerWidth * dpr, window.innerHeight * dpr);

    // default values
    this.pAmount = options.amount || 500;
    this.pSize = options.size || [8, 26];
    this.pRotation = options.rotation || [-5, 5];
    this.pSwing = options.swing || [0.1, 1];
    this.pSpeed = options.speed || [40, 100],
    this.pAmplitude = options.amplitude || [20, 50];
    this.pAlpha = options.alpha || [0.25, 1];
    this.pImageNames = options.images || [];

    // initialize all the images
    for (let i = 0; i < this.pImageNames.length; i++)
    {
      const image = new Image();
      image.src = this.pImageNames[i];
      this.pImageObjects.push(image);
    }

    this._init_particles();
  }

  start()
  {
    this.engine.start();
  }

  stop()
  {
    this.engine.stop();
  }

  _init_particles()
  {
    // clear the particles array
    this.particles.length = 0;

    for ( let i = 0; i < this.pAmount; i++)
    {
      const origin = new Vector2(frand(0, this.canvas.width), frand(-this.canvas.height, 0));
      const velocity = new Vector2(frand(this.pSwing[0], this.pSwing[1]), frand(this.pSpeed[0], this.pSpeed[1]));
      const size = frand(this.pSize[0], this.pSize[1]);
      const amplitude = frand(this.pAmplitude[0], this.pAmplitude[1]);
      const rspeed = frand(this.pRotation[0], this.pRotation[1]) * ((Math.random() < 0.5) ? -1 : 1);
      const alpha = frand(this.pAlpha[0], this.pAlpha[1]);
      const image = (this.pImageObjects.length > 0) ? irand(0, this.pImageObjects.length - 1) : -1;

      this.particles.push(new jsParticle(origin, velocity, size, amplitude, rspeed, alpha, image));
    }
  }

  _update(delta_time)
  {
    delta_time = delta_time / 1000;
    for ( let i = 0; i < this.particles.length; i++)
    {
      const particle = this.particles[i];
      particle.update(delta_time);

      if (particle.position.y - particle.size > this.canvas.height)
      {
        particle.position.y = -particle.size * 2;
        particle.position.x = particle.origin.x = Math.random() * this.canvas.width;
      }
    }
  }

  _draw()
  {
    this.ctx.fillStyle = 'rgb(255,255,255)';

    for ( let i = 0; i < this.particles.length; i++)
    {
      const particle = this.particles[i];
      const center = -(particle.size / 2);

      this.ctx.save();
      this.ctx.translate(particle.position.x, particle.position.y);
      this.ctx.rotate(particle.rotation);
      this.ctx.globalAlpha = this.particles[i].alpha;

      if ( particle.image === -1)
        this.ctx.fillRect(center, center, particle.size, particle.size);
      else
        this.ctx.drawImage(this.pImageObjects[particle.image], center, center, particle.size, particle.size);

      this.ctx.restore();
    }
  }

  _clear()
  {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  destroy(){
    this.stop();
    this.engine.removeAllListeners('tick');
    this.engine.removeAllListeners('perf');
  }

}
