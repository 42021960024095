/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import create from 'zustand';
import shallow from 'zustand/shallow';
import { subscribeWithSelector } from 'zustand/middleware';
import { PLAYER_CAMERA_MODE_PIVOT } from '@settings/settings.consts.js';

const canvasStore = create( subscribeWithSelector( (set) => ({
  loadProgress: 0,
  setLoadProgress: (val) => set(() => ({ loadProgress: val })),

  playerCameraMode: PLAYER_CAMERA_MODE_PIVOT,
  setPlayerCameraMode: (val) => set(() => ({ playerCameraMode: val })),

  transformControlsActive: false,
  setTransformControlsActive: (val) => set(() => ({ transformControlsActive: val })),

  globalOrbitControls: null,
  setGlobalOrbitControls: (val) => set(() => ({ globalOrbitControls: val })),

  globalOrbitControlsTargetObject: null,
  setGlobalOrbitControlsTargetObject: (val) => set(() => ({ globalOrbitControlsTargetObject: val })),

  globalPlayerObject: null,
  setGlobalPlayerObject: (val) => set(() => ({ globalPlayerObject: val })),

  isLoaded: false,
  setIsLoaded: (val) => set(() => ({ isLoaded: val })),

  cameraIsAnimating: false,
  setCameraIsAnimating: (val) => set(() => ({ cameraIsAnimating: val })),

  envMaps: {},
  addEnvMap: (id, envMap) => set((s) => {
    const envs = s.envMaps;
    envs[id] = envMap;
    return { envMaps: envs };
  }),

  gpuTier: null,
  setGPUTier: (val) => set(() => ({ gpuTier: val })),
})));

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
const useCanvasStore = (cb = null, isShallow = true) => {
  if (cb) {
    if (isShallow) {
      return canvasStore(cb, shallow);
    } else {
      return canvasStore(cb);
    }
  } else {
    return canvasStore();
  }
};

export default canvasStore;
