import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ButtonGroup.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  children: null,
};

const ButtonGroup = ({ className, children }) => {
  return (
    <div className={classnames('ButtonGroup', className)}>
      {children}
    </div>
  );
};

ButtonGroup.propTypes = propTypes;
ButtonGroup.defaultProps = defaultProps;

export default ButtonGroup;
