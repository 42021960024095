import './UseFramePerfDebug.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import _sortBy from 'lodash.sortby';
import { useRafPerf } from '@hooks/use-raf-perf.js';

export function UseFramePerfDebug(){

  useEffect(() => {
    window.__useFramePerf = window.__useFramePerf || {};
  }, []);

  const [useFrameList, setUseFrameList] = useState([]);

  const tempList = useRef([]);

  const rafLoop = useCallback(() => {
    //console.info('window.__useFramePerf:', window.__useFramePerf);
    if (window.__useFramePerf){
      tempList.current = [];
      for (const key in window.__useFramePerf){
        const item = window.__useFramePerf[key];
        if (item.running) {
          tempList.current.push({
            label: item.label,
            execTime: item.execTime ? item.execTime.toFixed(4) : 0,
            id: key,
            running: item.running ? 'on' : ''
          });
        }
      }
      tempList.current = _sortBy(tempList.current, 'execTime').reverse();
      setUseFrameList(tempList.current);
    }
  }, []);

  const [start, stop] = useRafPerf(rafLoop, 1 );
  useEffect(() => {
    start && start();
    return () => {
      stop();
    };
  }, [start, stop]);

  return (
    <div className="UseFramePerfDebug">
      <div className="UseFramePerfDebug__inner">
        <table>
          {useFrameList.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.label}</td>
                <td>{item.execTime}</td>
                {/*<td>{item.running}</td>*/}
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
}
