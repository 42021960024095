import './IconFacebook.scss';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const IconFacebook = (props) => {
  const { className } = props;

  return (
    <div className={classnames('IconFacebook', className)}>
      <svg width="18" height="18"
        viewBox="0 0 18 18" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 0C4.02943 0 0 4.05406 0 9.055C0 13.5745 3.29115 17.3207 7.59375 18V11.6725H5.30859V9.055H7.59375V7.06007C7.59375 4.79066 8.93742 3.53711 10.9932 3.53711C11.9776 3.53711 13.0078 3.71397 13.0078 3.71397V5.94235H11.873C10.7548 5.94235 10.4062 6.64039 10.4062 7.35719V9.055H12.9023L12.5033 11.6725H10.4062V18C14.7088 17.3207 18 13.5745 18 9.055C18 4.05406 13.9706 0 9 0Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

IconFacebook.propTypes = propTypes;
IconFacebook.defaultProps = defaultProps;

export default IconFacebook;
