import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ModalStep.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  children: null,
};

const ModalStep = ({ className, children }) => {
  return (
    <div className={classnames('ModalStep', className)}>
      <div className="ModalStep__inner">{children}</div>
    </div>
  );
};

ModalStep.propTypes = propTypes;
ModalStep.defaultProps = defaultProps;

export default ModalStep;
