// import {
//   Box,
//   Environment,
//   Icosahedron,
//   Sphere,
//   useAnimations,
//   useFBX,
//   useGLTF
// } from '@react-three/drei';
// import { Canvas, createPortal, useFrame, useThree } from '@react-three/fiber';
import { Html, Sphere } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import {
  Controllers,
  Hands,
  Interactive,
  useController,
  useXR,
  useXREvent,
  VRButton,
  XR
} from '@react-three/xr';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DoubleSide, Object3D, Quaternion, Ray, Vector3 } from 'three';
// import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
// import { Quaternion } from 'three';
// import { Gesture } from '@use-gesture/vanilla';
import create from 'zustand';
import gsap from 'gsap';
import useBus from 'use-bus';
import useEffectWithPrevious from 'use-effect-with-previous';
import useCanvasStore from '@store/_canvas';
import useAppStore from '@store/_app.js';
import { DEBUG_MODE, VR_DEBUG, WALK_MODE } from '@settings/settings.params.js';
import { EVENT_NAVIGATE_TO_MARKER } from '@settings/settings.events.js';
import { useUserSessionStore } from '@store/index.js';

const ControlState = {
  guiForward: false,
  guiBackward: false,
  keyForward: false,
  keyBackward: false,
  keyLeft: false,
  keyRight: false,
  keyLeftRotate: false,
  keyRightRotate: false,
  keyE: false,
  keyQ: false,
};

export function XRSessionUpdater({ children }) {
  const {
    // An array of connected `XRController`
    controllers,
    // Whether the XR device is presenting in an XR session
    isPresenting,
    // Whether hand tracking inputs are active
    isHandTracking,
    // A THREE.Group representing the XR viewer or player
    player,
    // The active `XRSession`
    session,
    // `XRSession` foveation. This can be configured as `foveation` on <XR>. Default is `0`
    foveation,
    // `XRSession` reference-space type. This can be configured as `referenceSpace` on <XR>. Default is `local-floor`
    referenceSpace,
  } = useXR();

  const setSession = useVRCore((s) => s.setSession);
  setSession(session);

  return <>{session ? <VR>{children}</VR> : children}</>;
}

export const useVRCore = create((set, get) => {
  return {
    session: false,
    setSession: (v) => {
      set({ session: v });
    },
    ctrler: false,
    setCtrler: (v) => {
      set({ ctrler: v });
    },
    showVRBtn: false,
    setShow: (v) => {
      set({ showVRBtn: v });
    },
  };
});

const MARKER_POSITION_Y_ADD = 0.1;

export function VR({ startAt = [0, 0, 0.1], children }) {
  const showVRBtn = useVRCore((s) => s.showVRBtn);
  const setShow = useVRCore((s) => s.setShow);

  const ctrler = useVRCore((s) => s.ctrler);
  const setCtrler = useVRCore((s) => s.setCtrler);

  const { hologramActive } = useAppStore((state) => ({
    hologramActive: state.hologramActive,
  }));

  const {
    // An array of connected `XRController`
    controllers,
    // Whether the XR device is presenting in an XR session
    isPresenting,
    // Whether hand tracking inputs are active
    isHandTracking,
    // A THREE.Group representing the XR viewer or player
    player,
    // The active `XRSession`
    session,
    // `XRSession` foveation. This can be configured as `foveation` on <XR>. Default is `0`
    foveation,
    // `XRSession` reference-space type. This can be configured as `referenceSpace` on <XR>. Default is `local-floor`
    referenceSpace,
  } = useXR();

  // useXREvent('squeeze', (ev) => {
  //     console.info('ev:', ev);
  // });

  const setGlobalPlayerObject = useCanvasStore((s) => s.setGlobalPlayerObject);
  const globalPlayer = useCanvasStore((s) => s.globalPlayerObject);

  // Set GSAP tick function
  const inXRSession = useAppStore(s => s.inXRSession);
  const useFrameLoop = useCallback((_, dt) => {
    if (inXRSession === true){
      gsap.ticker.tick(dt);
    }
  }, [inXRSession]);
  useFrame(useFrameLoop);

  useEffect(() => {
    // Setup Initial
    if (player) {
      setGlobalPlayerObject(player);
    }
  }, [player, setGlobalPlayerObject]);
  // session

  const initialPlayerPosIsSet = useRef();
  useEffectWithPrevious(([prevInXRSession]) => {
    if (!prevInXRSession && inXRSession){
      const lastMarkerPosition = useUserSessionStore.getState().lastMarkerPosition;
      if (lastMarkerPosition && lastMarkerPosition.markerPosition) {
        player.position.set(
          lastMarkerPosition.markerPosition.x,
          lastMarkerPosition.markerPosition.y + MARKER_POSITION_Y_ADD,
          lastMarkerPosition.markerPosition.z);
        if (lastMarkerPosition.rotationY) {
          player.rotation.set(0, lastMarkerPosition.rotationY, 0);
        }
      } else {
        player.position.set(-0.179745, 1.876984, -0.187347);
        player.rotation.set(0,
          -1.3831853071796614, 0);
      }
      initialPlayerPosIsSet.current = true;
    }
    window._player = player;
  }, [inXRSession, player]);

  // Switch to orbit mode
  const prevNavMarkerCamPos = useMemo(() => {
    return new Object3D();
  }, []);
  const orbitStartPosition = useMemo(() => {
    return new Object3D();
  }, []);
  const orbitLookRay = useMemo(() => {
    return new Ray();
  }, []);
  useEffectWithPrevious(([prevHologramActive]) => {
    console.info('VR prevHologramActive:', prevHologramActive);
    console.info('VR hologramActive:', hologramActive);
    if (hologramActive && prevHologramActive && hologramActive.id === prevHologramActive.id){
      return;
    }
    if (hologramActive && hologramActive.centerPos) {
      const ORBIT_DISTANCE = 2.5;

      const newTarget = new Vector3();
      newTarget.copy(hologramActive.centerPos);

      // Save previous pos/rotation
      if (initialPlayerPosIsSet.current) {
        prevNavMarkerCamPos.position.copy(player.position);
        prevNavMarkerCamPos.userData.positionSet = true;
      }

      const orbitStartObj = orbitStartPosition;
      orbitLookRay.copy(hologramActive.ray);
      orbitLookRay.origin.y -= 1.4; // Raise up the origin a bit
      orbitLookRay.at(ORBIT_DISTANCE, orbitStartObj.position);

      console.info('player.position:', player.position);
      console.info('orbitStartObj.position:', orbitStartObj.position);

      setNewPlayerPositon(orbitStartObj.position);
    } else {
      if (prevNavMarkerCamPos && prevNavMarkerCamPos.userData.positionSet) {
        console.info('VR prevNavMarkerCamPos:', prevNavMarkerCamPos);
        setNewPlayerPositon(prevNavMarkerCamPos.position);
        prevNavMarkerCamPos.userData.positionSet = false;
      }
    }
  }, [hologramActive]);

  const setNewPlayerPositon = function(position, duration = 0.2){
    console.log('VR setNewPlayerPositon', position);
    const anim = gsap.to(player.position, {
      x: position.x,
      y: position.y,
      z: position.z,
      duration,
      ease: 'power1.inOut',
      onComplete: () => {
        anim.kill();
      }
    });
  };

  const playerMoveVectorDummy = useMemo(() => {
    return new Vector3();
  }, []);
  const newPosDummy = useMemo(() => {
    return new Vector3();
  }, []);
  useBus(EVENT_NAVIGATE_TO_MARKER, (ev) => {
    if (player){
      const payload = ev.payload;
      const position = Array.isArray(payload.position) ?
        playerMoveVectorDummy.fromArray(payload.position)
        : payload.position;
      console.info('position:', position);
      newPosDummy.set(position.x, position.y + MARKER_POSITION_Y_ADD, position.z);
      console.info('newPosDummy:', newPosDummy);
      setNewPlayerPositon(newPosDummy);
      // setLastMarkerPosition({
      //   position: newPosDummy.clone(),
      //   rotationY: player.rotation.y
      // });
    }
  }, [player]);

  useEffect(() => {
    if ('xr' in window.navigator) {
      window.navigator.xr.isSessionSupported('immersive-vr').then(
        (v) => {
          setShow(v);
        },
        () => {
          setShow(false);
        }
      );
    }
  }, []);

  const left = useMemo(() => {
    return new Object3D();
  }, []);

  const right = useMemo(() => {
    return new Object3D();
  }, []);

  const rightController = useController('right');
  const lefctController = useController('left');

  // let player = useXR((s) => s.player);
  // let session = useXR((s) => s.session);
  // const pt = useMemo(() => {
  //   return new Vector3(0, 0, 0).fromArray(startAt);
  // }, [startAt]);

  const isDown = useRef(false);

  const temp = useMemo(() => {
    return new Vector3();
  }, []);
  // const chasing = new Vector3();
  // const keydown = useMemo(() => {
  //   const keydown = {
  //     wKey: true,
  //   };

  //   return keydown;
  // }, []);

  const gl = useThree((s) => s.gl);

  const up = useMemo(() => {
    return new Vector3(0, 1, 0);
  }, []);

  // useFrame(({ camera }, dt) => {
  //   if (ControlState.keyBackward || ControlState.guiBackward) {
  //     temp.set(0, 0, 1);
  //     temp.applyAxisAngle(up, proxy.rotation.y);

  //     camera.position.addScaledVector(temp, 10 * dt);
  //   }
  //   if (ControlState.keyForward || ControlState.guiForward) {
  //     temp.set(0, 0, -1);
  //     temp.applyAxisAngle(up, proxy.rotation.y);

  //     camera.position.addScaledVector(temp, 10 * dt);
  //   }
  // });

  useEffect(() => {
    // const x = new Vector3(1, 0, 1);
    gl.domElement.style.touchAction = 'none';
    gl.domElement.style.userSelect = 'none';
    // const gg = new Gesture(
    //   gl.domElement,
    //   {
    //     onDrag: (event) => {
    //       event.cancelable && event.preventDefault();
    //       // /** @type {import("@use-gesture/vanilla").AnyHandlerEventTypes} */
    //       // event = event;

    //       if (event.active) {
    //         proxy.rotation.y += event.delta[0] / 250;
    //       }
    //     }, //Handler<'drag', check<T, 'drag'>>;
    //     onDragStart: (event) => {
    //       event.cancelable && event.preventDefault();
    //       // /** @type {import("@use-gesture/vanilla").AnyHandlerEventTypes} */
    //       // event = event;
    //     }, //Handler<'drag', check<T, 'drag'>>;
    //     onDragEnd: (event) => {
    //       event.cancelable && event.preventDefault();
    //       // /** @type {import("@use-gesture/vanilla").AnyHandlerEventTypes} */
    //       // event = event;
    //     }, //Handler<'drag', check<T, 'drag'>>;
    //   },
    //   {
    //     eventOptions: {
    //       passive: false,
    //     },
    //     drag: {
    //       preventScrollAxis: 'y',
    //       preventDefault: true,
    //       preventScroll: true,
    //     },
    //   }
    // );

    return () => {
      // gg.destroy();
    };
  }, [gl]);

  // useFrame(() => {
  //   if (!session) {
  //     camera.quaternion.slerp(proxy.quaternion, 0.1);
  //   }
  // });

  useEffect(() => {
    if (DEBUG_MODE || WALK_MODE || VR_DEBUG) {
      const downHandler = ({ key, keyCode }) => {
        if (keyCode === 39) {
          ControlState.keyRightRotate = true;
        }
        if (keyCode === 37) {
          ControlState.keyLeftRotate = true;
        }
        if (key === 'p') {
          console.info('player.position:', player.position);
          console.info('player.rotation:', player.rotation);
        }
        if (key === 'w') {
          ControlState.keyForward = true;
        }
        if (key === 's') {
          ControlState.keyBackward = true;
        }

        if (key === 'a') {
          ControlState.keyLeft = true;
        }
        if (key === 'd') {
          ControlState.keyRight = true;
        }

        if (key === 'e') {
          ControlState.keyE = true;
        }
        if (key === 'q') {
          ControlState.keyQ = true;
        }
      };
      const upHandler = ({ key, keyCode }) => {
        if (keyCode === 39) {
          ControlState.keyRightRotate = false;
        }
        if (keyCode === 37) {
          ControlState.keyLeftRotate = false;
        }
        if (key === 'w') {
          ControlState.keyForward = false;
        }
        if (key === 's') {
          ControlState.keyBackward = false;
        }
        if (key === 'a') {
          ControlState.keyLeft = false;
        }
        if (key === 'd') {
          ControlState.keyRight = false;
        }
        if (key === 'e') {
          ControlState.keyE = false;
        }
        if (key === 'q') {
          ControlState.keyQ = false;
        }
      };
      window.addEventListener('keyup', upHandler);
      window.addEventListener('keydown', downHandler);
      return () => {
        window.removeEventListener('keyup', upHandler);
        window.removeEventListener('keydown', downHandler);
      };
    }
  }, []);

  // Navigate using keys
  const moveObj = useMemo(() => {
    return new Object3D();
  }, []);
  useFrame(function navigateUsingKeys(_, dt){

    if ((DEBUG_MODE || WALK_MODE) && globalPlayer) {
      const MOVE_SPEED = 0.1;
      let rotation = 0;
      // moveObj.position.set(0, 0, 0);
      // moveObj.applyAxisAngle(up, player.rotation.y);
      // player.getWorldDirection( temp );

      if (ControlState.keyForward) {
        player.translateZ(-MOVE_SPEED);
      }
      if (ControlState.keyBackward) {
        player.translateZ(MOVE_SPEED);
      }

      if (ControlState.keyLeft) {
        player.translateX(-MOVE_SPEED);
      }
      if (ControlState.keyRight) {
        player.translateX(MOVE_SPEED);
      }

      if (ControlState.keyE) {
        player.translateY(MOVE_SPEED);
      }
      if (ControlState.keyQ) {
        player.translateY(-MOVE_SPEED);
      }
      if (ControlState.keyRightRotate) {
        rotation = -0.05;
      }
      if (ControlState.keyLeftRotate) {
        rotation = 0.05;
      }

      if (rotation){
        globalPlayer.rotateOnAxis(up, rotation);
      } else {
        temp.applyAxisAngle(up, globalPlayer.rotation.y);
        if (globalPlayer) {
          globalPlayer.position.addScaledVector(temp, MOVE_SPEED * dt);
        }
      }
    }
  });

  useFrame(({ camera, mouse, scene }, dt) => {
    if (isDown.current) {
      temp.set(0, 0, 0);

      if (ctrler === lefctController) {
        temp.set(0, 0, 1);
        temp.applyQuaternion(ctrler.controller.quaternion);
      }

      if (ctrler === rightController) {
        temp.set(0, 0, -1);
        temp.applyQuaternion(ctrler.controller.quaternion);
      }

      if (globalPlayer) {
        globalPlayer.position.addScaledVector(temp, 10 * dt);
      }
    }

    // if (player && session) {
    //   player.position.lerp(pt, 0.1);

    //   chasing.copy(player.position);

    //   if (globalPlayer) {
    //     globalPlayer.position.lerp(player.position, 0.1);
    //     camera.position.lerp(globalPlayer.position, 0.1);
    //   } else {
    //     camera.position.lerp(chasing, 0.1);
    //   }
    // }

    // const q = new Quaternion();
    // left.getWorldQuaternion(q);
    // console.log(q);
  });

  /*<Interactive*/
  // onSelectStart={(event) => {
  //   setCtrler(event.target);
  //   // console.log();
  //   isDown.current = true;
  // }}
  // onSelectEnd={(event) => {
  //   setCtrler(event.target);
  //   isDown.current = false;
  // }}
  // onSelect={(event) => {
  //   const target = event?.intersection;
  //
  //   console.log(target?.object?.name);
  //   if (target?.object?.userData?.onHandleHologram) {
  //     isDown.current = false;
  //     target?.object?.userData?.onHandleHologram();
  //   }

  // event
  // //
  // const target = event.intersection;
  // if (target && globalPlayer) {
  //   console.log(target);
  //   const isHologram = target?.object?.userData?.isHologram;
  //   const hasTargetLocation = target.object?.userData?.targetLocation;
  //   if (isHologram) {
  //     //
  //     target?.object?.userData?.onHandleHologram();
  //     //
  //   } else if (hasTargetLocation) {
  //     const gp = new Vector3();
  //     target.object.getWorldPosition(gp);
  //     // console.log(gp);
  //     // gsap
  //     //   .to(globalPlayer.position, {
  //     //     x: gp[0],
  //     //     y: gp[1],
  //     //     z: gp[2],
  //     //     duration: 2,
  //     //     ease: 'power2.out',
  //     //   })
  //     //   .play();
  //     const tt = setInterval(() => {
  //       if (gp.distanceTo(globalPlayer.position) < 0.1) {
  //         clearInterval(tt);
  //       } else {
  //         globalPlayer.position.lerp(gp, 0.05);
  //       }
  //     }, 5);
  //   }
  //   // globalPlayer.position;
  //   // setCtrler(event.target)
  //   // pt.copy(target.point)
  // }
  // }}

  return (
    <group>
      {/*  */}

      {/*  */}

      <Controllers />
      <Hands modelLeft={left} modelRight={right} />

      {/*>*/}
      {/*<Sphere visible={false} scale={1000}>*/}
      {/*  <meshStandardMaterial side={DoubleSide}></meshStandardMaterial>*/}
      {/*</Sphere>*/}
      {children}
      {/*</Interactive>*/}

      {/* {showVRBtn && (
        <Html transform>
          <VRButton></VRButton>
        </Html>
      )} */}
    </group>
  );
}
// ,
