import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useNonFunctionalCheck from '@hooks/use-non-functional-check';
import { useNonFunctionalStore } from '@store';
import DeviceUnsupported from './DeviceUnsupported/DeviceUnsupported';
import RotateDevice from './RotateDevice/RotateDevice';
import WindowTooSmall from './WindowTooSmall/WindowTooSmall';
import WebGlDisabled from './WebGlDisabled/WebGlDisabled';
import * as styled from './NonFunctionals.scss';

const propTypes = { className: PropTypes.string };

const defaultProps = { className: '' };

const NonFunctionals = (props) => {
  const { className } = props;

  // check for non-functional states
  useNonFunctionalCheck();

  const {
    windowTooSmall,
    rotateDevice,
    deviceSupport,
    webglDisabled
  } = useNonFunctionalStore(state => ({
    windowTooSmall: state.windowTooSmall,
    rotateDevice: state.rotateDevice,
    deviceSupport: state.deviceSupport,
    webglDisabled: state.webglDisabled,
  }));

  return (
    <div className={classnames(styled.NonFunctionals, className)}>
      {windowTooSmall && <WindowTooSmall />}
      {rotateDevice && <RotateDevice />}
      {webglDisabled && <WebGlDisabled />}
      {
        !(deviceSupport.browser && deviceSupport.os) &&
          <DeviceUnsupported />
      }
    </div>
  );
};

NonFunctionals.propTypes = propTypes;
NonFunctionals.defaultProps = defaultProps;

export default NonFunctionals;
