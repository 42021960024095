import './TextGlow.scss';
import React, { Children } from 'react';

const TextGlow = (props) => {
  const { children } = props;

  return (
    <h2 className="TextGlow">
      <span>{children}</span>
      <span className="TextGlow__blurs">
        <span className="TextGlow__blur">{children}</span>
        <span className="TextGlow__blur">{children}</span>
        <span className="TextGlow__blur">{children}</span>
      </span>
    </h2>
  );
};

export default TextGlow;
