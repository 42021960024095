import classnames from 'classnames';

import './Link.scss';

export const Link = ({ onClick, children, className }) => {
  return (
    <button className={classnames('Link', className)} onClick={onClick}>
      {children}
    </button>
  );
};

export default Link;
