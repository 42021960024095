import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { Color, DoubleSide, RepeatWrapping, ShaderMaterial, Vector2 } from 'three';
import { fragmentShader } from '@shaders/example-shader';
import { useCore } from './use-core.js';

export function SnowScan({ scene }) {
  const core = useCore();

  const size = useThree((s) => s.size);
  useEffect(() => {
    if (!scene) {
      return;
    }

    scene.traverse((it) => {
      // if (it.name.indexOf('windows') !== -1) {
      //   console.log(it.name);
      // }
      // Plane014
      if (
        it.name === `Plane014` ||
        it.name === `Plane037` ||
        it.name === `Plane048_1` ||
        it.name === `Plane018` ||
        //
        // it.name === 'Plane081' ||
        // it.name === 'Plane077' ||
        // it.name === 'Plane037' ||
        // it.name === 'Plane047' ||
        // it.name === 'Plane014_2' ||
        it.name.indexOf('window') !== -1
      ) {
        //
        //
        // it.material = new ShaderMaterial({
        //   vertexShader: `
        //     void main (void) {
        //       gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        //     }
        //   `,
        //   fragmentShader: `
        //     void main (void) {
        //       gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
        //     }
        //   `,
        // });
        //
        //

        /** @type {MeshPhysicalMaterial} */
        const mat = it.material.clone();
        it.material = mat;
        mat.side = DoubleSide;
        mat.customProgramCacheKey = function () {
          return Math.random();
        };
        mat.onBeforeCompile = (shader) => {
          const freq = 1;

          // /** @type {MeshPhysicalMaterial} */
          // const cloned = it.material.clone();

          shader.uniforms.time = { value: Math.random() * 3.141592 };
          shader.uniforms.progress = { value: 0 };
          shader.uniforms.nightColor = { value: new Color('#00194C') };
          // shader.uniforms.nightColor = { value: new Color('#ff0000') };
          shader.uniforms.freq = { value: freq };
          shader.uniforms.colorMap = { value: mat.map };
          shader.uniforms.iResolution = { value: new Vector2().copy(size) };
          shader.uniforms.offset = { value: new Vector2(0.0, Math.random() * 2.0) };
          //

          mat.map.wrapS = RepeatWrapping;
          mat.map.wrapT = RepeatWrapping;

          let t = 0;
          let p = 0;
          //
          core.onLoop((st, dt) => {
            t += dt;
            p += dt;
            if (p >= 1) {
              p = 0;
            }

            //
            shader.uniforms.time.value = t;
            shader.uniforms.progress.value = p;
          });

          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <clipping_planes_pars_fragment>`,
            `#include <clipping_planes_pars_fragment>

varying vec3 vPos;
uniform float progress;
uniform float time;
uniform sampler2D colorMap;
uniform vec2 iResolution;
uniform vec3 nightColor;
uniform vec2 offset;

#define pi 3.14159265359

vec2 kaleido(vec2 uv) {
float th = atan(uv.y, uv.x);
float r = pow(length(uv), 1.);

float p1 = cos(2. * pi * 0.1 * time / 10.);
float q = 2. * pi / ( 5. + 4. * p1);
th = abs(mod(th, q) - 0.5 * q);

return vec2(
cos(th),
sin(th)) * pow(r, 1.3 + 1.3 / (1.3 + sin(2. * pi * time / 3.))
) * .1;
}

//https://www.shadertoy.com/view/MscXD7
#define _SnowflakeAmount 150	// Number of snowflakes
#define _BlizardFactor 0.0		// Fury of the storm !

vec2 uv;

float rnd(float x)
{
    return fract(sin(dot(vec2(x+47.49,38.2467/(x+2.3)), vec2(12.9898, 78.233)))* (43758.5453));
}

float drawCircle(vec2 center, float radius)
{
    return 1.0 - smoothstep(0.0, radius, length(uv - center));
}

vec4 mainImage( in vec4 fragColor, in vec2 vUv )
{
    uv = vUv;

    // fragColor = vec4(0.0, 0.0, 0.0, 1.0);
    float j;

    for(int i=0; i < _SnowflakeAmount; i++)
    {
        j = float(i);
        float speed = 0.3+rnd(cos(j))*(0.7+0.5*cos(j/(float(_SnowflakeAmount)*0.25)));
        vec2 center = vec2(
          (0.25-uv.y) * _BlizardFactor + rnd(j)+ 0.0 * 0.1*cos(time+sin(j)),
          mod(sin(j)-speed*(time*1.5*(0.1+_BlizardFactor)), 0.65)
        );

        fragColor += vec4(0.09 * drawCircle(center, 0.001 * 2.6 + speed*0.012));
    }

    return fragColor;
}

        `
          );
          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <dithering_fragment>`,
            `

vec4 debugBridgeColor = texture(colorMap, time * 0.03 - kaleido((vUv * 2.0 - 1.0) * 4.0));

vec4 baseColor = vec4(
  nightColor, 1.0);

    vec4 snowColor = mainImage(baseColor + debugBridgeColor * 0.0, vec2(
      mod(vUv.x + offset.x, 1.0), mod(1.0 - vUv.y + offset.y, 1.0)
    ) * 0.5);

#ifdef USE_FOG
  #ifdef FOG_EXP2
		float fogFactor2 = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
	#else
		float fogFactor2 = smoothstep( fogNear, fogFar, vFogDepth );
	#endif
	gl_FragColor.rgb = mix( snowColor.rgb, fogColor, fogFactor2 );
#endif

// #ifdef DITHERING
// gl_FragColor.rgb = dithering( gl_FragColor.rgb );
// #endif d

// if (cnoise(vPos * 3.5 + progress) < 0.0) {
//   discard;
// }
`
          );
        };
      }
    });
  }, [scene, core]);
  return <group></group>;
}
