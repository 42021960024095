import create from 'zustand';
import { subscribeWithSelector, persist } from 'zustand/middleware';
import { arrayRemove } from '@utils/array';
import { SKIP_PROGRESS_TRACKING } from '@settings/settings.params.js';
import { MAX_POINTS, TOTAL_POINTS_BEFORE_BONUS } from '@settings/settings.consts.js';
import triviaContent from '../data/trivia';
import hologramsData from '../data/holograms';

let useUserSessionStore = subscribeWithSelector((set) => ({
  audioActive: false,
  setAudioActive: () => set({ audioActive: true }),
  pointsEarned: 0,
  pointsAdded: { id: '', amount: 0 },
  totalPoints: TOTAL_POINTS_BEFORE_BONUS,
  increaseTotalPoints: (value = 1) =>
    set((state) => {
      return {
        ...state,
        totalPoints: state.totalPoints + value,
      };
    }),
  increasePoints: (value = 1) =>
    set((state) => {
      return {
        ...state,
        pointsAdded: { id: Math.random(), amount: value },
        pointsEarned: Math.min(state.pointsEarned + value, MAX_POINTS),
      };
    }),
  socialSharePointsReceived: false,
  setSocialSharePointsReceived: () => set({ socialSharePointsReceived: true }),
  endScreenFormSubmitted: true,
  setEndScreenFormSubmitted: () => set({ endScreenFormSubmitted: true }),
  onEndingVisited: false,
  setOnEndingVisited: (value = true) => set({ onEndingVisited: value }),
  displayTriviaModal: null,
  triviaQuestions: triviaContent,
  trackUserAnswer: (questionslug) =>
    set((state) => {
      const triviaQuestions = state.triviaQuestions;
      triviaQuestions.forEach((question, i) => {
        if (question.slug === questionslug) {
          triviaQuestions[i].answered = true;
        }
      });

      return {
        ...state,
        triviaQuestions: triviaQuestions,
      };
    }),
  holograms: hologramsData,
  activeHolograms: [
    'MIKESIEVERT',
    'NESTOR',
    'MARCUSEAST',
    'PETEREWENS',
    'CALLIE',
    'JONFREIER',
    'JANICEKAPNER',
    'MIKEKATZ',
    'BRIANKING',
    'DEEANNEKING',
    'SUSANLOOSEMORE',
    'MARKNELSON',
    'PETEROSVALDIK',
    'NEVILLERAY',
    'ABDULSAAD',
    'JOHNSAW',
    'ULFEWALDSSON'
  ],
  removeHologramFromActiveHolograms: (value) =>
    set((state) => {
      return {
        activeHolograms: arrayRemove(state.activeHolograms, value),
      };
    }),
  resetActiveHolograms: () => set({ activeHolograms: [] }),
  trackUserHolograms: (id) =>
    set((state) => {
      const holograms = state.holograms;
      holograms.forEach((hologram, i) => {
        if (hologram.id === id) {
          holograms[i].active = false;
        }
      });
      return {
        holograms: holograms,
      };
    }),
  userCharityChoice: '',
  setUserCharityChoice: (value) => set({ userCharityChoice: value }),
  lastMarkerPosition: null,
  setLastMarkerPosition: (value) => set({ lastMarkerPosition: value }),
}));

if (!SKIP_PROGRESS_TRACKING) {
  useUserSessionStore = persist(useUserSessionStore, { name: 't-mobile__user-settings' });
}
useUserSessionStore = create(useUserSessionStore);

export default useUserSessionStore;
