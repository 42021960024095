import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ClosedCaption.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const ClosedCaption = (props) => {
  const { className } = props;

  return (
    <div className={classnames('ClosedCaption', className)}>
      <div className="ClosedCaption__content">
        <p>Welcome to our campus here in the Pacific Northwest!
          I&apos;m Mike Sievert, CEO at T-Mobile. As we close 2022 and celebrate the holidays with loved ones,
          we wanted to say THANK YOU for making this year the most successful one in the history of The Un-Carrier.</p>
        <p>Start exploring our headquarters and look for the glowing icons scattered around.
          At each one, you can answer a trivia question from a T-Mobile executive. Answer correctly and you&apos;ll
          increase your chance of winning one of our daily prize drawings.</p>
        <p>From our home to yours, welcome and enjoy!</p>
        <p>Now, here&apos;s a question to get you started!</p>
      </div>
    </div>
  );
};

ClosedCaption.propTypes = propTypes;
ClosedCaption.defaultProps = defaultProps;

export default ClosedCaption;
