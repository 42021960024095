import './Canvas.scss';
import React, { useEffect, useRef, Suspense, useCallback, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { motion } from 'framer-motion';
import { Environment, PerspectiveCamera, Preload, useGLTF } from '@react-three/drei';
import { Perf } from 'r3f-perf';
import { Bloom, ChromaticAberration, ColorAverage, EffectComposer, SSR, Vignette } from '@react-three/postprocessing';
import { XR } from '@react-three/xr';
import { dispatch } from 'use-bus';
import useEffectWithPrevious from 'use-effect-with-previous';
import { useCanvasStore } from '@store';
import { CANVAS_MAX_LOAD_PERCENTAGE } from '@settings/settings.app';
import useThreeLoadingManager from '@hooks/use-three-loading-manager';
import useCanvasHooks from '@hooks/use-canvas-hooks';
import { SHOW_PERF } from '@settings/settings.params.js';
import useAppStore from '@store/_app';
import { Sleep } from '@utils/sleep';
import useEquirectTexture from '@hooks/use-equirect-texture.js';
import { TEX_STUDIO_HDRI_URL } from '@settings/settings.urls.js';
import CanvasSetup from '@components/canvas/CanvasSetup';
import { useRafPerf } from '@hooks/use-raf-perf.js';
import { EVENT_XR_SESSION_START } from '@settings/settings.events.js';
import useCanvasLoadHandler from '@hooks/use-canvas-load-handler.js';
import Metaverse from '../Metaverse/Metaverse';
import { useVRCore, VR, XRSessionUpdater } from '../VR/VR';
import { EnvPNG } from '../EnvPNG/EnvPNG';
// TheatreJS
function LoadedWatcher() {
  const setIsCanvasAssetsLoaded = useAppStore((state) => state.setIsCanvasAssetsLoaded);
  const isCanvasAssetsLoaded = useAppStore((state) => state.isCanvasAssetsLoaded);
  useEffect(() => {
    return () => {
      setIsCanvasAssetsLoaded(true);
    };
  }, [setIsCanvasAssetsLoaded]);
  return <></>;
}

const CamaraManager = function(){
  const inXRSession = useAppStore((s) => s.inXRSession);

  const scene = useThree(s => s.scene);

  // Handle the switch between XR and non-xr
  const cameraParentInXR = useRef();
  useEffectWithPrevious(([prevInXRSession]) => {
    if (prevInXRSession && !inXRSession){
      cameraParentInXR.current = cameraRef.current.parent;
      scene.add(cameraRef.current);
      console.info('cameraRef:', cameraRef);
    } else if (!prevInXRSession && inXRSession){
      cameraRef.current.rotation.set(0, 0, 0);
      cameraRef.current.position.set(0, 0, 0);
      if (cameraParentInXR.current){
        cameraParentInXR.current.add(cameraRef.current);
      }
    }
  }, [inXRSession]);

  const cameraRef = useRef();

  return (
    <>
      <PerspectiveCamera ref={cameraRef} makeDefault>
        {/* <pointLight position={[0, 1, -5]} color={'#fafafa'} intensity={1.0}></pointLight> */}
      </PerspectiveCamera>
    </>
  );
};

function ThreeCanvas(props) {
  const rootElRef = useRef(null);
  const [metaverseActive, setMetaverseActive] = useState(false);
  const setInXRSession = useAppStore((s) => s.setInXRSession);

  useEffect(() => {
    const unsubscribeViewChange = useAppStore.subscribe(
      (state) => state.view,
      async (value) => {
        if (value === 'metaverse' || value === 'onboarding') {
          setMetaverseActive(true);
        } else {
          setMetaverseActive(false);
        }
      }
    );

    return () => {
      unsubscribeViewChange();
    };
  }, []);

  const animateIn = () => {
    // gsap.fromTo(
    //   rootElRef.current,
    //   { opacity: 0 },
    //   {
    //     opacity: 1,
    //     duration: 1.2,
    //     delay: .2,
    //     ease: 'linear',
    //   }
    // );
  };

  useCanvasHooks();

  useCanvasLoadHandler();

  const session = useVRCore((s) => s.session);

  const onXRSessionStart = () => {
    setInXRSession(true);
    dispatch(EVENT_XR_SESSION_START);
  };
  const onXRSessionEnd = () => {
    setInXRSession(false);
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    // eslint-disable-next-line react/jsx-max-props-per-line
    <div className="Canvas" ref={rootElRef} {...props}>
      <motion.div
        variants={overlayVariants}
        animate={!metaverseActive ? 'show' : 'hidden'}
        transition={{ ease: 'linear', duration: 0.8 }}
        className="Canvas__overlay"
      ></motion.div>
      <Suspense fallback={<LoadedWatcher />}>
        <Canvas
          shadows={false}
          className="Canvas__renderer"
          frameloop="always"
          gl={{ alpha: false }}
          dpr={[1, 1.5]}
          onCreated={(state) => (state.gl.localClippingEnabled = true)}
        >
          <CanvasSetup />

          <XR onSessionStart={onXRSessionStart} onSessionEnd={onXRSessionEnd}>
            <XRSessionUpdater>
              <Metaverse></Metaverse>
            </XRSessionUpdater>
          </XR>

          <CamaraManager />

          <Preload all />

          <directionalLight intensity={0.2} position={[4.0, 0.8, -1]}></directionalLight>

          {/* <Environment preset="night" /> */}
          {SHOW_PERF && (
            <Perf
              showGraph={false}
              minimal={false}
              className="r3f-perf"
              style={{
                right: 'auto',
                top: 'auto',
                left: 0,
                bottom: 60,
                zIndex: 2000,
              }}
            />
          )}
          {/* <EffectComposer> */}
          {/* <Bloom mipmapBlur luminanceThreshold={0.9} intensity={3} /> */}
          {/* <Bloom ></Bloom> */}
          {/* <SSR jitter={0.3} jitterSpread={0.6}></SSR> */}
          {/* </EffectComposer> */}
          {/*{!session && (*/}
          {/*  <EffectComposer disableNormalPass multisampling={4}>*/}
          {/*    <Bloom*/}
          {/*      mipmapBlur*/}
          {/*      luminanceThreshold={0.9}*/}
          {/*      intensity={0.5}*/}
          {/*      //*/}
          {/*    />*/}
          {/*    <Vignette />*/}
          {/*  </EffectComposer>*/}
          {/*)}*/}
        </Canvas>
      </Suspense>
    </div>
  );
}

export default ThreeCanvas;
