import { boolean } from 'boolean';
import urlParams from '@utils/url-params.js';

export const DEBUG_MODE = boolean(urlParams.get('debug'));
export const LOOPS = boolean(urlParams.get('loops'));
export const SKIP_PROGRESS_TRACKING = boolean(urlParams.get('notrack'));
export const SHOW_PERF = boolean(urlParams.get('perf'));
export const THEATRE = boolean(urlParams.get('theatre'));
export const DEBUG_POSITION = boolean(urlParams.get('position'));
export const QA_MODE = boolean(urlParams.get('qa'));
export const WALK_MODE = boolean(urlParams.get('walk'));
export const GPU_TIER = parseInt(urlParams.get('tier')) || 0;
export const VR_DEBUG = boolean(urlParams.get('vr'));
