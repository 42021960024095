import { TransformControls, Html } from '@react-three/drei';
import { useCallback, useRef, useState } from 'react';
import useCanvasStore from '@store/_canvas';
import './TransformControlsWrapper.scss';

export default function TransformControlsWrapper(props){
  const [controls, setControls] = useState();
  const [enabled, setEnabled] = useState(true);
  const htmlRef = useRef();

  const setControlsRefCallback = useCallback((r) => {
    setControls(r);
  }, [setControls]);

  const { setTransformControlsActive } = useCanvasStore((state) => {
    return {
      setTransformControlsActive: state.setTransformControlsActive
    };
  });

  const mouseDown = useCallback(() => {
    setTransformControlsActive(true);
  }, [setTransformControlsActive]);

  const mouseUp = useCallback((ev) => {
    setTransformControlsActive(false);
    if (controls && controls.object) {
      // console.info('controls:', controls);
      console.log('Transform Info For', props.name);
      if (controls.mode === 'translate') {
        const pos = controls.object.position;
        if (htmlRef && htmlRef.current) {
          htmlRef.current.position.copy(pos);
        }
        console.info('TransformControls pos:', pos);
        console.log(pos.toArray().toString());
      } else if (controls.mode === 'scale') {
        console.info('TransformControls scale:', controls.object.scale);
        console.log(controls.object.scale.toArray().toString());
      } else if (controls.mode === 'rotate') {
        console.info('TransformControls rotation:', controls.object.rotation);
        console.log(controls.object.rotation.toArray().toString());
      }
    }
  }, [setTransformControlsActive, controls]);

  const setMode = useCallback((mode) => {
    if (controls){
      controls.setMode(mode);
    }
  }, [controls]);

  return (
    <>
      <TransformControls ref={setControlsRefCallback}
        {...props}
        enabled={enabled}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp} />
      {controls && controls.object && (
        <group ref={htmlRef} position={controls.object.position}>
          <Html style={{
            transform: 'translateY(15px)'
          }} wrapperClass={'TransformControlsWrapper'}>
            <button onClick={() => {
              setMode('translate');
            }}>T</button> <button onClick={() => {
              setMode('scale');
            }}>S</button> <button onClick={() => {
              setMode('rotate');
            }}>R</button>
          </Html>
        </group>
      )}
    </>
  );
}
