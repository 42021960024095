import { Center, Plane } from '@react-three/drei';
import { createPortal, useFrame, useThree } from '@react-three/fiber';
import { Interactive, useXR } from '@react-three/xr';
import { useEffect, useMemo, useRef } from 'react';
import { zeroPad } from 'react-countdown';
import { CanvasTexture, Vector2, Vector3 } from 'three';
import createStyledText from 'canvas-text';
import useUserSessionStore from '@store/_user-session.js';
import { heightAtDepth, RenderText, widthAtDepth } from '../RenderText/RenderText';
import { VRStickyUI } from '../VRStickyUI/VRStickyUI';

export function VRScore({ inVROnly = true }) {
  const session = useXR((s) => s.session);
  const camera = useThree((s) => s.camera);
  const w = widthAtDepth(1, camera);
  const h = heightAtDepth(1, camera);
  const totalPoints = useUserSessionStore((state) => state.totalPoints);
  const pointsEarned = useUserSessionStore((state) => state.pointsEarned);

  const btnSize = useMemo(() => {
    return new Vector2(0.2, 0.1);
  }, []);

  // useEffect(() => {
  //   if (document.querySelector('.Widget')) {
  //     document.querySelector('.Widget').remove();
  //   }
  // });

  const { texture } = useMemo(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 256 * 1.0;
    canvas.height = 256 * 0.5;

    const primary = {
      size: 40,
      fillStyle: 'white',
      weight: 100,
    };

    const text = '15';

    const chunks = [
      {
        ...primary,
        marginLeft: -40,
        fillStyle: '#E20074',
        text: `${zeroPad(pointsEarned)}`,
      },
      {
        ...primary,
        marginLeft: 30,
        fillStyle: 'black',
        text: `/${totalPoints}`,
      }
    ];

    // if (prefix) {
    //   chunks.unshift({
    //     prefix: true,
    //     text: prefix,
    //     size: 22,
    //     fillStyle: '#E20074',
    //     strokeStyle: '#E20074',
    //   });
    // }

    const family = `bold ${60}px "teleneo_office", ui-sans-serif, system-ui, -apple-system,${`
    `}BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif`;

    const styledText = createStyledText(context, chunks, {
      lineSpacing: 15,
      family: family,
    });

    const texture = new CanvasTexture(canvas);
    texture.generateMipmaps = true;
    // we can tailor the chunk rendering to our application
    // e.g. animations, fancy underlines, etc
    function renderText(context, str, x, y, textWidth, lineHeight, attribute) {
      context.fillStyle = attribute.fillStyle;

      // if (attribute.underline) {
      //   context.beginPath();
      //   context.strokeStyle = attribute.strokeStyle || attribute.fillStyle;
      //   context.lineWidth = 1;
      //   context.lineCap = 'round';
      //   context.lineJoin = 'round';

      //   // const offY = y + 13;
      //   // dashLine(context, [x, offY + lineHeight], [x + textWidth, offY + lineHeight], 8);
      //   context.stroke();
      // }

      context.font = family;
      context.fillStyle = attribute.fillStyle;
      context.textAlign = 'center';
      context.fillText(
        str,
        attribute.marginLeft + x + canvas.width / 2,
        y + canvas.height / 2 - lineHeight * text.split('\n').length * 0.5
      );
    }
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);

    styledText.render(0, 25, renderText);

    // if (border) {
    //   const bw = canvas.width;
    //   const bh = 100;
    //   context.strokeStyle = 'black';
    //   context.strokeWidth = 3;
    //   const px = 6;
    //   const py = 6;
    //   context.strokeRect(px, py, canvas.width - px, canvas.height - py);
    // }

    texture.needsUpdate = true;
    return {
      canvas,
      texture,
    };
  }, [totalPoints, pointsEarned]);

  const content = (
    <group
      scale={[1, 1, 1]}
      onClick={() => {
        //
      }}
      position={[0, 0, -1]}
    >
      <Plane
        position={[-0.48, h / 2 - btnSize.y / 2 + h * -0.17, 0.1]}
        args={[btnSize.x, btnSize.y]}
      >
        <meshBasicMaterial color={'#ffffff'} transparent={true} map={texture}></meshBasicMaterial>
      </Plane>
    </group>
  );

  //
  return (
    <group>
      {inVROnly ? session && <VRStickyUI lerp={1}>{content}</VRStickyUI> : <VRStickyUI lerp={1}>{content}</VRStickyUI>}
    </group>
  );
}
