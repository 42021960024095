export const EVENT_NAVIGATE_TO_MARKER = 'nav/navigateToMarker';
export const EVENT_ANIMATE_IN_TO_INITIAL_CAMERA_POS = 'player/animateInToIntialCamPos';
export const EVENT_UPDATE_CAMERA = 'UPDATE_CAMERA';
export const EVENT_BUTTON_CLICK = 'BUTTON_CLICK';
export const EVENT_INTRO_TRANSITION = 'INTRO_TRANSITION';
export const EVENT_HOLOGRAM_APPEAR = 'HOLOGRAM_APPEAR';
export const EVENT_HOLOGRAM_DISAPPEAR = 'HOLOGRAM_DISAPPEAR';
export const EVENT_HOLOGRAM_PLAY = 'HOLOGRAM_PLAY';
export const EVENT_ANSWER_WRONG = 'ANSWER_WRONG';
export const EVENT_ANSWER_CORRECT = 'ANSWER_CORRECT';
export const EVENT_VICTORY = 'VICTORY';
export const EVENT_WIN_POINTS = 'WIN_POINTS';
export const REMOVE_MARKER = 'REMOVE_MARKER';
export const EVENT_GL_COMPILE = 'EVENT_GL_COMPILE';
export const EVENT_XR_SESSION_START = 'EVENT_XR_SESSION_START';
export const EVENT_OPEN_QUESTION = 'EVENT_OPEN_QUESTION';
export const GOTO_END_WITH_DELAY = 'GOTO_END_WITH_DELAY';
