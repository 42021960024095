import React, { useState } from 'react';
import classnames from 'classnames';
import './SocialsCta.scss';
import useMediaQuery from '@hooks/use-media-query';
import Socials from '../Socials/Socials';

const SocialsCta = (props) => {
  const { className } = props;

  const isMobile = useMediaQuery('(max-width: 850px)');
  const [isSocialsCtaClosed, setIsSocialsCtaClosed] = useState(false);

  return (
    <div className={classnames('SocialsCta', className, { 'SocialsCta--closed': isSocialsCtaClosed })}>
      <div className="SocialsCta__bg"></div>
      <div className="SocialsCta__text">
        Want to increase your chances to win? Smash one of the share buttons and earn an extra point!
      </div>
      <Socials />
      {isMobile && (
        <div className="SocialsCta__close" onClick={() => setIsSocialsCtaClosed(true)}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.34326 1.34326L12.657 12.657" stroke="#E20074" strokeWidth="1.5" />
            <path d="M1.34326 12.6567L12.657 1.34303" stroke="#E20074" strokeWidth="1.5" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default SocialsCta;
