import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import TMobileLogo from '@assets/svgs/t-mobile-logo.svg';
import './BrandLogo.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const BrandLogo = (props) => {
  const { className } = props;

  return (
    <div className={classnames('BrandLogo', className)}>
      <TMobileLogo className="BrandLogo__logo" />
    </div>
  );
};

BrandLogo.propTypes = propTypes;
BrandLogo.defaultProps = defaultProps;

export default BrandLogo;
