import './Button.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { dispatch } from 'use-bus';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EVENT_BUTTON_CLICK } from '@settings/settings.events';
import { isMobile } from '@utils/device.js';
import useTouchDetect from '@hooks/use-touch-detect.js';

export const Button = ({ onClick, children, className, soundVolume = 1, playSound = false }) => {
  const handleClick = (e) => {
    playSound && dispatch({ type: EVENT_BUTTON_CLICK, payload: { volume: soundVolume } });
    onClick(e);
  };

  const rootElRef = useRef();

  const touchDown = useTouchDetect(rootElRef, isMobile());

  return (
    <button ref={rootElRef} className={classnames('Button', className, { 'Button--touch-down': touchDown })} onClick={handleClick}>
      <div className="Button__inner">
        <div className="Button__hover">{children}</div>
        <div className="Button__static">{children}</div>
      </div>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Button;
