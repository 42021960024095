import React, { forwardRef, useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { Vector3, Clock, Spherical, Sphere, Group, Matrix4, Frustum, Object3D, Ray } from 'three';
import { useThree } from '@react-three/fiber';
import { mergeRefs } from 'react-merge-refs';
import { Interactive } from '@react-three/xr';
import useBus, { dispatch } from 'use-bus';
import useAppStore from '@store/_app';
import TransformControlsWrapper from '@components/canvas/TransformControlsWrapper/TransformControlsWrapper.jsx';
import { EVENT_HOLOGRAM_DISAPPEAR, EVENT_NAVIGATE_TO_MARKER, REMOVE_MARKER } from '@settings/settings.events.js';
import { useRafPerf, useFramePerf } from '@hooks/use-raf-perf.js';
import { DEBUG_MODE } from '@settings/settings.params.js';
import { useUserSessionStore } from '@store/index.js';
import { NavigationMarkerModel } from '@components/canvas/models/NavigationMarkerModel.jsx';
import { getCameraPosition, getCameraRotation } from '@utils/three-utils.js';
import useCameraOrXRPlayer from '@hooks/use-camera-or-x-r-player.js';

const MAX_DISTANCE_INTERACTIVE_MARKER = 25;
const MAX_OBSTACLES_INTERACTIVE_MARKER = 5;

export const NavigationMarker = forwardRef(
  (
    {
      id,
      onUpdate = () => {},
      onDone = () => {},
      onStart = () => {},
      destination = null,
      position,
      onClickHandler,
      type = 'marker',
    },
    ref
  ) => {
    const camera = useCameraOrXRPlayer();
    const [enableTansform, setEnableTransform] = useState(false);
    const internalRef = useRef();
    const [isPlayerOnMarker, setIsPlayerOnMarker] = useState(null);
    const worldPosition = useMemo(() => {
      return new Vector3();
    }, []);
    const [interactive, setInteractive] = useState(() => {
      return !useUserSessionStore.getState().activeHolograms.includes('MIKESIEVERT') || DEBUG_MODE;
    });

    // Populate userData with some props
    useEffect(() => {
      if (internalRef && internalRef.current) {
        internalRef.current.userData = { ...internalRef.current.userData, id, destination, type };
        // internalRef.current.traverse((it) => {
        //   console.info('it:', it);
        // });
      }
    }, [internalRef]);

    useEffect(() => {
      const unsubscribeInteractiveChange = useAppStore.subscribe(
        (state) => state.hologramActive,
        (value) => {
          // console.info('hologramActive:', value);
          setInteractive(!value);
        }
      );
      return () => {
        unsubscribeInteractiveChange();
      };
    }, []);

    // Check when a hologram disapears if we should make the markers interactive
    useBus(
      EVENT_HOLOGRAM_DISAPPEAR,
      () => {
        setInteractive(!useAppStore.getState().hologramActive);
      },
      [setInteractive]
    );

    // useEffect(() => {
    //   if (!coneMaterialRef) return;
    //   ringRef.current.material.transparent = true;
    //   const tl = gsap.timeline({
    //     onStart: () => {
    //       internalRef.current.visible = true;
    //     },
    //     onComplete: () => {
    //       if (!interactive) {
    //         internalRef.current.visible = false;
    //       }
    //     } });
    //   tl.add([
    //     gsap.to([coneMaterialRef.current, ringRef.current.material], {
    //       opacity: interactive ? 1 : 0,
    //       duration: 0.5,
    //       ease: 'none',
    //     })
    //   ]);
    //   return () => {
    //     tl.kill();
    //   };
    // }, [interactive]);

    // useFrame((st, dt) => {
    //   //insideGroupRef.current.lookAt(st.camera.position);
    //   if (interactive) {
    //     internalRef.current.getWorldPosition(d);
    //     if (d.distanceTo(st.camera.position) < 3) {
    //       internalRef.current.visible = false;
    //     } else {
    //       internalRef.current.visible = true;
    //     }
    //   }
    // });

    useEffect(() => {
      if (internalRef) {
        worldPosition.copy(internalRef.current.position);
      }
    }, [internalRef, worldPosition]);

    const rafLoop = useCallback(() => {
      const pos = camera.position;
      if (worldPosition.distanceTo(pos) < 3) {
        if (isPlayerOnMarker !== true) setIsPlayerOnMarker(true);
      } else {
        if (isPlayerOnMarker !== false) setIsPlayerOnMarker(false);
      }
      // if (interactive && internalRef.current){
      //   internalRef.current.getWorldPosition(d);
      //
      // } else {
      //   if (isPlayerOnMarker) setIsPlayerOnMarker(false);
      // }
    }, [interactive, internalRef, camera, isPlayerOnMarker, setIsPlayerOnMarker]);
    useEffect(() => {
      if (!interactive) {
        setIsPlayerOnMarker(null);
      }
    }, [interactive]);
    const [start, stop] = useFramePerf(rafLoop, { fps: 10, name: 'nav-marker-player-check' });
    useEffect(() => {
      start && start();
      return () => {
        stop();
      };
    }, [start, stop]);

    const tempPos = useMemo(() => {
      return new Vector3();
    }, []);
    const tempRay = useMemo(() => {
      return new Ray();
    }, []);
    const handlePointerDown = useCallback(
      (ev) => {
        // console.log('yo is me mario', ev.intersections);
        // console.log(ev.intersections.length);
        if (ev.intersections.length > MAX_OBSTACLES_INTERACTIVE_MARKER) return;
        const distance = ev.distance || ev.intersection.distance;
        if (ev.distance > MAX_DISTANCE_INTERACTIVE_MARKER) {
          console.warn('NavigationMarker Too Far', id, ev);
          return;
        }
        if (enableTansform) {
          return;
        }
        if (onClickHandler) {
          onClickHandler(ev);
        }
        if (!interactive || !internalRef.current.visible) return;
        // console.log('NavigationMarker handlePointerDown', id, ev);
        ev.stopPropagation && ev.stopPropagation();
        // if (coneRef){
        //   coneRef.current.scale.set(5, 5, 5);
        // }
        if (ev && ev.nativeEvent) {
          if (ev.nativeEvent.shiftKey && ev.nativeEvent.ctrlKey) {
            dispatch({ type: REMOVE_MARKER, payload: { id } });
            return;
          }
          if (ev.nativeEvent.shiftKey) {
            setEnableTransform((val) => !val);
            return;
          }
        }
        tempPos.copy(internalRef.current.position);
        let direction = ev?.ray?.direction;
        if (!direction) {
          tempRay.set(camera.position, tempPos);
          tempRay.lookAt(tempPos);
          direction = tempRay.direction;
        }
        console.info('direction:', direction);
        if (type === 'marker') {
          dispatch({
            type: EVENT_NAVIGATE_TO_MARKER,
            payload: { position: destination || tempPos, direction: direction },
          });
        } else {
          // dispatch({ type: EVENT_NAVIGATE_TO_MARKER, payload: { position: tempPos } });
          // setTimeout(() => {
          //   dispatch({ type: EVENT_NAVIGATE_TO_MARKER, payload: { position: destination || tempPos } });
          // }, 1000);
        }
      },
      [setEnableTransform, id, internalRef, enableTansform, interactive]
    );

    return (
      <>
        {enableTansform && internalRef.current && <TransformControlsWrapper object={internalRef.current} />}
        <group ref={mergeRefs([ref, internalRef])} position={position}>
          {interactive && (
            <Interactive
              onSelect={(ev) => {
                console.log('ON SELECT MARKER');
                handlePointerDown(ev);
              }}
              onSqueeze={() => {
                console.log('ON SQUEZE');
              }}
              onHover={() => {
                console.log('ON HOVER');
              }}
              onSqueezeStart={() => {
                console.log('ON SQUEEZE START');
              }}
            >
              <NavigationMarkerModel
                active={isPlayerOnMarker}
                onClick={(ev) => {
                  handlePointerDown(ev);
                }}
              />
            </Interactive>
          )}
        </group>
      </>
    );
  }
);
