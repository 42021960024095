import './Countdown.scss';
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCountdown, { zeroPad } from 'react-countdown';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DEBUG_MODE } from '@settings/settings.params.js';

dayjs.extend(utc);
dayjs.extend(timezone);

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const DatePart = ({ dateProp, dateValue }) => {
  return (
    <div className={`Countdown__date__part Countdown__date__part--${dateProp.toLowerCase()}`}>
      <div className="Countdown__date__value">{zeroPad(dateValue)}</div>
      <div className="Countdown__date__label">{dateProp}</div>
    </div>
  );
};

const Countdown = (props) => {
  const { className, children, onComplete } = props;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return children;
    } else {
      // Render a countdown
      return (
        <div className="Countdown__date">
          <DatePart dateProp={'Days'} dateValue={days} />
          <DatePart dateProp={'Hours'} dateValue={hours} />
          <DatePart dateProp={'Minutes'} dateValue={minutes} />
          <DatePart dateProp={'Seconds'} dateValue={seconds} />
        </div>
      );
    }
  };

  return (
    <div className={classnames('Countdown', className)}>
      <ReactCountdown
        onComplete={onComplete}
        date={DEBUG_MODE
          ? Date.now() + 20000
          : dayjs('2022-12-12T9:00:00').tz('America/Toronto')
            .toDate()}
        renderer={renderer}
      />
    </div>
  );
};

Countdown.propTypes = propTypes;
Countdown.defaultProps = defaultProps;

export default Countdown;
