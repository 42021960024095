import { detection } from '@utils/device.js';

const settings = {
  mobile: {
    viewControlsRotationSpeed: 0.35
  },
  desktop: {
    viewControlsRotationSpeed: 0.3
  }
};

const deviceSpecificSettings = detection.isMobile ? settings.mobile : settings.desktop;

export default deviceSpecificSettings;
