import './VideoSequence.scss';
import { useRef, forwardRef, useImperativeHandle } from 'react';

const VideoSequence = forwardRef(({
  videoUrl, onVideoEnd, endVideoAt, loop = false, muted = true, autoPlay = false  }, ref) => {
  const video = useRef();
  const videoEndCalled = useRef(false);

  useImperativeHandle(ref, () => ({
    play: () => {
      video.current.play();
    },
  }));

  const initializeVideo = () => {
    // console.log('initializeVideo');
  };

  const handleVideoEnd = () => {
    if (!videoEndCalled.current) {
      videoEndCalled.current = true;
      onVideoEnd();
    }
  };

  const handleVideoTimeUpdate  = (ev) => {
    if (endVideoAt) {
      const curTime = ev.target.currentTime;
      if (curTime >= endVideoAt){
        handleVideoEnd();
      }
    }
  };

  return (
    <div className="VideoSequence">
      <video
        ref={video}
        disablePictureInPicture
        // eslint-disable-next-line react/no-unknown-property
        playsInline
        onLoadedMetadata={initializeVideo}
        onEnded={handleVideoEnd}
        onTimeUpdate={handleVideoTimeUpdate}
        loop={loop}
        preload="metadata"
        autoPlay={autoPlay}
        muted={muted}
      >
        <source src={`${videoUrl}.webm#t=0.1`} type="video/webm" />
        <source src={`${videoUrl}.mp4#t=0.1`} type="video/mp4" />
      </video>
    </div>
  );
});

export default VideoSequence;
