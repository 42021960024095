import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import BrandLogo from '@components/elements/BrandLogo/BrandLogo';
import SoundButton from '@components/elements/SoundButton/SoundButton';
// import VrButton from '@components/elements/VrButton/VrButton';
import './UiHeader.scss';
import useMediaQuery from '@hooks/use-media-query';
import useAppStore from '@store/_app';
import Socials from '@components/elements/Socials/Socials';
import PointsTrackerAnimation from '@components/elements/PointsTrackerAnimation/PointsTrackerAnimation';
import PointsTracker from '@components/elements/PointsTracker/PointsTracker';
import VrButton from '@components/elements/VrButton/VrButton.jsx';
import ClosedCaptionButton from '@components/elements/ClosedCaptionButton/ClosedCaptionButton.jsx';
import ClosedCaption from '@components/elements/ClosedCaption/ClosedCaption.jsx';

const UiHeader = (props) => {
  const isMobile = useMediaQuery('(max-width: 850px)');

  const { ccIsOpen, hologramActive } = useAppStore((state) => ({
    ccIsOpen: state.ccIsOpen,
    hologramActive: state.hologramActive
  }));

  const [VRActive, setVRActive] = useState(false);
  const [socialsActive, setSocialsActive] = useState(true);
  const [logoCentered, setLogoCentered] = useState(true);
  const [logoWhite, setLogoWhite] = useState(true);
  const [pointsTrackerActive, setPointsTrackerActive] = useState(false);

  useEffect(() => {
    const unsubscribeVisibilityCheck = useAppStore.subscribe(
      (state) => state.view,
      (value) => {
        switch (value) {
          case 'landing':
            setVRActive(false);
            setLogoCentered(true);
            setLogoWhite(true);
            setSocialsActive(true);
            setPointsTrackerActive(false);
            break;
          case 'metaverse':
            setVRActive(true);
            setLogoWhite(false);
            setLogoCentered(false);
            setSocialsActive(false);
            setPointsTrackerActive(true);
            break;
          case 'onboarding':
            setVRActive(true);
            setLogoWhite(false);
            setLogoCentered(false);
            setSocialsActive(false);
            setPointsTrackerActive(false);
            break;

          case 'endScreen':
            setVRActive(false);
            setLogoWhite(true);
            setLogoCentered(false);
            setSocialsActive(false);
            setPointsTrackerActive(true);
            break;
          default:
            setVRActive(false);
            setLogoWhite(true);
            setLogoCentered(true);
            setSocialsActive(true);
            setPointsTrackerActive(false);
            break;
        }
      }
    );

    return () => {
      unsubscribeVisibilityCheck();
    };
  }, []);

  const shouldShowCCOption = useMemo(() => {
    return (hologramActive && hologramActive.id === 'MIKESIEVERT');
  }, [hologramActive]);

  return (
    <>
      {shouldShowCCOption && ccIsOpen && <ClosedCaption />}
      <div className={classnames('UiHeader', { 'UiHeader--centered': logoCentered && isMobile })}>
        {isMobile && socialsActive && (
          <div className="UiHeader__socials">
            <Socials />
          </div>
        )}

        <div className={classnames('UiHeader__logo', { 'UiHeader__logo--white': logoWhite && isMobile })}>
          <BrandLogo />
        </div>

        {isMobile && pointsTrackerActive && (
          <div className="UiHeader__pointstracker">
            <PointsTrackerAnimation />
            <PointsTracker isOpen={false} />
          </div>
        )}

        <div className="UiHeader__nav">
          <ul>
            {shouldShowCCOption && (
              <li>
                <ClosedCaptionButton />
              </li>
            )}
            {!isMobile && VRActive && (
              <li>
                <VrButton />
              </li>
            )}
            <li>
              <SoundButton />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default UiHeader;
