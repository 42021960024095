import './PointsTrackerAnimation.scss';
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import useAppStore from '@store/_app';

const Sparkle = () => {
  return (
    <svg
      className="PointsTrackerAnimation__sparkle"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.64706 12C5.64706 8.88 3.12 6.35294 0 6.35294V5.64706C3.12 5.64706 5.64706 3.12 5.64706 0H6.35294C6.35294 3.12 8.88 5.64706 12 5.64706V6.35294C8.88 6.35294 6.35294 8.88 6.35294 12H5.64706Z"
        fill="white"
      />
    </svg>
  );
};

const PointsTrackerAnimation = (props) => {
  const { className } = props;

  const mainRef = useRef();

  const animateIn = () => {
    const sparkles = mainRef.current.querySelectorAll('.PointsTrackerAnimation__sparkle');

    const w = mainRef.current.clientWidth;
    const h = mainRef.current.clientHeight;

    for (let index = 0; index < sparkles.length; index++) {
      gsap.fromTo(
        sparkles[index],
        {
          x: w / 2,
          y: h / 2,
        },
        {
          x: gsap.utils.random(0 - 50, w + 50),
          y: gsap.utils.random(0 - 50, h + 50),
          duration: 3,
          ease: 'expo.out',
          delay: 0.01 * index,
        }
      );

      gsap.fromTo(
        sparkles[index],
        {
          scale: gsap.utils.random(0, 1),
          opacity: gsap.utils.random(0, 1),
        },
        {
          opacity: 0,
          scale: 0,
          duration: 3,
          ease: 'expo.inOut',
          delay: 0.01 * index,
        }
      );
    }
  };

  const animateOut = () => {
  };

  useEffect(() => {
    const unsubscribePointsAddedListener = useAppStore.subscribe(
      (state) => state.pointsAddedActive,
      (pointsAddedActive) => {
        console.log(`pointsAddedActive ${pointsAddedActive}`);
        if (pointsAddedActive) animateIn();
        if (!pointsAddedActive) animateOut();
      }
    );

    return () => {
      unsubscribePointsAddedListener();
    };
  }, []);

  return (
    <div ref={mainRef} className={classnames('PointsTrackerAnimation', className)}>
      {Array.from(Array(40), (e, i) => {
        return <Sparkle key={i} />;
      })}
    </div>
  );
};

export default PointsTrackerAnimation;
