import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ClosedCaptionSVG from '@assets/svgs/cc-icon.svg';
import './ClosedCaptionButton.scss';
import useMediaQuery from '@hooks/use-media-query';
import useAppStore from '@store/_app.js';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const ClosedCaptionButton = (props) => {
  const { ccIsOpen, setCcIsOpen, modalActive } = useAppStore((state) => ({
    ccIsOpen: state.ccIsOpen,
    setCcIsOpen: state.setCcIsOpen,
    modalActive: state.modalActive,
  }));

  const handleClick = useCallback(
    function () {
      setCcIsOpen(!ccIsOpen);
    },
    [setCcIsOpen, ccIsOpen]
  );

  const isMobile = useMediaQuery('(max-width: 850px)');

  const { className } = props;

  const rootClassNames = classnames(
    'ClosedCaptionButton',
    { 'ClosedCaptionButton--selected': ccIsOpen },
    { 'ClosedCaptionButton--modal': modalActive && isMobile },
    className
  );

  return (
    <div onClick={handleClick} className={rootClassNames}>
      <ClosedCaptionSVG />
    </div>
  );
};

ClosedCaptionButton.propTypes = propTypes;
ClosedCaptionButton.defaultProps = defaultProps;

export default ClosedCaptionButton;
