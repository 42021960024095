/* eslint-disable react/jsx-max-props-per-line */
import { useThree, useFrame } from '@react-three/fiber';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Box } from '@react-three/drei';
import useBus from 'use-bus';
import { useLocation } from 'wouter';
import hologramsData from '@data/holograms.js';
import EvercoastPlayer from '@components/canvas/EvercoastPlayer/EvercoastPlayer.jsx';
import { PlayerViewControls } from '@components/canvas/Controls/PlayerViewControls';
import useAppStore from '@store/_app.js';
import { DEBUG_MODE } from '@settings/settings.params.js';
import { navMarkers } from '@data/nav-markers.js';
import { EvecoastPlayerInstances } from '@components/canvas/EvercoastPlayer/EvercoastPlayerInstancedMeshes.jsx';
import { DebugGLTFScene } from '@components/canvas/DebugGLTFScene.jsx';
import useUserSessionStore from '@store/_user-session';
import { GOTO_END_WITH_DELAY, REMOVE_MARKER } from '@settings/settings.events.js';
import { NavigationMarkerInstances } from '@components/canvas/models/NavigationMarkerModel';
import VRQuizController from '@components/canvas/VR/VRQuizController';
import VREndScreenController from '@components/canvas/VR/VREndScreenController';
import { ROUTE_PATHS } from '@settings/settings.app.js';
import { useVRCore } from '../VR/VR';
import { NavigationMarker } from '../NavigationMarker/NavigationMarker';
import { BasicLoader } from '../BasicLoader/BasicLoader';

export default function Metaverse() {
  return <Content></Content>;
}

const METAVERSE_MODEL_URL = `/3d/wholeset/2022-12-13-t-3-31pm/r6/r6.glb`;

function Content() {
  const scene = useThree((s) => s.scene);
  const [location, navigate] = useLocation();

  const setIsCanvasAssetsLoaded = useAppStore((state) => state.setIsCanvasAssetsLoaded);
  useEffect(() => {
    console.log('METAVERSE MOUNTED');
    setIsCanvasAssetsLoaded(true);
  }, []);

  const activeHolograms = useUserSessionStore((state) => state.activeHolograms);
  const removeHologramFromActiveHolograms = useUserSessionStore((state) => state.removeHologramFromActiveHolograms);

  const rootGroup = useRef();

  // useInteraction(rootGroup, 'onHover', (ev) => {
  //   console.log('XR onHover', ev);
  // });
  // useInteraction(rootGroup, 'onSqueezeStart', (ev) => {
  //   console.log('XR onSqueezeStart', ev);
  // });

  const hologramsDataMemo = useMemo(() => {
    return hologramsData;
  }, []);

  const holograms = useUserSessionStore((state) => state.holograms);

  useEffect(() => {
    console.log(holograms);
  }, [holograms]);

  useEffect(() => {
    const unsubscribeVisibilityCheck = useAppStore.subscribe(
      (state) => state.triviaHologramComplete,
      (value) => {
        // Moved this action to EvercoastPlayer > close()
        // setTimeout(() => {
        //   removeHologramFromActiveHolograms(value);
        // }, 2000);
      }
    );

    return () => {
      unsubscribeVisibilityCheck;
    };
  }, [activeHolograms]);

  const { setQuestion, setQuestionTip } = useAppStore((state) => ({
    setQuestion: state.setQuestion,
    setQuestionTip: state.setQuestionTip,
  }));

  const onQuestionOpen = useCallback(
    (id) => {
      if (id === undefined) return;
      setQuestion(id);
    },
    [setQuestion]
  );

  const onQuestionTipOpen = useCallback(
    (id) => {
      if (id === undefined) return;
      setQuestionTip(id);
    },
    [setQuestionTip]
  );

  const debugPositionRef = useRef();

  const navMarkerRefs = useRef([]);
  const [navMarkerPositions, setNavMarkerPositions] = useState(navMarkers);
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'n') {
        // n
        // Add a new marker at a debug marker position
        if (debugPositionRef.current) {
          setNavMarkerPositions((positions) => {
            if (debugPositionRef.current) {
              return [
                ...positions,
                { id: `${navMarkerPositions.length}`, position: debugPositionRef.current.position.toArray() }
              ];
            }
            return positions;
          });
        }
      } else if (e.key === '/') {
        // Log out an array of all marker positions
        // console.log(navMarkerRefs);
        const markersOutputArr = navMarkerRefs.current
          .filter((m) => {
            return !!m;
          })
          .map((m) => {
            if (m) {
              const userData = m.userData;
              return {
                id: userData.id,
                position: m.position.toArray(),
                ...(userData.destination ? userData.destination.toArray() : {}),
                type: userData.type,
              };
            }
          });
        console.info('markersOutputArr:', markersOutputArr);
      }
    },
    [navMarkerPositions, setNavMarkerPositions]
  );
  // Remove marker
  useBus(
    REMOVE_MARKER,
    ({ payload }) => {
      console.info('payload.id:', payload.id);
      const newNavPositions = navMarkerPositions.filter((n) => {
        return n.id !== payload.id;
      });
      newNavPositions.map((nav, i) => {
        nav.id = `${i}`;
      });
      setNavMarkerPositions(newNavPositions);
    },
    [navMarkerPositions, setNavMarkerPositions]
  );
  useEffect(() => {
    // const formatNavPositions = navMarkerPositions.map((p, i) => {
    //   return {
    //     id: `${i}`,
    //     position: p.position,
    //   };
    // });
    // console.info('navPositions:', formatNavPositions);
  }, [navMarkerPositions]);
  useEffect(() => {
    addEventListener('keydown', handleKeyDown);
    return () => {
      removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const showVRBtn = useVRCore((s) => s.showVRBtn);
  const inXRSession = useAppStore((s) => s.inXRSession);

  const onNavClick = function (e) {
    debugPositionRef.current.position.copy(e.point);
  };

  useBus(GOTO_END_WITH_DELAY, () => {
    setTimeout(() => {
      navigate(ROUTE_PATHS.endScreen);
    }, 1000);
  });

  // useFrame(({ scene }) => {
  //   scene.traverse((it) => {
  //     if (it.name === 'Cube072') {
  //       it.visible = false;
  //     }
  //     if (it.name === 'tunnelExample111') {
  //       it.visible = false;
  //     }
  //     if (it.name === 'Cube088_2' || it.name === 'tunnelExample106') {
  //       it.visible = false;
  //     }
  //   });
  // });
  //
  //

  return (
    <group>
      {!inXRSession && <PlayerViewControls />}
      <NavigationMarkerInstances>
        {navMarkerPositions.map((nav, i) => {
          return (
            <NavigationMarker
              ref={(nm) => {
                navMarkerRefs.current[i] = nm;
              }}
              key={`${nav.id}-nav-marker`}
              id={nav.id}
              position={nav.position}
            ></NavigationMarker>
          );
        })}
      </NavigationMarkerInstances>
      {/*<directionalLight color="white" position={[2, 5, -2]} visible={true} intensity={0.15} castShadow={true} />*/}
      {/*<group rotation={[0, Math.PI * -0.5, 0]} position={[0, -1.8, 0]}>*/}

      {/*  */}
      <DebugGLTFScene debugPositionRef={debugPositionRef}>
        <BasicLoader
          markers={navMarkerPositions}
          url={METAVERSE_MODEL_URL}

          // url={`/3d/wholeset/2022-12-09-t-0653pm/tMobileLayout-v1-compressed.glb`}
          // url={`/3d/wholeset/2022-12-07-t-05-30pm/r3/tMobileLayout-v2-compression.glb`}

          // url={`/3d/wholeset/2022-12-07-t-05-30pm/r2/t-mobile-compressed.glb`}
          // url={`/3d/wholeset/2022-12-07-t-05-30pm/tMobileLayout-v1-compress.glb`}
          // url={`/3d/wholeset/2022-12-06-t-05-38pm/tMobileLayout-v1-Compress.glb`}
          // url={`/3d/wholeset/2022-12-03-t-12-03-am/op-t-img-webp-dedup-instance.glb`}
          // url={`/3d/wholeset/2022-12-02-t-12-20pm/op-t-img-webp-dedup-instance.glb`}
          // url={`/3d/wholeset/2022-12-02-t-12-41pm/op-t-img-webp-dedup-instance.glb`}
          // url={`/3d/wholeset/2022-12-01-t-11-26-am/op-dedup-inst-webp.glb`}
        ></BasicLoader>
      </DebugGLTFScene>

      <>
        {inXRSession && (
          <>
            <VRQuizController renderOrder={2} />
            <VREndScreenController />
          </>
        )}
      </>

      {/* <SnowCompo></SnowCompo> */}
      {/* <BridgeInstances ref={bridgeInstanceRef}> */}
      {/* <BridgeModel rotation={[0, Math.PI * -0.5, 0]} position={[0, -1.8, 0]}></BridgeModel> */}
      {/* <BridgeModel rotation={[0, Math.PI * -0.5, 0]} position={[0, -1.8, 22.12]}></BridgeModel>
          <BridgeModel rotation={[0, Math.PI * -0.5, 0]} position={[0, -1.8, 22.12 * 2]}></BridgeModel> */}
      {/* </BridgeInstances> */}
      {/* <group position={[-3, 0, 0]}>
          <Snow amount={256} spread={[0.05, 1, 2]}></Snow>
        </group>
        <group position={[6.5, 0, 0]}>
          <Snow amount={256} spread={[0.05, 1, 2]}></Snow>
        </group> */}
      {/*<WeatherControlMachine></WeatherControlMachine>*/}
      <EvecoastPlayerInstances>
        <group>
          {hologramsDataMemo.map((hologram) => {
            if (activeHolograms.includes(hologram.id)) {
              return (
                <EvercoastPlayer
                  key={hologram.id}
                  {...hologram}
                  onQuestionOpen={onQuestionOpen}
                  onQuestionTipOpen={onQuestionTipOpen}
                />
              );
            }
          })}
        </group>
      </EvecoastPlayerInstances>
      {DEBUG_MODE && (
        <Box ref={debugPositionRef} args={[0.2, 0.2, 0.2]}>
          <meshBasicMaterial color="blue" />
        </Box>
      )}
    </group>
  );
}

// useGLTF.preload(METAVERSE_MODEL_URL);

// "Cube180"
