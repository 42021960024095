export const navMarkers = [
  {
    'id': '0',
    'position': [
      26.48620333267011,
      1.7301204681396483,
      -2.9313243051345133
    ],
    'type': 'marker'
  },
  {
    'id': '1',
    'position': [
      9.896937250177794,
      1.702365348912258,
      -1.850829527034542
    ],
    'type': 'marker'
  },
  {
    'id': '2',
    'position': [
      27.29847436546959,
      1.7301204681396483,
      -15.385448961522302
    ],
    'type': 'marker'
  },
  {
    'id': '3',
    'position': [
      26.234961200102088,
      1.7683,
      -45.07192064625894
    ],
    'type': 'marker'
  },
  {
    'id': '4',
    'position': [
      -28.10605363928616,
      1.735050220489502,
      -45.77755433593376
    ],
    'type': 'marker'
  },
  {
    'id': '5',
    'position': [
      3.006626681931974,
      1.73,
      -45.284890397709475
    ],
    'type': 'marker'
  },
  {
    'id': '6',
    'position': [
      -44.490865385339994,
      1.735050220489502,
      -34.78318466462263
    ],
    'type': 'marker'
  },
  {
    'id': '7',
    'position': [
      -44.31002888757209,
      1.735050220489502,
      -27.209951664044652
    ],
    'type': 'marker'
  },
  {
    'id': '8',
    'position': [
      -34.24475807183955,
      1.735050220489502,
      -21.907194266657235
    ],
    'type': 'marker'
  },
  {
    'id': '9',
    'position': [
      -18.450999352180954,
      1.735050220489502,
      -12.330718968117598
    ],
    'type': 'marker'
  },
  {
    'id': '10',
    'position': [
      -18.06976739772693,
      1.735050220489502,
      -0.40697036399844555
    ],
    'type': 'marker'
  },
  {
    'id': '11',
    'position': [
      -53.47999874809799,
      1.735050220489502,
      -1.6639405512329564
    ],
    'type': 'marker'
  },
  {
    'id': '12',
    'position': [
      -69.85997111740504,
      5.995050220489501,
      -1.62626726079879
    ],
    'type': 'marker'
  },
  {
    'id': '13',
    'position': [
      -96.52437054108135,
      5.995050220489501,
      -5.868574480771668
    ],
    'type': 'marker'
  },
  {
    'id': '14',
    'position': [
      -103.19065002003238,
      5.995050220489502,
      -41.733167014601634
    ],
    'type': 'marker'
  },
  {
    'id': '15',
    'position': [
      -77.3164802754105,
      5.995050220489502,
      -40.91060418710333
    ],
    'type': 'marker'
  },
  {
    'id': '16',
    'position': [
      -63.08399558496139,
      5.997269077301025,
      -47.174750917151606
    ],
    'type': 'marker'
  },
  {
    'id': '17',
    'position': [
      27.39850261840384,
      1.7716633670105097,
      -30.240002448951643
    ],
    'type': 'marker'
  },
  {
    'id': '18',
    'position': [
      -42.48850655621344,
      -9.057671606280145,
      -45.94133772708504
    ],
    'type': 'marker'
  },
  {
    'id': '19',
    'position': [
      -51.1665496489507,
      1.7362934418897567,
      -45.33556390859738
    ],
    'type': 'marker'
  },
  {
    'id': '20',
    'position': [
      14.807741646773152,
      1.76832377910614,
      -49.34916187965557
    ],
    'type': 'marker'
  },
  {
    'id': '21',
    'position': [
      16.487671394659774,
      1.7679269313812258,
      -19.730005730509045
    ],
    'type': 'marker'
  },
  {
    'id': '22',
    'position': [
      -34.29061217586947,
      1.7717661857604978,
      -34.7654492892859
    ],
    'type': 'marker'
  },
  {
    'id': '23',
    'position': [
      -82.79608731995337,
      6.000000238418579,
      4.5745281008558765
    ],
    'type': 'marker'
  },
  {
    'id': '24',
    'position': [
      -82.23207474196325,
      6.000000238418579,
      -8.334622401279074
    ],
    'type': 'marker'
  },
  {
    'id': '25',
    'position': [
      -100.84034263612844,
      6.000000476837158,
      -18.75995375050719
    ],
    'type': 'marker'
  },
  {
    'id': '26',
    'position': [
      -44.97068454941218,
      1.771766185760498,
      -14.215559391922707
    ],
    'type': 'marker'
  },
  {
    'id': '27',
    'position': [
      -93.90563954415504,
      6,
      3.8456606728850335
    ],
    'type': 'marker'
  },
  {
    'id': '28',
    'position': [
      -71.24596877804589,
      6,
      -45.76083997565385
    ],
    'type': 'marker'
  },
  {
    'id': '29',
    'position': [
      -82.2839124954405,
      6,
      -46.59340175443204
    ],
    'type': 'marker'
  },
  {
    'id': '30',
    'position': [
      -34.17347329401904,
      1.7717661857604983,
      -1.2256781237692636
    ],
    'type': 'marker'
  },
  {
    'id': '31',
    'position': [
      -94.93865309888119,
      6,
      -41.26731152380951
    ],
    'type': 'marker'
  },
  {
    'id': '32',
    'position': [
      -33.96284252350648,
      1.7717661857604983,
      -10.279224255103284
    ],
    'type': 'marker'
  },
  {
    'id': '33',
    'position': [
      -42.812081787096965,
      1.771766185760498,
      -1.515231282758343
    ],
    'type': 'marker'
  },
  {
    'id': '34',
    'position': [
      -104.36139031568841,
      6,
      -9.163297786986817
    ],
    'type': 'marker'
  },
  {
    'id': '35',
    'position': [
      -8.56633415680888,
      1.7714893342011628,
      0.00828747938025065
    ],
    'type': 'marker'
  },
  {
    'id': '36',
    'position': [
      -24.693607225691046,
      1.771766185760498,
      -8.069862173059306
    ],
    'type': 'marker'
  },
  {
    'id': '37',
    'position': [
      -13.064358540607195,
      1.7683236072812087,
      -45.021959498669816
    ],
    'type': 'marker'
  },
  {
    'id': '38',
    'position': [
      -101.11310055331764,
      6.000000476837158,
      -31.496581488858304
    ],
    'type': 'marker'
  },
  {
    'id': '39',
    'position': [
      -83.04128226587676,
      6,
      -42.23022965430537
    ],
    'type': 'marker'
  },
  {
    'id': '40',
    'position': [
      -34.874658958566236,
      1.7717661857604976,
      -42.90968221412773
    ],
    'type': 'marker'
  },
  {
    'id': '41',
    'position': [
      -26.118409358134116,
      1.7717661857604983,
      -20.500099999296772
    ],
    'type': 'marker'
  },
  {
    'id': '42',
    'position': [
      -29.06752623146603,
      1.7717661857604978,
      -37.07305349832824
    ],
    'type': 'marker'
  },
  {
    'id': '43',
    'position': [
      -64.18536465325086,
      1.7717661857604978,
      -45.86578688046922
    ],
    'type': 'marker'
  },
  {
    'id': '44',
    'position': [
      -50.80956184590142,
      5.999994046328538,
      -46.30705877457934
    ],
    'type': 'marker'
  },
  {
    'id': '45',
    'position': [
      18.36106843895115,
      1.7679269313812254,
      -1.9557479230175332
    ],
    'type': 'marker'
  },
  {
    'id': '46',
    'position': [
      -43.0691363584702,
      1.771766185760499,
      -46.88154791355639
    ],
    'type': 'marker'
  }
];
