import { DEBUG_MODE } from '@settings/settings.params';

const HOLOGRAM_SCALE = 1.15;

const hologramsData = [
  {
    name: 'Mike Sievert',
    jobTitle: 'President & Chief Executive Officer',
    id: 'MIKESIEVERT',
    scale: HOLOGRAM_SCALE,
    position: [1.8158540551698334, 1.7707343917349991, -0.31995814005129586],
    //position: [-100.242919492365, 6.000000476837158, -35.732936320974055],
    rotation: [0, 0.8517522816460077, 0],
    //rotation: [-3.141592653589793, 1.2437297025702618, -3.141592653589793],
    showTipTiming: 19,
    showQuestionTiming: 12,
    active: true,
    showAtStart: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.MIKESIEVERT.ec.take.002/4303/TMOBILEMETAVERSE.MIKESIEVERT.ec.take.002.ecm',
  },
  {
    name: 'Néstor Cano',
    jobTitle: 'EVP, Integration and Transformation',
    id: 'NESTOR',
    scale: HOLOGRAM_SCALE,
    position: [14.955374653885896, 1.7717185020446777, -10.876178947505478],
    rotation: [0, 1.2777132927656711, 0],
    showTipTiming: 58,
    showQuestionTiming: DEBUG_MODE ? 3 : 46,
    active: true,
    showAtStart: false,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.NESTER.ec.take.003/4304/TMOBILEMETAVERSE.NESTER.ec.take.003.ecm',
  },
  {
    name: 'Marcus East',
    jobTitle: 'EVP & Chief Digital Officer',
    id: 'MARCUSEAST',
    scale: HOLOGRAM_SCALE,
    position: [29.560033405296974, 1.7717185020446777, 3.7440812012359967],
    rotation: [0, -0.41607132206084474, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.MARCUSEAST.ec.take.003/4305/TMOBILEMETAVERSE.MARCUSEAST.ec.take.003.ecm',
  },
  {
    name: 'Ulf Ewaldsson',
    jobTitle: 'EVP & Chief Network Officer',
    id: 'ULFEWALDSSON',
    scale: HOLOGRAM_SCALE,
    position: [-25.98656087294556, 1.7679269313812256, -34.58305602083356],
    rotation: [0, -0.033259037532865125, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.ULFEWALDSSON.ec.take.003/4306/TMOBILEMETAVERSE.ULFEWALDSSON.ec.take.003.ecm',
  },
  {
    name: 'Peter A. Ewens',
    jobTitle: 'EVP, Corporate Strategy & Development',
    id: 'PETEREWENS',
    scale: HOLOGRAM_SCALE,
    position: [33.49812212902516, 1.7717, -43.6598294186101],
    rotation: [0, 1.0858046916737056, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.PETER.ec.take.002/4307/TMOBILEMETAVERSE.PETER.ec.take.002.ecm',
  },
  {
    name: 'Callie Field',
    jobTitle: 'President, Business Group',
    id: 'CALLIE',
    scale: HOLOGRAM_SCALE,
    position: [-28.183625080371037, 1.7717, -49.936757565736784],
    rotation: [-3.141592653589793, 1.3678424653956625, -3.141592653589793],
    active: true,
    limitOrbitRange: Math.PI * 0.5,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.CALLIE.ec.take.002/4308/TMOBILEMETAVERSE.CALLIE.ec.take.002.ecm',
  },
  {
    name: 'Jon Freier',
    jobTitle: 'President, Consumer Group',
    id: 'JONFREIER',
    scale: HOLOGRAM_SCALE,
    position: [-48.150655602223516, 1.7717, -26.235073311151698],
    rotation: [-3.141592653589793, -0.7877812471525515, 3.141592653589793],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.JONFREIER.ec.take.002/4309/TMOBILEMETAVERSE.JONFREIER.ec.take.002.ecm',
  },
  {
    name: 'Janice V. Kapner',
    jobTitle: 'EVP & Chief Communications Officer',
    id: 'JANICEKAPNER',
    scale: HOLOGRAM_SCALE,
    position: [-18.024, 1.7717, 2.5],
    rotation: [0, -0.2, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.JANICEKAPNER.ec.take.005/4310/TMOBILEMETAVERSE.JANICEKAPNER.ec.take.005.ecm',
  },
  {
    name: 'Mike Katz',
    jobTitle: 'Chief Marketing Officer',
    id: 'MIKEKATZ',
    scale: HOLOGRAM_SCALE,
    position: [-65.71, 6, -7.515],
    rotation: [0, 1.2703645277354756, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.MIKEKATZ.ec.take.002/4311/TMOBILEMETAVERSE.MIKEKATZ.ec.take.002.ecm',
  },
  {
    name: 'Brian King',
    jobTitle: 'EVP & Chief Information Officer',
    id: 'BRIANKING',
    scale: HOLOGRAM_SCALE,
    position: [-95.7324414472904, 6, -23.092517502210416],
    rotation: [0, 0.9947106436981843, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.BRIANKING.ec.take.002/4312/TMOBILEMETAVERSE.BRIANKING.ec.take.002.ecm',
  },
  {
    name: 'Deeanne King',
    jobTitle: 'EVP & Chief Human Resources Officer',
    id: 'DEEANNEKING',
    scale: HOLOGRAM_SCALE,
    position: [-76.05859196282856, 6, -36.7597760399075],
    rotation: [0, -0.5289956221207095, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.DEEANNEKING.ec.take.002/4313/TMOBILEMETAVERSE.DEEANNEKING.ec.take.002.ecm',
  },
  {
    name: 'Susan Loosmoore',
    jobTitle: 'EVP, Financial Planning & Analysis',
    id: 'SUSANLOOSEMORE',
    scale: HOLOGRAM_SCALE,
    position: [-55.28, 1.77, -6.1115],
    rotation: [0, 2, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.SUSANLOOSEMORE.ec.take.003/4323/TMOBILEMETAVERSE.SUSANLOOSEMORE.ec.take.003.ecm',
  },
  {
    name: 'Mark Nelson',
    jobTitle: 'EVP& General Counsel',
    id: 'MARKNELSON',
    scale: HOLOGRAM_SCALE,
    position: [-94.05219567168764, 6.000000238418579, -46.733538199883384],
    rotation: [-3.141592653589793, 1.1262174503528883, -3.141592653589793],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.MARKNELSON.ec.take.002/4498/TMOBILEMETAVERSE.MARKNELSON.ec.take.002.ecm',
  },
  {
    name: 'Peter Osvaldik',
    jobTitle: 'EVP & Chief Financial Officer',
    id: 'PETEROSVALDIK',
    scale: HOLOGRAM_SCALE,
    position: [-53.90567276774876, 1.7654824185472227, -47.39231800112205],
    rotation: [-Math.PI, -0.10336604846994311, -Math.PI],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.PETEROSVALDIK.ec.take.002/4325/TMOBILEMETAVERSE.PETEROSVALDIK.ec.take.002.ecm',
  },
  {
    name: 'Neville R. Ray',
    jobTitle: 'President of Technology',
    id: 'NEVILLERAY',
    scale: HOLOGRAM_SCALE,
    position: [-60.12521391439861, 5.999999291537279, -44.84345869731009],
    rotation: [0, 0.3340977424326271, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.NEVILLERAY.ec.take.003/4326/TMOBILEMETAVERSE.NEVILLERAY.ec.take.003.ecm',
  },
  {
    name: 'Abdul Saad',
    jobTitle: 'EVP & Chief Technology Officer',
    id: 'ABDULSAAD',
    scale: HOLOGRAM_SCALE,
    position: [31.98444966010826, 1.7679269313812256, -16.120973266812868],
    rotation: [1.4693878877865764e-16, 0.9058045923491297, -1.5577309287962982e-16],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.ABDULSAAD.ec.take.005/4327/TMOBILEMETAVERSE.ABDULSAAD.ec.take.005.ecm',
  },
  {
    name: 'John Saw',
    jobTitle: 'EVP, Advanced & Emerging Technologies',
    id: 'JOHNSAW',
    scale: HOLOGRAM_SCALE,
    position: [14.378142480178262, 1.7844628285881075, -43.64882841844071],
    rotation: [0, -1.556951357346295, 0],
    active: true,
    streamURL:
      'https://streaming.evercoast.com/T-Mobile/TMOBILEMETAVERSE.JOHNSAW.ec.take.002/4629/TMOBILEMETAVERSE.JOHNSAW.ec.take.002.ecm',
  }
];

export default hologramsData;

export function getHologramByID(id){
  return hologramsData.find(obj => {
    return obj.id === id;
  });
}
