import { Center, Plane } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Interactive, useXR } from '@react-three/xr';
import { useEffect, useRef, useState } from 'react';
import { commonMaterials } from '@components/canvas/common-materials.js';
import { heightAtDepth, RenderText, widthAtDepth } from '../RenderText/RenderText';
import { VRStickyUI } from '../VRStickyUI/VRStickyUI';

export function VRQuiz({ progress = 0.2, inVROnly = false, questionData, handleOnAnswer }) {
  const session = useXR((s) => s.session);
  const camera = useThree((s) => s.camera);
  const w = widthAtDepth(1, camera);
  const h = heightAtDepth(1, camera);

  const full = 1.6;
  const length = full * progress;

  const rootObj = useRef();
  const planeRef = useRef();

  useEffect(() => {
    if (rootObj && rootObj.current) {
      rootObj.current.renderOrder = 3;
    }
  }, [rootObj]);

  //const handleOnAnswerCorrect

  const onSelecthandler = function(questionAnswer){
    //console.info('questionAnswer:', questionAnswer);
    handleOnAnswer(questionAnswer.label, questionData);
    if (questionData.correct === questionAnswer.label || questionData.correct === ''){
      // correct answer
    } else {
      // incorrect answer
    }
  };

  const onHoverHandler = function(answerId){
    setHoverActiveOn(answerId);
  };

  const onHoverOutHandler = function(){
    setHoverActiveOn('');
  };

  const [hoverActiveOn, setHoverActiveOn] = useState();

  const ansFontSize = 32;

  const content = (
    <>
      {questionData && (
        <group
          ref={rootObj}
          scale={[0.5, 0.5, 1]}
          onClick={() => {
            //
          }}
          position={[0, 0, -1]}
        >
          <group position={[0, 0, -0.5]} scale={1.9}>
            <Plane ref={planeRef} renderOrder={3} args={[1.6, 1]} material={commonMaterials.whiteVRBacking}>
            </Plane>
            {/*<Plane position={[0, -0.5 - 0.01 / 2, 0]} args={[full, 0.01]}>*/}
            {/*  <meshBasicMaterial color={'white'} opacity={1}></meshBasicMaterial>*/}
            {/*</Plane>*/}
            {/*<group position={[-full / 2, -0.5 - 0.01 / 2, 0]}>*/}
            {/*  <Plane position={[length / 2, 0, 0.001]} args={[length, 0.01]}>*/}
            {/*    <meshBasicMaterial color={'#E20074'} opacity={1} transparent></meshBasicMaterial>*/}
            {/*  </Plane>*/}
            {/*</group>*/}
          </group>
          <group position={[0, 0.22, 0.12]} scale={[1.3, 1.3, 1]}>
            <group position={[0, 0.095, 0]}>
              <RenderText
                hScale={1}
                lineSpacing={10}
                fontSize={43}
                text={questionData.question}
                constrainWidth={1000}>
              </RenderText>
            </group>
            <group position={[0, -0.05, 0.0]}>
              <Interactive onHover={() => {
                onHoverHandler('a');
              }} onBlur={() => {
                onHoverOutHandler();
              }} onSelect={() => {
                onSelecthandler(questionData.answers[0]);
              }}>
                <group position={[0, -0.2 - 0.01 * 1, 0.0]}>
                  <RenderText border={true} fontSize={ansFontSize} lineSpacing={0} aspect={2} prefix={`A. `} hover={hoverActiveOn === 'a'} text={questionData.answers[0].answer}></RenderText>
                </group>
              </Interactive>
              <Interactive onHover={() => {
                onHoverHandler('b');
              }} onBlur={() => {
                onHoverOutHandler();
              }} onSelect={() => {
                onSelecthandler(questionData.answers[1]);
              }}>
                <group position={[0, -0.3 - 0.01 * 2, 0.0]}>
                  <RenderText border={true} fontSize={ansFontSize} lineSpacing={0} aspect={2} prefix={`B. `} hover={hoverActiveOn === 'b'} text={questionData.answers[1].answer}></RenderText>
                </group>
              </Interactive>
              <Interactive onHover={() => {
                onHoverHandler('c');
              }} onBlur={() => {
                onHoverOutHandler();
              }} onSelect={() => {
                onSelecthandler(questionData.answers[2]);
              }}>
                <group position={[0, -0.4 - 0.01 * 3, 0.0]}>
                  <RenderText border={true} fontSize={ansFontSize} lineSpacing={0} aspect={2} prefix={`C. `} hover={hoverActiveOn === 'c'} text={questionData.answers[2].answer}></RenderText>
                </group>
              </Interactive>
            </group>
          </group>
        </group>
      )}
    </>
  );

  //
  return <group><VRStickyUI lerp={0.9}>{content}</VRStickyUI></group>;
}
