import { mountStoreDevtool } from 'simple-zustand-devtools';
import useWindowSizeStore from './_window-size';
import useCanvasStore from './_canvas';
import useNonFunctionalStore from './_non-functional';
import useUserSessionStore from './_user-session';
import useAppStore from './_app';

export { useWindowSizeStore, useCanvasStore, useNonFunctionalStore, useAppStore, useUserSessionStore };

console.info('process.env.NODE_ENV:', process.env.NODE_ENV);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('AppStore', useAppStore);
  mountStoreDevtool('CanvasStore', useCanvasStore);
  mountStoreDevtool('SessionStore', useUserSessionStore);
}
