import React, { useEffect, useRef, useState } from 'react';
import useBus, { dispatch } from 'use-bus';
import { useXR } from '@react-three/xr';
import useAppStore from '@store/_app.js';
import useUserSessionStore from '@store/_user-session.js';
import { Sleep } from '@utils/sleep.js';
import {
  EVENT_ANSWER_CORRECT,
  EVENT_ANSWER_WRONG, EVENT_OPEN_QUESTION,
  EVENT_WIN_POINTS
} from '@settings/settings.events.js';
import { VRQuiz } from '@components/canvas/VRQuiz/VRQuiz';
import { VRCloseButton } from '@components/canvas/VRCloseButton/VRCloseButton.jsx';
import { VRScore } from '@components/canvas/VRScore/VRScore.jsx';
import { DEBUG_MODE } from '@settings/settings.params.js';

export default function VRQuizController({ ...props }){
  const hintSound = useRef();
  const hintTimeout = useRef();
  const closeTimeout = useRef();
  const modalActiveTimeout = useRef();
  const answerTimeout = useRef();
  const [active, setActive] = useState(false);
  const [pointerEvents, setPointerEvents] = useState(false);
  const session = useXR((s) => s.session);

  const [tipActive, setTipActive] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(true);
  const [answeredQuestion, setAnsweredQuestion] = useState('');
  const [questionIsCorrect, setQuestionIsCorrect] = useState(false);
  const [hintSoundPlaying, setHintSoundPlaying] = useState(false);

  const setQuestion = useAppStore((state) => state.setQuestion);
  const question = useAppStore((state) => state.question);
  const setTriviaHologramComplete = useAppStore((state) => state.setTriviaHologramComplete);
  const setModalActive = useAppStore((state) => state.setModalActive);

  const trackUserAnswer = useUserSessionStore((state) => state.trackUserAnswer);
  const triviaQuestions = useUserSessionStore((state) => state.triviaQuestions);
  const increasePoints = useUserSessionStore((state) => state.increasePoints);
  const trackUserHolograms = useUserSessionStore((state) => state.trackUserHolograms);
  const setUserCharityChoice = useUserSessionStore((state) => state.setUserCharityChoice);

  const close = async () => {
    if (active) {
      clearTimeout(hintTimeout.current);

      setActive(false);
      setTipActive(false);
      setQuestion('');

      await Sleep(100);

      setShowQuestion(false);
      setShowSuccessMessage(false);
    }
  };

  // useEffect(() => {
  //   if (session && DEBUG_MODE){
  //     setQuestion('PETEROSVALDIK');
  //     //setQuestion('JOHNSAW');
  //   }
  // }, [session]);

  useBus(EVENT_OPEN_QUESTION, ({ payload }) => {
    if (session){
      setQuestion(payload.id);
    }
  }, [session]);

  // const { togglePlayPause, ready, loading, playing } = useAudioPlayer({
  //   src: file,
  //   format: "mp3",
  //   autoplay: false,
  //   onend: () => console.log("sound has ended!")
  // })

  const handleOnAnswer = (answerSlug) => {
    const triviaQuestion = triviaQuestions.find((q) => q.slug === question);
    if (triviaQuestion.answered) return;

    setAnsweredQuestion(answerSlug);

    // answerTimeout.current = setTimeout(() => {
    //   setAnsweredQuestion('');
    // }, 500);

    if (triviaQuestion.correct === answerSlug || triviaQuestion.correct === '') {
      //clearTimeout(hintTimeout.current);

      dispatch(EVENT_ANSWER_CORRECT);
      setQuestionIsCorrect(true);
      setTriviaHologramComplete(triviaQuestion.slug);
      trackUserHolograms(triviaQuestion.slug);
      setPointerEvents(true);
      setShowQuestion(false);
      setShowSuccessMessage(true);

      console.log('setShowSuccessMessage');

      // charity choice
      if (triviaQuestion.correct === '') {
        const charityChoice = triviaQuestion.answers.find((answer) => answer.label === answerSlug);
        setUserCharityChoice(charityChoice.answer);
      }

      // close modal
      modalActiveTimeout.current = setTimeout(() => {
        console.log('modalActiveTimeout');
        dispatch(EVENT_WIN_POINTS);
        increasePoints(1);
        setModalActive(false);
        trackUserAnswer(triviaQuestion.slug);
      }, 100);

      // close message
      closeTimeout.current = setTimeout(() => {
        console.log('closeTimeout');
        close();
      }, 2000);
    } else {
      dispatch(EVENT_ANSWER_WRONG);
      clearTimeout(hintTimeout.current);
      hintTimeout.current = setTimeout(() => {
        setHintSoundPlaying(true);
        setTipActive(true);
      }, 100);
    }
  };

  useEffect(() => {
    if (!active) return;

    clearTimeout(hintTimeout.current);
    clearTimeout(closeTimeout.current);
    clearTimeout(modalActiveTimeout.current);

    setShowQuestion(true);
    setShowSuccessMessage(false);

    hintTimeout.current = setTimeout(() => {
      setHintSoundPlaying(true);
      setTipActive(true);
    }, 7000);

    return () => {
      clearTimeout(hintTimeout.current);
      setHintSoundPlaying(false);
    };
  }, [active]);

  const [currentQuestionData, setCurrentQuestionData] = useState();

  useEffect(() => {
    if (question) {

      const triviaQuestion = triviaQuestions.find((q) => q.slug === question);
      if (triviaQuestion.answered) setQuestionIsCorrect(true);

      setCurrentQuestionData(triviaQuestion);
      setActive(true);
      setPointerEvents(false);
      setModalActive(true);

    } else {
      // cleanup
    }
  }, [question]);

  useEffect(() => {
    //
  }, [showQuestion]);

  return (
    <>
      {active && (
        <group renderOrder={2} {...props}>
          <VRQuiz handleOnAnswer={handleOnAnswer} questionData={currentQuestionData} />
          <VRCloseButton inVROnly={true}></VRCloseButton>
          <VRScore inVROnly={true}></VRScore>
        </group>
      )}
    </>
  );
}
