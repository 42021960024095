import { useEffect } from 'react';
import gsap from 'gsap';
import SplitText from 'gsap/dist/SplitText';

const useGsapPlugins = () => {

  // register gsap plugins here
  useEffect(() => {
    gsap.registerPlugin(SplitText);
  }, []);
};

export default useGsapPlugins;
