import { useThree } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import useAppStore from '@store/_app.js';
import useCanvasStore from '@store/_canvas.js';

export default function useCameraOrXRPlayer(){
  const inXRSession = useAppStore((s) => s.inXRSession);
  const globalPlayer = useCanvasStore((s) => s.globalPlayerObject);
  const camera = useThree(s => s.camera);
  const [cameraObject, setCameraObject] = useState(camera);

  useEffect(() => {
    if (inXRSession && globalPlayer){
      setCameraObject(globalPlayer);
    } else {
      setCameraObject(camera);
    }
  }, [inXRSession, globalPlayer, camera]);
  return cameraObject;
}
