import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './FooterNav.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const FooterNav = (props) => {
  const { className } = props;

  return (
    <div className={classnames('FooterNav', className)}>
      <ul>
        <li>
          <a
            href="https://www.t-mobile.com/privacy-center/privacy-notices/t-mobile-privacy-notice"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="https://rules.creativezing.com/TMobileHolidaySweeps/" target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>
        </li>
        <li>
          <a href="https://s3.us-west-1.amazonaws.com/gameofphonesvr.com/employee-rules.pdf" target="_blank" rel="noopener noreferrer">
            EMPLOYEE RULES
          </a>
        </li>
      </ul>
    </div>
  );
};

FooterNav.propTypes = propTypes;
FooterNav.defaultProps = defaultProps;

export default FooterNav;
