import './HologramCtas.scss';
import React from 'react';
import { Html } from '@react-three/drei';
import classnames from 'classnames';
import Button from '../Button/Button';
import Link from '../Link/Link';

const HologramCtas = (props) => {
  const {
    onClick,
    position = [0, 0.75, 0],
    isLoaded,
    isVisible,
    ctaActive,
    clickToPlay,
    clickToSkip,
    skipButtonActive,
    interactive
  } = props;

  return (
    <Html
      center
      position={position}
      wrapperClass="HologramCtas"
      className={classnames('HologramCtas', { 'HologramCtas--disabled': onClick === undefined })}
    >
      {skipButtonActive && !isLoaded && isVisible && (
        <div className="HologramCtas__button HologramCtas__button--loading Button">Loading...</div>
      )}

      {isLoaded && isVisible && ctaActive && (
        <Button className="HologramCtas__button" onClick={clickToPlay} playSound={true}>
          Tap to play
        </Button>
      )}

      {skipButtonActive && isVisible && (
        <Link onClick={clickToSkip}>Select Answer</Link>
      )}
    </Html>
  );
};

export default HologramCtas;
