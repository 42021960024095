import { MeshBasicMaterial, Color, MeshStandardMaterial, DoubleSide, FrontSide } from 'three';

const MAGENTA = 0xE20074;
const WHITE = 0xffffff;

export const commonMaterials = {
  magenta: new MeshBasicMaterial({
    color: new Color(MAGENTA),
    // emissive: new Color(WHITE),
    // emissiveIntensity: 1,
    reflectivity: 0.8,
    envMapIntensity: 0.9,
    wireframe: false
  }),
  white: new MeshBasicMaterial({
    color: new Color(0xffffff),
    reflectivity: 1,
    wireframe: false
  }),
  whiteVRBacking: new MeshBasicMaterial({
    color: new Color(0xffffff),
    depthTest: true,
    side: FrontSide,
    transparent: false,
    wireframe: false
  }),
  wireframe: new MeshBasicMaterial({
    color: new Color(MAGENTA),
    depthWrite: true,
    depthTest: true,
    wireframe: true
  }),
  transparent: new MeshBasicMaterial({
    color: new Color(0, 0, 0),
    transparent: true,
    opacity: 0,
    depthWrite: false,
    depthTest: true
  })
};
