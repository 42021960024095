import './Snowfall.scss';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import useAppStore from '@store/_app';
import { CanvasSnow } from '@components/elements/Snowfall/CanvasSnow.js';

const Snowfall = forwardRef(({ running, className, ...props }, ref) => {
  const isLoading = useAppStore((state) => state.isLoading);

  const canvasRef = useRef();
  const canvasSnowRef = useRef();
  useEffect(() => {
    if (canvasRef) {
      const canvasSnow = (canvasSnowRef.current = new CanvasSnow());
      const options = {
        amount: 100,
        size: [8, 20],
        rotation: [1, 5],
        speed: [40, 80],
        swing: [0.1, 1],
        amplitude: [30, 50],
        alpha: [0.1, 0.95],
        images: ['/images/snowflakes/snowflake01.png', '/images/snowflakes/snowflake02.png'],
      };
      canvasSnow.init(canvasRef.current, options);
      // canvasSnow.start();
      window.addEventListener('resize', canvasSnow.resize);
      return () => {
        window.removeEventListener('resize', canvasSnow.resize);
        canvasSnow.stop();
        canvasSnow.destroy();
      };
    }
  }, [canvasRef]);

  useEffect(() => {
    console.log(isLoading);
    if (running) {
      if (!isLoading) canvasSnowRef.current && canvasSnowRef.current.start();
    } else {
      canvasSnowRef.current && canvasSnowRef.current.stop();
    }
  }, [running, canvasSnowRef, isLoading]);

  const rootClass = classnames(className, 'Snowfall');

  return (
    <div className={rootClass}>
      <canvas ref={canvasRef} className="Snowfall__canvas" />
    </div>
  );
});

export default Snowfall;
