import './Metaverse.scss';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import useAppStore from '@store/_app';
import Socials from '@components/elements/Socials/Socials';
import QuestionModal from '@components/elements/QuestionModal/QuestionModal';
import { DesignToggle } from '@components/helpers/DesignToggle/DesignToggle';
import Widget from '@components/elements/Widget/Widget';
import useMediaQuery from '@hooks/use-media-query';
import MobileWidget from '@components/elements/MobileWidget/MobileWidget';
import { DEBUG_MODE, VR_DEBUG } from '@settings/settings.params.js';
import OnBoarding from '@pages/OnBoarding/OnBoarding';
import triviaContent from '../../data/trivia';

const MetaverseUi = () => {
  const isMobile = useMediaQuery('(max-width: 850px)');

  return (
    <main className="Metaverse">
      {!isMobile && <Widget />}
      {isMobile && <MobileWidget />}

      {triviaContent.map((question) => (
        <QuestionModal key={question.slug} questionProp={question} />
      ))}
      {!isMobile && <Socials />}
      {DEBUG_MODE && <DesignToggle screen="metaverse-m" />}
    </main>
  );
};

const Metaverse = (props) => {
  const setView = useAppStore((state) => state.setView);
  const view = useAppStore((state) => state.view);
  const onBoardingCompleted = useAppStore((state) => state.onBoardingCompleted);
  const inXRSession = useAppStore((s) => s.inXRSession);

  const [showOnboarding, setShowOnboarding] = useState(false);
  useEffect(() => {
    if (onBoardingCompleted || VR_DEBUG) {
      setView('metaverse');
    } else {
      setView('onboarding');
      setTimeout(() => {
        setShowOnboarding(true);
      }, 1000);
    }
  }, []);

  return (
    <>
      {view === 'metaverse' && !inXRSession && <MetaverseUi />}
      {showOnboarding && view === 'onboarding' && !inXRSession && <OnBoarding />}
    </>
  );
};

export default Metaverse;
