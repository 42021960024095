/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import './EndScreen.scss';
import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'wouter';
import gsap from 'gsap';
import { dispatch } from 'use-bus';
import useAppStore from '@store/_app';
import Button from '@components/elements/Button/Button';
import Socials from '@components/elements/Socials/Socials';
import FooterNav from '@components/elements/FooterNav/FooterNav';
import useUserSessionStore from '@store/_user-session';
import { ROUTE_PATHS } from '@settings/settings.app';
import { DesignToggle } from '@components/helpers/DesignToggle/DesignToggle';
import TextGlow from '@components/elements/TextGlow/TextGlow';
import Link from '@components/elements/Link/Link';
import PointsTracker from '@components/elements/PointsTracker/PointsTracker';
import { DEBUG_MODE } from '@settings/settings.params.js';
import Snowfall from '@components/elements/Snowfall/Snowfall';
import SocialsCta from '@components/elements/SocialsCta/SocialsCta';
import useMediaQuery from '@hooks/use-media-query';
import { EVENT_WIN_POINTS } from '@settings/settings.events';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const EndScreenMessage = ({ points, totalPoints }) => {
  if (points === totalPoints) {
    return (
      <>
        <div data-animate>
          <TextGlow>Whoa!</TextGlow>
        </div>
        <p data-animate className="EndScreen__form__text--lg">
          You earned ALL {totalPoints} points!
        </p>
        <p data-animate>
          Talk about Magenta MAX! To enter tonight's drawing with that massive score, drop your email address below.`
        </p>
      </>
    );
  } else {
    return (
      <>
        <div data-animate>
          <TextGlow>Not bad!</TextGlow>
        </div>
        <p data-animate className="EndScreen__form__text--lg">
          You picked up {points} of the {totalPoints} possible points.
        </p>
        <p data-animate>
          That's better than zero! To be eligible for tonight's drawing, drop your email address below so we can contact
          you if you win!
        </p>
      </>
    );
  }
};

const EndScreen = (props) => {
  const isMobile = useMediaQuery('(max-width: 850px)');

  const { className } = props;
  const [location, navigate] = useLocation();

  const mainRef = useRef(null);
  const emailFormline = useRef(null);
  const checkboxRef = useRef(null);

  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [checkBoxError, setCheckBoxError] = useState(null);
  const [emailReady, setEmailReady] = useState(false);
  const [checkboxReady, setCheckboxReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setView = useAppStore((state) => state.setView);
  const pointsEarned = useUserSessionStore((state) => state.pointsEarned);
  const totalPoints = useUserSessionStore((state) => state.totalPoints);
  const userCharityChoice = useUserSessionStore((state) => state.userCharityChoice);

  const endScreenFormSubmitted = useUserSessionStore((state) => state.endScreenFormSubmitted);
  const setEndScreenFormSubmitted = useUserSessionStore((state) => state.setEndScreenFormSubmitted);
  const socialSharePointsReceived = useUserSessionStore((state) => state.socialSharePointsReceived);

  // The content has ended so make sure redirect the user to the thank you screen
  useEffect(() => {
    setEndScreenFormSubmitted(true);
  }, []);

  const animateIn = () => {
    if (!mainRef) return;
    gsap.fromTo(
      '[data-animate]',
      {
        y: 150,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 2.4,
        stagger: 0.07,
        ease: 'expo.out',
      }
    );
  };

  useEffect(() => {
    setView('endScreen');
    animateIn();
  }, []);

  const goBack = () => {
    navigate(ROUTE_PATHS.metaverse);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    if (!checkboxRef.current.checked) {
      setCheckBoxError('check this');
      return;
    }

    const myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      email: userEmail,
      slt_points: pointsEarned,
      charity_choice: userCharityChoice,
      bonus_points: socialSharePointsReceived ? 1 : 0,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(process.env.API_ENDPOINT, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setCheckBoxError(null);
        setEndScreenFormSubmitted(true);
        dispatch(EVENT_WIN_POINTS);
        setTimeout(() => {
          animateIn();
        }, 0);
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onCheckBoxChange = (event) => {
    setCheckboxReady(checkboxRef.current.checked);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    setEmailError(null);
  };

  const handleBlur = (event) => {
    const isValid = isValidEmail(event.target.value);
    setEmailReady(isValid);

    if (isValid) {
      setUserEmail(event.target.value);
    }
  };

  console.info('endScreenFormSubmitted:', endScreenFormSubmitted);

  return (
    <main ref={mainRef} className={classnames('EndScreen', className)}>
      <Snowfall className="EndScreen__snowfall" />

      {!endScreenFormSubmitted && !isMobile && (
        <div className="EndScreen__points">
          <PointsTracker isOpen={false} />
        </div>
      )}
      <div className="EndScreen__inner">
        {!endScreenFormSubmitted && (
          <div className="EndScreen__form">
            <div className="EndScreen__form__text">
              <EndScreenMessage points={pointsEarned} totalPoints={totalPoints} />
            </div>

            {/*<form className="EndScreen__form__lines" data-animate>*/}
            {/*  <div ref={emailFormline} className={`EndScreen__form__line ${emailError ? 'is-error' : ''}`}>*/}
            {/*    <input*/}
            {/*      type="email"*/}
            {/*      id="email"*/}
            {/*      onChange={handleChange}*/}
            {/*      onBlur={handleBlur}*/}
            {/*      name="email"*/}
            {/*      placeholder="youremailaddress@mail.com"*/}
            {/*    />*/}
            {/*    {emailError && <span className="EndScreen__form__error">{emailError}</span>}*/}
            {/*  </div>*/}

            {/*  <div className="EndScreen__form__info">*/}
            {/*    These email addresses will NOT be used for any other purpose. Open to legal US residents only. T-Mobile Employees: Use your work email address to enter an internal sweepstakes.*/}
            {/*  </div>*/}

            {/*  <div className="EndScreen__form__line EndScreen__form__line--checkbox">*/}
            {/*    <label htmlFor="tc">*/}
            {/*      <input ref={checkboxRef} onChange={onCheckBoxChange} type="checkbox" id="tc" name="tc" />*/}
            {/*      <span className="checkmark"></span>*/}
            {/*      <span>*/}
            {/*        I have read and agree to the{' '}*/}
            {/*        <a*/}
            {/*          href="https://rules.creativezing.com/TMobileHolidaySweeps/"*/}
            {/*          target="_blank"*/}
            {/*          rel="noopener noreferrer"*/}
            {/*        >*/}
            {/*          Terms & Conditions*/}
            {/*        </a>*/}
            {/*      </span>*/}
            {/*    </label>*/}
            {/*  </div>*/}

            {/*  <Button*/}
            {/*    className={classnames('EndScreen__form__button', {*/}
            {/*      'EndScreen__form__button--disabled': !checkboxReady || !emailReady || isSubmitting,*/}
            {/*    })}*/}
            {/*    onClick={handleSubmit}*/}
            {/*  >*/}
            {/*    Submit*/}
            {/*  </Button>*/}
            {/*</form>*/}
          </div>
        )}

        {endScreenFormSubmitted && (
          <div className="EndScreen__message">
            <div data-animate>
              <h2>Thanks for Playing Game of Phones!</h2>
            </div>
            <p data-animate>Happy Holidays from T-Mobile!</p>
            <div data-animate>
              <Socials />
            </div>
          </div>
        )}
      </div>
      <div className="EndScreen__return">
        <Link onClick={goBack}>Return to Game of Phones</Link>
      </div>
      {!endScreenFormSubmitted && (
        <>
          <SocialsCta />
          <FooterNav />
        </>
      )}
      {DEBUG_MODE && <DesignToggle screen="endscreen" />}
    </main>
  );
};

EndScreen.propTypes = propTypes;
EndScreen.defaultProps = defaultProps;

export default EndScreen;
