export function EvercoastPlayerMaterial(shader, settings, size, position) {
  shader.uniforms.uReveal = { value: 0.0 };
  shader.uniforms.uSize = { value: size };
  shader.uniforms.uGamma = { value: settings.gamma };
  shader.uniforms.uExposure = { value: settings.exposure };
  shader.uniforms.uContrast = { value: settings.contrast };
  shader.uniforms.uSaturation = { value: settings.saturation };
  shader.uniforms.uPosY = { value: position[1] - 1.76 };

  shader.vertexShader = shader.vertexShader.replace(
    '#include <common>',
    /* glsl */ `
    #include <common>
    // varying vec3 vViewDirection;
    varying vec3 vPos;
    uniform float uReveal;
    `
  );

  shader.vertexShader = shader.vertexShader.replace(
    '#include <project_vertex>',
    /* glsl */ `
      #include <project_vertex>;
      // vViewDirection = -mvPosition.xyz;
      vec4 worldPosition = vec4( transformed, 1.0 );
      vPos = (modelMatrix * worldPosition).xyz;
    `
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    '#include <common>',
    /* glsl */ `
    #include <common>
    // varying vec3 vViewDirection;
    uniform float uReveal;
    uniform float uExposure;
    uniform float uContrast;
    uniform float uGamma;
    uniform float uSaturation;
    uniform float uPosY;
    uniform vec3 uSize;
    varying vec3 vPos;

    vec3 adjustExposure(vec3 color, float value) {
      return (1.0 + value) * color;
    }

    vec3 adjustGamma(vec3 value, float param) {
      return vec3(pow(abs(value.r), param), pow(abs(value.g), param), pow(abs(value.b), param));
    }

    vec3 adjustContrast(vec3 color, float value) {
      return 0.5 + value * (color - 0.5);
    }

    vec3 adjustSaturation(vec3 rgb, float adjustment) {
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
    }
    `
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    '#include <output_fragment>',
    /* glsl */ `
    #include <output_fragment>
    float soft = 0.15;
    float posY = (vPos.y / (uSize.y + soft)) / 2.5;
    float pY = posY - (soft / 2.5);
    float p = smoothstep(pY, pY + soft,  uPosY + uReveal - (soft / 2.5));

    vec3 mixed = outgoingLight;

    mixed = adjustExposure(mixed, uExposure);
    mixed = adjustContrast(mixed, uContrast);
    mixed = adjustGamma(mixed, uGamma);
    mixed = adjustSaturation(mixed, uSaturation);

    gl_FragColor = vec4(mixed, diffuseColor.a * p );
  `
  );
}
