import './MobileWidget.scss';
import React, { useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'wouter';
import useAppStore from '@store/_app';
import { ROUTE_PATHS } from '@settings/settings.app';
import useUserSessionStore from '@store/_user-session';
import WidgetCloseButton from '../WidgetCloseButton/WidgetCloseButton';
import WidgetMap from '../WidgetMap/WidgetMap';
import Button from '../Button/Button';
import PointsTrackerAnimation from '../PointsTrackerAnimation/PointsTrackerAnimation';
import PointsTracker from '../PointsTracker/PointsTracker';
import Socials from '../Socials/Socials';

const MobileWidget = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [location, navigate] = useLocation();

  const pointsAddedActive = useAppStore((state) => state.pointsAddedActive);
  const pointsEarned = useUserSessionStore((state) => state.pointsEarned);

  const toggle = () => {
    setOpen(!isOpen);
  };

  const goToEndScreen = () => {
    navigate(ROUTE_PATHS.endScreen);
  };

  return (
    <div
      className={classnames('MobileWidget', { 'Widget--points-earned': pointsAddedActive }, { 'Widget--open': isOpen })}
    >
      <div className="MobileWidget__inner">
        {isOpen && (
          <div className="MobileWidget__content">
            <WidgetMap />
          </div>
        )}
        <div
          className={classnames('MobileWidget__content__footer', {
            'MobileWidget__content__footer--disabled': pointsEarned < 1,
          })}
        >
          {!isOpen && <Button onClick={goToEndScreen}>End game</Button>}
          {isOpen && <Socials />}
          <WidgetCloseButton onClick={toggle} isOpen={isOpen} />

        </div>
      </div>
    </div>
  );
};

export default MobileWidget;
