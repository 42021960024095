import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RafPerf from 'raf-perf';
import { useFrame } from '@react-three/fiber';
import { v4 as uuidv4 } from 'uuid';

/**
 * Useful for throttling requestAnimationFrame to a specific fps
 * Uses raf-perf https://www.npmjs.com/package/raf-perf
 *
 * useRafPerf
 * @param callback
 * @param fps
 * @param perfCallback
 * @returns {[((function(): void)|*),((function(): void)|*),((function(): void)|*)]}
 *
 * Usage:
 *   const rafLoop = useCallback(() => {
 *     console.log('loop 1');
 *   }, []);
 *   const [start, stop] = useRafPerf(rafLoop, 30);
 *   useEffect(() => {
 *     start && start();
 *     return () => {
 *       stop();
 *     };
 *   }, [start, stop]);
 *
 */

window.performance = window.performance || {};
window.performance.now = (function() {
  return performance.now       ||
    window.performance.mozNow    ||
    window.performance.msNow     ||
    window.performance.oNow      ||
    window.performance.webkitNow ||
    function() {
      return new Date().getTime();
    };
})();

export const useRafPerf = function (callback, fps = 60, perfCallback) {
  const [engine, setEngine] = useState();
  const cbRef = useRef(callback);
  const perfRef = useRef(perfCallback);

  useEffect(() => {
    const e = new RafPerf({ fps: fps });
    setEngine(e);
    return () => {
      if (e.isRunning) e.stop();
      e.removeAllListeners('tick');
      e.removeAllListeners('perf');
    };
  }, []);

  // Add callback listener
  useEffect(() => {
    if (engine && callback) {
      engine.on('tick', callback);
      return () => {
        engine.removeAllListeners('tick');
      };
    }
  }, [callback, engine]);

  // Add perf callback
  useEffect(() => {
    if (engine && perfCallback) {
      engine.on('perf', perfCallback);
      return () => {
        engine.removeAllListeners('perf');
      };
    }
  }, [perfCallback, engine]);

  const start = useCallback(() => {
    engine && engine.start();
  }, [engine]);

  const stop = useCallback(() => {
    engine && engine.stop();
  }, [engine]);

  const destroy = useCallback(() => {
    if (engine) {
      if (engine.isRunning) engine.stop();
      engine.removeAllListeners('tick');
      engine.removeAllListeners('perf');
    }
  }, [engine]);

  return [start, stop, destroy];
};

export const useFramePerf = function (callback, { fps = 60, name = '', autoStart = false }) {
  const isRunning = useRef(false);

  const [uuid] = useState(() => {
    return uuidv4();
  });

  const internalName = useMemo(() => {
    return name || uuid.substring(0, 7);
  }, [name, uuid]);

  useEffect(() => {
    if (uuid){
      if (window.__useFramePerf) {
        window.__useFramePerf[uuid] = {
          label: internalName
        };
      }
      return () => {
        if (window.__useFramePerf) {
          delete window.__useFramePerf[uuid];
        }
      };
    }
  }, [uuid]);

  const start = useCallback(() => {
    isRunning.current = true;
  }, []);

  const stop = useCallback(() => {
    isRunning.current = false;
  }, []);

  useEffect(() => {
    if (autoStart){
      start();
    }
  }, [autoStart]);

  // Stop on unmount
  useEffect(() => {
    return () => {
      stop();
    };
  }, []);

  const now = useRef(0);
  const fpsInterval = useRef(1000 / fps);
  const then = useRef(0);
  const elapsed = useRef(0);
  const perfMeasure = useRef(0);
  const frameLoop = useCallback((_, dt) => {
    if (isRunning.current) {
      now.current = Date.now();
      elapsed.current = now.current - then.current;
      if (elapsed.current > fpsInterval.current) {
        // Get ready for next frame by setting then=now, but also adjust for your
        // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
        then.current = now.current - (elapsed.current % fpsInterval.current);
        // Update
        //console.log('loop--');
        if (window.__useFramePerf) {
          perfMeasure.current = window.performance.now();
        }
        callback && callback(_, dt);
        if (window.__useFramePerf && window.__useFramePerf[uuid]) {
          const execTime = window.performance.now() - perfMeasure.current;
          window.__useFramePerf[uuid].execTime = execTime;
        }
      }
    }
    if (window.__useFramePerf) {
      window.__useFramePerf[uuid].running = isRunning.current;
    }
  }, [callback]);
  useFrame(frameLoop);

  return [start, stop];
};
