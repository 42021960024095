import './ShareButton.scss';
import React from 'react';
import { createSharePopUp } from '@utils/share';

const ShareButton = (props) => {
  const { link, children, onClick } = props;

  const handleClick = (e) => {
    onClick();
    createSharePopUp(e);
  };

  return (
    <a className="ShareButton" onClick={handleClick}
      href={link} target="_blank"
      rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default ShareButton;
