/* eslint-disable */
import React, { forwardRef, useEffect, useRef } from 'react';
import {
  MOUSE,
  Vector2,
  Vector3,
  Vector4,
  Quaternion,
  Matrix4,
  Spherical,
  Box3,
  Sphere,
  Raycaster,
  MathUtils,
} from 'three';
import { extend, useFrame, useThree } from '@react-three/fiber';
import CameraControlsDefault from 'camera-controls';
import {mergeRefs} from "react-merge-refs";

export const CameraControlsClass = CameraControlsDefault;

const subsetOfTHREE = {
  MOUSE: MOUSE,
  Vector2: Vector2,
  Vector3: Vector3,
  Vector4: Vector4,
  Quaternion: Quaternion,
  Matrix4: Matrix4,
  Spherical: Spherical,
  Box3: Box3,
  Sphere: Sphere,
  Raycaster: Raycaster,
  MathUtils: {
    DEG2RAD: MathUtils.DEG2RAD,
    clamp: MathUtils.clamp,
  },
};

CameraControlsDefault.install({ THREE: subsetOfTHREE });
extend({ CameraControlsDefault });

export const CameraControls = forwardRef((props, ref) => {
  const cameraControls = useRef(null);
  const camera = useThree((state) => state.camera);
  const renderer = useThree((state) => state.gl);
  useFrame((_, delta) => cameraControls.current?.update(delta));
  useEffect(() => () => {
    console.log('DISPOSING CAMERA CONTROLS');
    cameraControls.current?.dispose();
  }, []);
  return (
    <cameraControlsDefault
      ref={mergeRefs([cameraControls, ref])}
      args={[camera, renderer.domElement]}
    />
  );
});
