import './Widget.scss';
import { useEffect, useState } from 'react';
import { motion, MotionConfig } from 'framer-motion';
import { useLocation } from 'wouter';
import classnames from 'classnames';
import Button from '@components/elements/Button/Button';
import PointsTracker from '@components/elements/PointsTracker/PointsTracker';
import { ROUTE_PATHS } from '@settings/settings.app';
import useMediaQuery from '@hooks/use-media-query';
import useAppStore from '@store/_app';
import { expoOut } from '@utils/eases';
import useUserSessionStore from '@store/_user-session';
import WidgetCloseButton from '../WidgetCloseButton/WidgetCloseButton';
import WidgetMap from '../WidgetMap/WidgetMap';
import PointsTrackerAnimation from '../PointsTrackerAnimation/PointsTrackerAnimation';

const Widget = () => {
  const isMobile = useMediaQuery('(max-width: 850px)');
  const [dynamicHeight, setDynamicHeight] = useState();
  const [dynamicMapHeight, setDynamicMapHeight] = useState();

  const [location, navigate] = useLocation();
  const pointsAddedActive = useAppStore((state) => state.pointsAddedActive);
  const setWidgetOpen = useAppStore((state) => state.setWidgetOpen);
  const widgetOpen = useAppStore((state) => state.widgetOpen);
  const pointsEarned = useUserSessionStore((state) => state.pointsEarned);
  const calcHeight = () => `min(calc(${window.innerHeight}px - 4rem), var(--widget-max-h))`;
  const calcMapHeight = () => `min(calc(${window.innerHeight}px - 19.75rem), calc(var(--widget-max-h) - 14.75rem))`;

  useEffect(() => {
    function handleResize() {
      setDynamicHeight(calcHeight());
      setDynamicMapHeight(calcMapHeight());
    }

    const unsubscribeActiveCheck = useAppStore.subscribe(
      (state) => state.modalActive,
      (value) => {
        setWidgetOpen(!value);
      }
    );

    setDynamicHeight(calcHeight());
    setDynamicMapHeight(calcMapHeight());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      unsubscribeActiveCheck();
    };
  }, []);

  const goToEndScreen = () => {
    navigate(ROUTE_PATHS.endScreen);
  };

  const onCloseButton = () => {
    setWidgetOpen(!widgetOpen);
  };
  return (
    <MotionConfig transition={{ duration: isMobile ? 0 : 1, ease: expoOut }}>
      <div
        className={classnames(
          'Widget',
          { 'Widget--points-earned': pointsAddedActive },
          { 'Widget--closed': !widgetOpen }
        )}
      >
        <div className="Widget__close">
          <WidgetCloseButton onClick={onCloseButton} isOpen={widgetOpen} />
        </div>
        <motion.div
          className="Widget__points"
          onClick={onCloseButton}
          layout
          style={{ height: widgetOpen ? '8.75rem' : '3rem' }}
        >
          <motion.div className="Widget__points__inner" layout="position">
            <PointsTrackerAnimation />
            <PointsTracker isOpen={widgetOpen} />
          </motion.div>
        </motion.div>
        <motion.div
          className="Widget__content"
          layout="position"
          style={{ height: widgetOpen ? dynamicHeight : '3rem' }}
        >
          <motion.div
            style={{ height: dynamicMapHeight }}
            layout="position"
            className="Widget__map"
            animate={{ opacity: widgetOpen ? 1 : 0 }}
          >
            <WidgetMap />
          </motion.div>
          <motion.div
            className={`Widget__footer ${pointsEarned < 1 ? 'Widget__footer--disabled' : ''}`}
            layout="position"
          >
            <motion.div className="Widget__footer__inner" animate={{ opacity: widgetOpen ? 1 : 0 }}>
              <Button onClick={goToEndScreen}>End game</Button>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </MotionConfig>
  );
};

export default Widget;
