import { useCallback, useEffect, useRef } from 'react';
import copyToClipboard from 'copy-to-clipboard';
import { DEBUG_MODE } from '@settings/settings.params.js';
import { commonMaterials } from '@components/canvas/common-materials.js';

const replaceMaterials = function(obj, material){
  obj.traverse((it) => {
    if (it.material) {
      it.material = material;
    }
  });
};

const countUsageByName = function(scene, name){
  let meshUse = 0;
  let instanceUse = 0;
  scene.traverse((it) => {
    if (it.name === name) {
      if (it.isInstancedMesh){
        instanceUse += it.count;
      } else if (it.isMesh) {
        meshUse++;
      }
    }
  });
  console.info('meshUse:', meshUse);
  console.info('instanceUse:', instanceUse);
};

const getTriangleCount = function(it){
  const result = {
    verticies: 0,
    triangles: 0
  };
  if ( it.isMesh || it.isInstancedMesh ) {
    const geometry = it.geometry;
    const verticesCount = geometry.attributes.position.count;
    let trianglesCount = 0;
    if ( geometry.index !== null ) {
      trianglesCount = geometry.index.count / 3;
    } else {
      trianglesCount = geometry.attributes.position.count / 3;
    }
    result.verticies = verticesCount;
    result.triangles = trianglesCount;
  }
  return result;
};

export const DebugGLTFScene = function({ children, debugPositionRef, ...props }){

  const selectedObj = useRef();
  const rootObj = useRef();

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'i') {
        if (selectedObj.current) {
          if (!selectedObj.current.userData.debugMaterialOn) {
            selectedObj.current.userData.originalMaterial = selectedObj.current.material;
            selectedObj.current.userData.debugMaterialOn = true;
            replaceMaterials(selectedObj.current, commonMaterials.wireframe);
          } else {
            selectedObj.current.material = selectedObj.current.userData.originalMaterial;
            selectedObj.current.userData.debugMaterialOn = false;
          }
        }
      }
    },
    [selectedObj]
  );
  useEffect(() => {
    addEventListener('keydown', handleKeyDown);
    return () => {
      removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onClickHandler = useCallback((ev) => {
    if (DEBUG_MODE) {
      ev.stopPropagation();
      if (ev.object) {
        console.info('----- OBJ INFO ----', ev.object.name);
        console.info('name:', ev.object.name);
        countUsageByName(rootObj.current, ev.object.name);
        const triangleCount = getTriangleCount(ev.object);
        console.info('triangles:', triangleCount.triangles);
        console.info('ev.object:', ev.object);
        console.info('/----- OBJ INFO ----', ev.object.name);
        selectedObj.current = ev.object;
        if (ev && ev.nativeEvent) {
          if (ev.nativeEvent.shiftKey) {
            const pos = ev.point;
            const posArray = pos.toArray();
            console.info('posArray:', posArray);
            // console.info('posArray.toString():', posArray.toString());
            copyToClipboard(posArray.toString());
            if (debugPositionRef) {
              debugPositionRef.current.position.copy(pos);
            }
            return;
          }
        }
      }
    }
  }, [rootObj]);

  return (
    <group ref={rootObj} onClick={(ev) => {
      onClickHandler(ev);
    }} {...props}>
      {children}
    </group>
  );
};
