import { useEffect, useState } from 'react';

export default function useTouchDetect(rootElRef, condition = true){
  rootElRef = rootElRef.current || rootElRef;
  const [touchDown, setTouchDown] = useState(false);
  useEffect(() => {
    if (!!(condition) && rootElRef && rootElRef.addEventListener){
      const touchStartHandler = function(){
        setTouchDown(true);
      };
      const touchEndHandler = function(){
        setTouchDown(false);
      };
      rootElRef.addEventListener('touchstart', touchStartHandler);
      rootElRef.addEventListener('touchend', touchEndHandler);
      rootElRef.addEventListener('touchcancel', touchEndHandler);
      return () => {
        rootElRef.removeEventListener('touchstart', touchStartHandler);
        rootElRef.removeEventListener('touchend', touchEndHandler);
        rootElRef.removeEventListener('touchcancel', touchEndHandler);
      };
    }
  }, [rootElRef, setTouchDown]);

  return touchDown;
}
