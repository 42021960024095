import { useEffect } from 'react';
import { Color, InstancedMesh } from 'three';
import { commonMaterials } from '@components/canvas/common-materials.js';

const hideModels = {
  always: [
    // 2022-12-09 loklok
    /// latest 3d model dont have snow
    // 'Roundcube003',
    // 'Cube197', // Snow
    // 'Plane007_2', // SNow
    // 'Cube194', // SNow
    // 'Plane040_1', // Snow
  ],
  tier2: [],
};

const wallElements = [
  // WIndows
  'Plane081',
  'Plane077',
  'Plane037',
  'Plane047',
  'Plane014_2',
  // Walls
  'Plane051',
  'bottomBorder004',
  'Plane022_1',
  'Cube034_1',
  'Cube170',
  'Cube172',
  'Cube193',
  'wallFlat',
  'Cube173',
  'CornerArea092'
];

export function isWallElement(obj) {
  if (wallElements.includes(obj.name)) {
    return true;
  }
  if (obj.name.indexOf('windows') !== -1) {
    return true;
  }
  if (obj.name.indexOf('wall') !== -1) {
    return true;
  }
  if (obj.name.toLowerCase().indexOf('corner') !== -1) {
    return true;
  }
}

export const useParseModelScene = function (scene) {
  useEffect(() => {
    if (scene) {
      scene.userData.walls = [];
      scene.traverse((it) => {
        if (hideModels.always.includes(it.name)) {
          it.visible = false;
          it.userData.keepHidden = true;
        }
        if (it.material && it.name === 'T_Mobile_Logo_Letter') {
          it.material = it.material.clone();
          it.material.map = null;
          it.material.color = new Color('#D21373'); //.convertLinearToSRGB();
        }
        if ((it.isMesh || it.isInstancedMesh) && isWallElement(it)) {
          //it.material = commonMaterials.wireframe;
          scene.userData.walls.push(it);
        }
        // if (!(it instanceof InstancedMesh)){
        //   it.visible = false;
        //   it.userData.keepHidden = true;
        // }
        if (it.name.indexOf(`pink_Objects`) === 0 && it?.material?.name?.indexOf(`pink_material`) === 0) {
          //
          //console.log(it.name, it.material.name);
          it.material = it.material.clone();
          // it.material.emissive = new Color('#ffffff');
          it.material.color = new Color('#E20074');
          it.material.map = null;
        }
      });
    }
  }, [scene]);
};
