import './PointsTracker.scss';
import React, { useEffect, useState, forwardRef } from 'react';
import { motion, MotionConfig, AnimatePresence } from 'framer-motion';
import { zeroPad } from 'react-countdown';
import useEffectWithPrevious from 'use-effect-with-previous';
import { useLocation } from 'wouter';
import useUserSessionStore from '@store/_user-session';
import useAppStore from '@store/_app';
import useMediaQuery from '@hooks/use-media-query';
import { TOTAL_POINTS_BEFORE_BONUS } from '@settings/settings.consts.js';
import { ROUTE_PATHS } from '@settings/settings.app.js';

const PointsTracker = forwardRef(({ isOpen }, ref) => {
  const [location, navigate] = useLocation();
  const [earnedAmount, setEarnedAmount] = useState(0);
  const [closeEarnedAmountTimeout, setCloseEarnedAmountTimeout] = useState(false);
  const totalPoints = useUserSessionStore((state) => state.totalPoints);
  const pointsEarned = useUserSessionStore((state) => state.pointsEarned);
  const isMobile = useMediaQuery('(max-width: 850px)');
  const setPointsAddedActive = useAppStore((state) => state.setPointsAddedActive);
  const pointsAddedActive = useAppStore((state) => state.pointsAddedActive);
  const onEndingVisited = useUserSessionStore((state) => state.onEndingVisited);
  const setOnEndingVisited = useUserSessionStore((state) => state.setOnEndingVisited);
  const resetActiveHolograms = useUserSessionStore((state) => state.resetActiveHolograms);

  const goToEndScreen = () => {
    navigate(ROUTE_PATHS.endScreen);
  };
  useEffectWithPrevious(
    ([prevPointsEarned]) => {
      if (pointsEarned === totalPoints && prevPointsEarned < pointsEarned && !onEndingVisited) {
        setTimeout(() => {
          goToEndScreen();
          setOnEndingVisited(true);
        }, 100);
      }
      // If the user has reached all points, there should not be any active holograms
      if (pointsEarned >= TOTAL_POINTS_BEFORE_BONUS){
        resetActiveHolograms();
      }
    },
    [pointsEarned, totalPoints, onEndingVisited, resetActiveHolograms]
  );

  useEffect(() => {
    const unsubscribePointsAdded = useUserSessionStore.subscribe(
      (state) => state.pointsAdded,
      (pointsAdded) => {
        clearTimeout(closeEarnedAmountTimeout);
        setEarnedAmount(0);
        if (pointsAdded.amount > 0) {
          setPointsAddedActive(true);
          setEarnedAmount(pointsAdded.amount);
          setCloseEarnedAmountTimeout(
            setTimeout(() => {
              setPointsAddedActive(false);
            }, 3000)
          );
        }
      }
    );

    return () => {
      unsubscribePointsAdded();
    };
  }, []);

  return (
    <MotionConfig transition={{ duration: isMobile ? 0 : 1, ease: [0.19, 1.0, 0.22, 1.0] }}>
      <motion.div className="PointsTracker">
        <motion.div
          className="PointsTracker__sm"
          layout
          style={{ top: isOpen ? '.5rem' : '0' }}
          animate={{ opacity: isOpen ? 0 : 1 }}
          transition={{ duration: isMobile ? 0 : isOpen ? 0.25 : 1, ease: [0.19, 1.0, 0.22, 1.0] }}
        >
          <div>
            <span>{zeroPad(pointsEarned)}</span>/{totalPoints} <span>Points earned</span>
          </div>
        </motion.div>
        <motion.div
          className="PointsTracker__lg"
          layout
          style={{ top: isOpen ? '2rem' : '0' }}
          animate={{ opacity: isOpen ? 1 : 0 }}
          transition={{ duration: isMobile ? 0 : isOpen ? 1 : 0.25, ease: [0.19, 1.0, 0.22, 1.0] }}
        >
          <div className="PointsTracker__lg__points">
            <span>{zeroPad(pointsEarned)}</span> <span>/</span> <span>{totalPoints}</span>
          </div>
          <div className="PointsTracker__lg__label">Points earned</div>
        </motion.div>
      </motion.div>
      <AnimatePresence>
        {pointsAddedActive && earnedAmount > 0 && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="PointsEarned">
            +{earnedAmount}
          </motion.div>
        )}
      </AnimatePresence>
    </MotionConfig>
  );
});

export default PointsTracker;
