import './VrButton.scss';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { VRButton } from '@react-three/xr';
import { useThree } from '@react-three/fiber';
import useAppStore from '@store/_app';
import { useVRCore } from '@components/canvas/VR/VR';

const VrButton = (props) => {
  // const session = useVRCore((s) => s.session);
  // const setSession = useVRCore((s) => s.setSession);

  const vrBtn = useRef();

  useEffect(() => {
    if (vrBtn.current) {
      vrBtn.current.style = `
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      `;
    }
  }, [vrBtn]);
  return (
    <>
      <div className="VrButton">
        <VRButton ref={vrBtn}>
          {() => {
            return (
              <svg width="30" height="16"
                viewBox="0 0 30 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M25 1H5C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11.7778L12.3606 13.3311C12.7435 12.2345 13.778 11.5 14.9395 11.5C16.1591 11.5 17.231 12.3085 17.5661 13.4812L18 15H25C27.2091 15 29 13.2091 29 11V5C29 2.79086 27.2091 1 25 1Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
              </svg>
            );
          }}
        </VRButton>
      </div>
    </>
  );
};

export default VrButton;
