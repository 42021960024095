import { Center, Plane } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Interactive, useXR } from '@react-three/xr';
import React, { useEffect, useRef, useState } from 'react';
import { Vector2 } from 'three';
import { dispatch } from 'use-bus';
import { commonMaterials } from '@components/canvas/common-materials.js';
import { COLOR_MAGENTA } from '@settings/settings.consts.js';
import { VRGenericButton } from '@components/canvas/VRCloseButton/VRGenericButton.jsx';
import { GOTO_END_WITH_DELAY } from '@settings/settings.events.js';
import { heightAtDepth, RenderText, widthAtDepth } from '../RenderText/RenderText';
import { VRStickyUI } from '../VRStickyUI/VRStickyUI';

export function VREndScreen({ progress = 0.2, inVROnly = false, questionData, handleOnAnswer }) {
  const session = useXR((s) => s.session);
  const camera = useThree((s) => s.camera);
  const w = widthAtDepth(1, camera);
  const h = heightAtDepth(1, camera);

  const full = 1.6;
  const length = full * progress;

  const rootObj = useRef();

  const mainCopy = `CONGRATULATIONS!
You have completed all the trivia questions!
Select END GAME to exit VR mode and
enter your info for a chance to win.`;

  const onSelectHandler = function(){
    session && session.end();
    dispatch(GOTO_END_WITH_DELAY);
  };

  const content = (

    <group
      ref={rootObj}
      scale={[0.5, 0.5, 1]}
      onClick={() => {
        //
      }}
      position={[0, 0, -0.6]}
    >
      <group position={[0, 0, -0.5]} scale={1.6}>
        <Plane args={[1.6, 1]} material={commonMaterials.white}>
        </Plane>
      </group>
      <group position={[0, 0.22, 0]}>
        <group position={[0, -0.14, 0.1]}>
          <RenderText lineSpacing={4} hScale={1} fontSize={35} text={mainCopy} constrainWidth={650}></RenderText>
        </group>
        <group position={[0, -0.4, 0.12]}>
          <VRGenericButton
            bgColor={COLOR_MAGENTA}
            color={'#ffffff'}
            fontSize={25}
            position={[0, 0, 0]}
            text={'END GAME'}
            onSelectHandler={onSelectHandler}
            size={(new Vector2(0.2, 0.07)).multiplyScalar(1.5)} />
        </group>
      </group>
    </group>
  );

  //
  return <group><VRStickyUI lerp={0.9}>{content}</VRStickyUI></group>;
}
