import './HologramLabel.scss';
import React from 'react';
import { Html } from '@react-three/drei';

const HologramLabel = (props) => {
  const { name, jobTitle } = props;

  return (
    <Html position={[0, 1, 0]}
      wrapperClass="HologramLabel">
      <div className="HologramLabel__inner">
        <div className="HologramLabel__name">{name}</div>
        <div className="HologramLabel__jobtitle">{jobTitle}</div>
      </div>
    </Html>
  );
};

export default HologramLabel;
