import { useEffect } from 'react';
import { useDetectGPU } from '@react-three/drei';
import { monitor, useControls } from 'leva';
import { useCanvasStore } from '@store';
import { TEX_STUDIO_HDRI_URL } from '@settings/settings.urls.js';
import useEquirectTexture from '@hooks/use-equirect-texture.js';
import { commonMaterials } from '@components/canvas/common-materials.js';

const useCanvasHooks = () => {
  const GPUTier = useDetectGPU();
  const setGPUTier = useCanvasStore(state => state.setGPUTier);

  useControls('GPUTier', {
    gpu: monitor(() => {
      if (GPUTier) {
        return GPUTier.gpu;
      }
    }),
    tier: monitor(() => {
      if (GPUTier) {
        return GPUTier.tier;
      }
    })
  });

  useEffect(() => {
    console.info('GPUTier:', GPUTier);
    setGPUTier(GPUTier);
  }, [GPUTier]);
};

export default useCanvasHooks;
