import { useHelper, useTexture, PerspectiveCamera } from '@react-three/drei';
import { useCallback, useEffect, useMemo, useRef } from 'react';
// import sortBy from 'lodash.sortby';
import {
  Sphere,
  sRGBEncoding,
  Vector3,
  Matrix4,
  Frustum,
  CameraHelper,
  Box3,
  BoxGeometry,
  Color,
  LinearEncoding,
  Raycaster
} from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import useSWR from 'swr';
import { useLoader, useThree } from '@react-three/fiber';
// import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { useRafPerf, useFramePerf } from '@hooks/use-raf-perf';
import { useCanvasStore } from '@store/index.js';
import { DEBUG_MODE } from '@settings/settings.params.js';
import { useParseModelScene } from '@components/canvas/hooks/use-parse-model-scene.js';
import useAppStore from '@store/_app.js';
import usePerformanceSettings from '@hooks/use-performance-settings.js';
import { Sleep } from '@utils/sleep.js';
import { getCameraPosition, getCameraQuaternion } from '@utils/three-utils';
import useCameraOrXRPlayer from '@hooks/use-camera-or-x-r-player.js';
import { Zones } from './Zones';
import { GLTFLoader } from './GLTFLoaderClean';
import { SnowScan } from '../Snow/SnowScan';
// import { SnowRTT } from '../Snow/SnowRTT';

const modelUrl = `/3d/wholeset/2022-11-29-t-8-31pm/instanced.glb`;
// const lightMapURL = '/3d/prototypes/bridge/2022-11-22-t-7-45pm/lm.jpg';
// const lightMapURLLounge = `/3d/wholeset/2022-12-06-t-08-00pm/LoungeLightMap1k.png`;
// const lightMapURLCoffeeRoom = `/3d/wholeset/2022-12-06-t-08-00pm/CoffeeRoomLightMap1k.png`;

// const CoffeeRoomLightMap1k = `/3d/wholeset/2022-12-07-t-05-30pm/CoffeeRoomLightMap1k.png`;
// const elavatorRoomLightMap1K = `/3d/wholeset/2022-12-07-t-05-30pm/elavatorRoomLightMap1K.png`;
// const LoungeLightMap1k = `/3d/wholeset/2022-12-07-t-05-30pm/LoungeLightMap1k.png`;
// const propsLightMap1k = `/3d/wholeset/2022-12-07-t-05-30pm/mainHallLightMap1K.png`;
// const mainHallLightMap1K = `/3d/wholeset/2022-12-07-t-05-30pm/propsLightMap1k.png`;
// const secondFloorLightMap1k = `/3d/wholeset/2022-12-07-t-05-30pm/secondFloorLightMap1k.png`;

export const posMap = Zones;

const secondFloorVGeo = new BoxGeometry(73.2, 9.79, 73.1);
secondFloorVGeo.translate(-98.522, 30.246, 27.9729);
/** @type {Box3} */
secondFloorVGeo.computeBoundingBox();
const secondFloorWarpper = secondFloorVGeo.boundingBox;

// const fetcher = async ({ url, camera, camSphere, zone3 }) => {
//   camSphere.center.copy(camera.position);

//   if (!camSphere.intersectsBox(zone3)) {
//     await new Promise((r) => {
//       setTimeout(r, 1000);
//     });

//     console.log('trying again');
//     return await Promise.reject({});
//   }

//   const loader = new GLTFLoader();
//   const dracoLoader = new DRACOLoader();
//   dracoLoader.setDecoderPath('/draco/');
//   loader.setDRACOLoader(dracoLoader);

//   const glb = await loader.loadAsync(url);

//   return glb;
// };
//!SECTION

//
class GLTFLoader2 extends GLTFLoader {
  constructor() {
    super();

    // public
    // const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');
    this.setDRACOLoader(dracoLoader);

    this._load = this.load;
    // this.load = (url, cb) => {
    //   const camSphere = new Sphere(new Vector3(99999, 99999, 99999), CameraRef.radius);
    //   const zone3 = new Box3();

    //   if (posMap[url]) {
    //     zone3.min.x = posMap[url].min.x;
    //     zone3.min.y = posMap[url].min.y;
    //     zone3.min.z = posMap[url].min.z;

    //     zone3.max.x = posMap[url].max.x;
    //     zone3.max.y = posMap[url].max.y;
    //     zone3.max.z = posMap[url].max.z;
    //     zone3.needsUpdate = true;
    //   }

    //   const ttt = setInterval(() => {
    //     if (CameraRef.camera) {
    //       camSphere.center.copy(CameraRef.camera.position);
    //       // console.log(url, 'can load now', camSphere.intersectsBox(zone3));
    //       if (camSphere.intersectsBox(zone3)) {
    //         clearInterval(ttt);
    //         this._load(url, cb);
    //       }
    //     }
    //   }, 100);
    // };
  }
}

function getVectorDifference(v1, v2, out) {
  out = out || new Vector3();
  out.set(Math.abs(v1.x - v2.x), Math.abs(v1.y - v2.y), Math.abs(v1.z - v2.z));
  return out;
}

const DETECT_SPHERE_RADIUS = 2;

export function BasicLoader({ markers = [], url = modelUrl, ...props }) {
  const cameraOrXRPlayer = useCameraOrXRPlayer();
  // const size = useThree((s) => s.size);
  const camera = useThree((s) => s.camera);
  // const camSphere = new Sphere(new Vector3(10000, 10000, 10000), CameraRef.radius);
  // const zone3 = new Box3(new Vector3(-0.1, -0.1, -0.1), new Vector3(0.1, 0.1, 0.1));

  // if (posMap[url]) {
  //   zone3.min.x = posMap[url].min.x;
  //   zone3.min.y = posMap[url].min.y;
  //   zone3.min.z = posMap[url].min.z;

  //   zone3.max.x = posMap[url].max.x;
  //   zone3.max.y = posMap[url].max.y;
  //   zone3.max.z = posMap[url].max.z;
  // }

  const CameraRef = useMemo(() => {
    return { radius: 10, camera: false };
  }, []);
  CameraRef.camera = camera;

  GLTFLoader2.markers = markers;
  const glb = useLoader(GLTFLoader2, url);

  const perfSettings = usePerformanceSettings();

  // Parse the scene and make some adjustments
  useParseModelScene(glb.scene);

  /*
  loungeEnvironment_LightMap
  lightMap_coffeeRoom
  mainHall_LightMap
  elavatorBake
  secondFloor_lightmap
  propsLightMap
  */
  // public/3d/wholeset/2022-12-09-t-0118pm/1kwebp/CoffeeRoomLightMap4k.webp
  // public/3d/wholeset/2022-12-09-t-0118pm/1kwebp/elavator_lightmap4K.webp
  // public/3d/wholeset/2022-12-09-t-0118pm/1kwebp/LoungeLightMap4k.webp
  // public/3d/wholeset/2022-12-09-t-0118pm/1kwebp/mainHallLightMap4K.webp
  // public/3d/wholeset/2022-12-09-t-0118pm/1kwebp/propsLightMap4k.webp
  // public/3d/wholeset/2022-12-09-t-0118pm/1kwebp/secondFloor_lightmap4k.webp

  // Light Maps
  const lightMapCoffeeRoomLightMap1k = useTexture(`/3d/wholeset/2022-12-09-t-0118pm/1kwebp/CoffeeRoomLightMap.webp`);
  lightMapCoffeeRoomLightMap1k.name = 'lightMap_coffeeRoom';
  const lightMapElavatorRoomLightMap1K = useTexture(`/3d/wholeset/2022-12-09-t-0118pm/1kwebp/elavator_lightmap.webp`);
  lightMapElavatorRoomLightMap1K.name = 'elavatorBake';
  const lightMapLoungeLightMap1k = useTexture(`/3d/wholeset/2022-12-09-t-0118pm/1kwebp/LoungeLightMap.webp`);
  lightMapLoungeLightMap1k.name = 'loungeEnvironment_LightMap';
  const lightMapPropsLightMap1k = useTexture(`/3d/wholeset/2022-12-09-t-0118pm/1kwebp/propsLightMap.webp`);
  lightMapPropsLightMap1k.name = 'propsLightMap';
  //
  const lightMapMainHallLightMap1K = useTexture(`/3d/wholeset/2022-12-09-t-0118pm/1kwebp/mainHallLightMap.webp`);
  lightMapMainHallLightMap1K.name = 'mainHall_LightMap';
  const lightMapSecondFloorLightMap1k = useTexture(`/3d/wholeset/2022-12-09-t-0118pm/1kwebp/secondFloor_lightmap.webp`);
  lightMapSecondFloorLightMap1k.name = 'secondFloor_lightmap';

  const lightMap1KMap = useTexture(`/3d/wholeset/2022-12-09-t-0118pm/1kwebp/bridge.webp`);
  lightMap1KMap.name = `lightMap`;
  const quintimMap = useTexture(`/3d/wholeset/2022-12-13-t-3-31pm/CommonAssets_Lightmap_1k.webp`);
  quintimMap.name = `UV_lightmap`;

  // const lightMapLounge = useTexture(lightMapURLLounge);
  // const lightMapCoffee = useTexture(lightMapURLCoffeeRoom);

  // Detect if camera is animating, to prevent jank when animating
  const cameraIsAnimatingRef = useRef(false);
  const hologramIsActiveRef = useRef(false);
  useEffect(() => {
    const unsubs = [
      useCanvasStore.subscribe(
        (state) => state.cameraIsAnimating,
        (value) => {
          cameraIsAnimatingRef.current = value;
        }
      ),
      useAppStore.subscribe(
        (s) => s.hologramActive,
        (val) => {
          hologramIsActiveRef.current = val && val.id && val.centerPos;
        }
      )
    ];
    return () => {
      unsubs.map((u) => {
        u && u();
      });
    };
  }, []);

  const objectTriCounts = useRef({});

  useEffect(() => {
    if (!glb) {
      return;
    }

    if (!lightMapCoffeeRoomLightMap1k) {
      return;
    }
    if (!lightMapElavatorRoomLightMap1K) {
      return;
    }
    if (!lightMapLoungeLightMap1k) {
      return;
    }
    if (!lightMapPropsLightMap1k) {
      return;
    }
    if (!lightMapMainHallLightMap1K) {
      return;
    }
    if (!lightMapSecondFloorLightMap1k) {
      return;
    }
    if (!quintimMap) {
      return;
    }

    const array = [
      quintimMap,
      lightMapCoffeeRoomLightMap1k,
      lightMapElavatorRoomLightMap1K,
      lightMapLoungeLightMap1k,
      lightMapPropsLightMap1k,
      lightMapMainHallLightMap1K,
      lightMapSecondFloorLightMap1k,
      lightMap1KMap
    ];

    array
      .filter((e) => e)
      .map((lm) => {
        //
        // console.log(lm);

        lm.encoding = sRGBEncoding;
        lm.flipY = false;
        lm.needsUpdate = true;
      });

    // lightMapCoffee.encoding = sRGBEncoding;
    // lightMapCoffee.flipY = false;
    // lightMapCoffee.needsUpdate = true;

    glb.insts = [];
    glb.secondFloorVisible = [];
    glb.vertCount = [];
    // console.log(glb);
    glb.scene.traverse((it) => {
      if (it.material && it.material.name.indexOf('shadow') === 0) {
        //
        it.material.color = new Color('#444444');
      }

      if (it.userData.keepHidden) return;
      if (it.material && it.geometry) {
        it.geometry.computeBoundingSphere();

        // const before = it.geometry.boundingSphere.center.clone();
        // it.geometry.center();
        // const after = it.geometry.boundingSphere.center.clone();

        // it.geometry.translate(
        //   //
        //   -(after.x - before.x),
        //   -(after.y - before.y),
        //   -(after.z - before.z)
        // );

        // console.log(it.geometry?.attributes?.uv2?.name);

        //  || it.material.name.includes('trimEnvironment')
        // if (it.material.name.includes('trimEnvironment')) {
        //   it.material.lightMap = lightMapLounge;
        //   it.material.lightMapIntensity = 2;
        //   //it.material.side = DoubleSide;
        //   it.material.envMapIntensity = 0.5;
        // }

        // if (it?.userData?.MY_LIGHTMAP === 'lightMap') {
        //   it.material.lightMap = lightMapLounge;
        //   it.material.lightMapIntensity = 2;
        // }

        const lmfound = array.find((e) => e?.name === it?.userData?.MY_LIGHTMAP);
        //console.log(it?.userData?.MY_LIGHTMAP);
        if (lmfound) {
          it.material = it.material.clone();
          it.material.lightMap = lmfound;
          it.material.lightMapIntensity = 1;
          it.material.envMapIntensity = 0.3;
        }

        // if (it?.userData?.MY_LIGHTMAP === 'loungeEnvironment_LightMap') {
        // }

        // console.log(it.userData);

        // if (['textureSet001'].some((r) => )) {
        //   it.material.lightMap = lightMapCoffee;
        //   it.material.lightMapIntensity = 2;
        //   //it.material.side = DoubleSide;
        //   it.material.envMapIntensity = 0.5;
        // } else {
        //   it.material.envMapIntensity = 1;
        // }
        // it.material.emissiveIntensity = 0.1;
        // it.mat erial.emissive = new Color(255, 255, 255);

        it.worldPosition = new Vector3();
        it.getWorldPosition(it.worldPosition);
      }
      if (it.isInstancedMesh) {
        glb.insts.push(it);
      }

      // if (it.name === 'CornerArea109') {
      //   console.log('CornerArea109');

      //   it.material = it.material.clone();
      //   it.material.color = new Color('#ffffff');
      //   // it.material.emissive = new Color('#DCDAD9');
      //   // it.material.lightMap = null;

      //   it.material.roughness = 0.0;
      //   it.material.metalness = 1.0;
      //   it.material.roughnessMap = null;
      //   it.material.metalnessMap = null;
      //   // it.material.color = new Color('#ff0000');
      // }

      // if (it.name === 'Plane048') {
      //   it.scale.set(1, 1, 1);
      //   console.log(it.name);
      // }
      // if (it.name.includes('Plane') && (it.scale.x === -1 || it.scale.y === -1 || it.scale.z === -1)) {
      //   it.scale.set(1, 1, 1);
      // }
      //Verticies counts
      // if ( it.isMesh || it.isInstancedMesh ) {
      //   const geometry = it.geometry;
      //   const verticesCount = geometry.attributes.position.count;
      //   let trianglesCount = 0;
      //   if ( geometry.index !== null ) {
      //     trianglesCount = geometry.index.count / 3;
      //   } else {
      //     trianglesCount = geometry.attributes.position.count / 3;
      //   }
      //   glb.vertCount.push({
      //     name: it.name,
      //     verticies: verticesCount,
      //     triangles: trianglesCount
      //   });
      // }

      //
      // if (it.name === 'Cube180') {
      //   // it.material.lightMap = null;
      //   it.material.envMapIntensity = 1;
      //   it.material.metalness = 1;
      //   it.material.roughness = 0.4;
      //   it.material.metalnessMap = null;
      //   it.material.roughnessMap = null;
      // }
    });
    // Print out verticies count
    // glb.vertCount = sortBy(glb.vertCount, ['verticies']).reverse();
    // console.info('glb.vertCount:', glb.vertCount);
  }, [glb]);

  const [v3inst, m4, fr, sphereDetector, wp] = useMemo(() => {
    return [
      new Vector3(),
      new Matrix4(),
      new Frustum(),
      new Sphere(new Vector3(0, 0, 0), DETECT_SPHERE_RADIUS),
      new Vector3()
    ];
  }, []);

  const frustumCam = useRef();
  // useHelper(DEBUG_MODE && frustumCam, CameraHelper);

  const seeAny = (it, camera, glb) => {
    let saw = false;

    const imesh = it;

    if (!it.geometry.boundingSphere) {
      it.geometry.computeBoundingSphere();
    }

    for (let i = 0; i < it.count; i++) {
      const xyz = imesh.iPos[i];

      v3inst.fromArray(xyz);
      v3inst.add(it.worldPosition);

      sphereDetector.radius = imesh.geometry.boundingSphere.radius * 3.25;
      // sphereDetector.radius = 0.5;
      sphereDetector.center.copy(v3inst);

      // if (camera.position.y >= 6) {
      //   if (secondFloorWarpper.intersectsSphere(sphereDetector)) {
      //     if (!saw) {
      //       saw = true;
      //       return saw;
      //     }
      //   }

      //   // if (v3inst.y > 6.0) {
      //   //   if (v3inst.y + sphereDetector.radius <= 6) {
      //   //     saw = false;
      //   //     return saw;
      //   //   }
      //   // }
      // }

      if (v3inst.distanceTo(camera.position) <= DETECT_SPHERE_RADIUS) {
        if (!saw) {
          saw = true;
          return saw;
        }
      }
      if (fr.intersectsSphere(sphereDetector)) {
        if (!saw) {
          saw = true;
          return saw;
        }
      }
    }

    return saw;
    //
  };

  function isWindows() {
    return navigator.platform.indexOf('Win') > -1;
  }

  //
  let lastSignature = '';
  const counter = useRef(0);
  const loopPerf = useCallback(
    function BasicLoaderCheckIntersect(st) {
      // Skip if camera is in transition

      counter.current++;

      if (!isWindows()) {
        if (cameraIsAnimatingRef.current || hologramIsActiveRef.current) {
          // console.log('skipping BasicLoaderCheckIntersect');
          // console.info('cameraIsAnimatingRef.current:', cameraIsAnimatingRef.current);
          // console.info('hologramIsActiveRef.current:', hologramIsActiveRef.current);
          return;
        }
      }

      const camPosition = cameraOrXRPlayer.position;
      //const camRotation = getCameraRotation(camera);
      const camQuaternion = cameraOrXRPlayer.quaternion;

      // Check if the camera has moved
      const nowSig =
        camPosition
          .toArray()
          .map((e) => e.toFixed(1))
          .join('') +
        camQuaternion
          .toArray()
          .map((e) => e.toFixed(1))
          .join('');

      if (lastSignature === nowSig) {
        //console.log('skip');
        return;
      }
      lastSignature = nowSig;

      CameraRef.camera = camera;
      let logHidden = 0;
      let logHidden2 = 0;
      let countVisible = 0;
      // scene.updateMatrixWorld(true);

      const fc = frustumCam.current;
      fc.fov = camera.fov + perfSettings.frustumCullingFovAdd;
      fc.near = camera.near;
      fc.far = camera.far;
      fc.aspect = camera.aspect;
      fc.scale.copy(camera.scale);
      fc.position.copy(camPosition);
      fc.quaternion.copy(camQuaternion);
      fc.updateProjectionMatrix();
      fc.updateMatrixWorld();
      //
      m4.identity();
      m4.multiplyMatrices(fc.projectionMatrix, fc.matrixWorldInverse);
      fr.setFromProjectionMatrix(m4);

      // const scanFloor = (it, camera) => {
      //   const result = it.visible;
      //   if (!it.geometry.boundingBox) {
      //     it.geometry.computeBoundingBox();
      //     it.updateMatrixWorld();
      //   }

      //   it.getWorldPosition(wp);
      //   console.log(camera.position.y);
      //   const floorLayer = 6;
      //   if (camera.position.y < floorLayer) {
      //     if (wp.position.y > wp)
      //   } else if (camera.position.y > floorLayer) {
      //   }

      //   return result;

      //   // if (camera.position.y >= 6) {
      //   //   const result = b3.containsPoint(camera.position);
      //   //   return result;
      //   // } else if (camera.position.y <= 6) {
      //   // }
      // };

      //!SECTION
      //!SECTION

      glb.insts.forEach(function loopInstMesh(it) {
        if (it) {
          // console.log(it.scan(fr).length);
          // if (it.name.indexOf('Pool') > -1) {
          //   it.visible = true;
          //   return;
          // }
          if (it.scan) {
            it.visible = it.scan(fr, cameraOrXRPlayer, camera).length > 0; // && seeAny(it, camera, glb);
          }
          // it.visible = seeAny(it, camera, glb);
          // ;

          // it.visible = true;
          if (!it.visible) {
            logHidden += it.count;
            logHidden2++;
          } else {
            countVisible++;
          }
        }
      });

      // console.log(logHidden, 'instanced');
      if (DEBUG_MODE) {
        console.log(logHidden2, 'draw call reduction');
        console.log('visible', countVisible);
      }
    },
    [cameraOrXRPlayer, camera]
  );

  //
  const [start, stop] = useFramePerf(loopPerf, { fps: 5, name: 'frustum-check' });
  useEffect(() => {
    start && start();
    return () => {
      stop();
    };
  }, [start, stop]);

  // useHelper(DEBUG_MODE && frustumCam, CameraHelper);

  const gl = useThree((s) => s.gl);

  if (!glb) {
    return null;
  }

  if (!gl) {
    return null;
  }

  return (
    <group {...props}>
      <group>
        {/* <ambientLight intensity={0.3}></ambientLight> */}
        {glb.scene && <SnowScan scene={glb.scene}></SnowScan>}
        {<primitive object={glb.scene}></primitive>}
        <PerspectiveCamera ref={frustumCam}></PerspectiveCamera>
      </group>
    </group>
  );
}
