import React, { useEffect, useState } from 'react';
import { useXR } from '@react-three/xr';
import { VREndScreen } from '@components/canvas/VR/VREndScreen';
import useUserSessionStore from '@store/_user-session.js';
import { TOTAL_POINTS_BEFORE_BONUS } from '@settings/settings.consts.js';

export default function VREndScreenController({ ...props }){
  const session = useXR((s) => s.session);

  const [active, setActive] = useState(false);

  const pointsEarned = useUserSessionStore((state) => state.pointsEarned);

  useEffect(() => {
    if (!active && pointsEarned >= TOTAL_POINTS_BEFORE_BONUS){
      setActive(true);
    }
  }, [pointsEarned]);

  return (
    <group {...props}>
      {active && <VREndScreen />}
    </group>
  );
}
