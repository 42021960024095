import '@styles/index.scss';
import React from 'react';
import { Router, Route } from 'wouter';
import { Leva } from 'leva';
import NonFunctionals from '@components/NonFunctionals/NonFunctionals';
import { ROUTE_PATHS } from '@settings/settings.app';
import useWindowResizeListener from '@hooks/use-window-resize-listener';
import useNonFunctionalCheck from '@hooks/use-non-functional-check';
import useGsapPlugins from '@hooks/use-gsap-plugins';
import UiHeader from '@components/modules/UiHeader/UiHeader';
import Landing from '@pages/Landing/Landing';
import Metaverse from '@pages/Metaverse/Metaverse';
import EndScreen from '@pages/EndScreen/EndScreen';
import Canvas from '@components/canvas/Canvas/Canvas';
import { DEBUG_MODE, LOOPS, QA_MODE, THEATRE } from '@settings/settings.params.js';
import Loader from '@components/elements/Loader/Loader';
import Sounds from '@components/elements/Sounds/Sounds';
import useSetupGA from '@hooks/use-setup-g-a.js';
import { UseFramePerfDebug } from '@components/canvas/UseFramePerfDebug/UseFramePerfDebug.jsx';
import ClosedCaption from '@components/elements/ClosedCaption/ClosedCaption.jsx';

const App = () => {
  useWindowResizeListener();
  useNonFunctionalCheck();
  useGsapPlugins();
  useSetupGA();

  // const SHOW_METAVERSE = process.env.ENV === 'dev' || QA_MODE;

  return (
    <div className="App">
      <Leva hidden={!DEBUG_MODE} collapsed />
      {LOOPS && <UseFramePerfDebug />}
      <UiHeader />
      <Canvas />
      <Loader />
      <Sounds />
      <Router>
        <Route path={ROUTE_PATHS.landing} component={Landing} />
        {/* <Route path={ROUTE_PATHS.onBoarding} component={OnBoarding} /> */}
        <Route path={ROUTE_PATHS.metaverse} component={Metaverse} />
        <Route path={ROUTE_PATHS.endScreen} component={EndScreen} />
      </Router>
      <NonFunctionals />
    </div>
  );
};

export default App;
