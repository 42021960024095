import './Landing.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'wouter';
import gsap from 'gsap';
import { dispatch } from 'use-bus';
import { Button } from '@components/elements/Button/Button';
import Socials from '@components/elements/Socials/Socials';
import { DesignToggle } from '@components/helpers/DesignToggle/DesignToggle';
import FooterNav from '@components/elements/FooterNav/FooterNav';
import FooterSoundCta from '@components/elements/FooterSoundCta/FooterSoundCta';
import VideoSequence from '@components/elements/VideoSequence/VideoSequence';
import { ROUTE_PATHS } from '@settings/settings.app';
import useAppStore from '@store/_app';
import { Sleep } from '@utils/sleep';
import TextGlow from '@components/elements/TextGlow/TextGlow';
import Countdown from '@components/elements/Countdown/Countdown';
import Snowfall from '@components/elements/Snowfall/Snowfall';
import { EVENT_INTRO_TRANSITION } from '@settings/settings.events';
import { DEBUG_MODE, QA_MODE } from '@settings/settings.params';
import useMediaQuery from '@hooks/use-media-query';

const Landing = () => {
  // eslint-disable-next-line no-unused-vars
  const [location, navigate] = useLocation();
  const mainRef = useRef(null);
  const video = useRef(null);
  const videoBackground = useRef(null);
  const isMobile = useMediaQuery('(max-width: 850px)');

  const [animated, setAnimated] = useState(false);
  const [snowRunning, setSnowRunning] = useState(false);
  const [countDownComplete, setCountDownComplete] = useState(true);

  const setView = useAppStore((state) => state.setView);
  const isLoading = useAppStore((state) => state.isLoading);

  const SHOW_ENTER_BUTTON = process.env.ENV === 'dev' || QA_MODE;

  useEffect(() => {
    setView('landing');
    if (!isLoading) animateIn();

    const unsubscribeLoadingState = useAppStore.subscribe(
      (state) => state.isLoading,
      async (value) => {
        if (!value) {
          beforeAnimation();
          await Sleep(800);
          animateIn();
        }
      }
    );

    return () => {
      unsubscribeLoadingState();
    };
  }, [animated]);

  const beforeAnimation = () => {
    gsap.set('[data-animate]', {
      y: 150,
      opacity: 0,
    });
    gsap.set(videoBackground.current, {
      opacity: 0,
      scale: 1.1,
    });
  };

  const animateIn = () => {
    if (!mainRef) return;

    setSnowRunning(true);

    gsap.fromTo(
      videoBackground.current,
      {
        opacity: 0,
        scale: 1.1,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 2.4,
        stagger: 0.07,
        ease: 'expo.out',
      }
    );

    gsap.fromTo(
      '[data-animate]',
      {
        y: 150,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 2.4,
        stagger: 0.07,
        ease: 'expo.out',
      }
    );
  };

  const startExperience = useCallback(() => {
    video.current.play();
    dispatch(EVENT_INTRO_TRANSITION);
    setSnowRunning(false);
    // we might want to do some out- or loading animation before we navigate
    setView('landing-end');

    gsap.to(mainRef.current, {
      opacity: 0,
      duration: 0.5,
      ease: 'none',
      delay: 0.1,
    });
  }, [setView, video, setSnowRunning]);

  const onVideoEnd = () => {
    navigate(ROUTE_PATHS.metaverse);
  };

  const onComplete = () => {
    setCountDownComplete(true);
  };

  return (
    <>
      <main ref={mainRef} className="Landing">
        <Snowfall running={snowRunning} className="Landing__snowfall" />
        <div className="Landing__bg">
          <div className="Landing__bg__inner" ref={videoBackground}>
            {isMobile && <VideoSequence videoUrl="/videos/landing-bg-m" loop={true} autoPlay={true} />}
            {!isMobile && <VideoSequence videoUrl="/videos/landing-bg" loop={true} autoPlay={true} />}
          </div>
        </div>
        <div className="Landing__container">
          <div className="Landing__text">
            <h2>
              <span data-animate>
                <TextGlow>Game of </TextGlow>
              </span>
              <span data-animate>Phones</span>
            </h2>

            <p data-animate>(And other stuff too)</p>

            <div className="Landing__countdown" data-animate>
              {!countDownComplete && <Countdown onComplete={onComplete}></Countdown>}
            </div>
            {(SHOW_ENTER_BUTTON || countDownComplete) && (
              <div className="Landing__cta" style={{ marginTop: '100px' }} data-animate>
                <Button onClick={startExperience} soundVolume={0.2} playSound={true}>
                  enter the experience
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="Landing__footer__wrapper" data-animate>
          {countDownComplete && (
            <>
              <FooterNav />
              <FooterSoundCta />
            </>
          )}
          {!isMobile && <Socials />}
        </div>
      </main>

      {(SHOW_ENTER_BUTTON || countDownComplete) && (
        <VideoSequence ref={video} endVideoAt={3.2} muted={false} videoUrl="/videos/intro" onVideoEnd={onVideoEnd} />
      )}
      {DEBUG_MODE && <DesignToggle screen="landing-m" />}
    </>
  );
};

export default Landing;
