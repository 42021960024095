import './SoundButton.scss';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import useAppStore from '@store/_app';

const SoundButton = (props) => {
  const { className } = props;

  const toggleSound = useAppStore((state) => state.toggleSound);
  const sound = useAppStore((state) => state.sound);

  const toggleMute = () => {
    toggleSound();
  };

  return (
    <div onClick={toggleMute} className={classnames('SoundButton', { 'SoundButton--playing': sound }, className)}>
      <svg
        className="SoundButton__icon"
        width="18"
        height="8"
        viewBox="0 0 18 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path className="SoundButton__icon__path SoundButton__icon__path--1" d="M1 0L1 8" stroke="currentColor" strokeWidth="1.5" />
        <path className="SoundButton__icon__path SoundButton__icon__path--2" d="M5 0L5 8" stroke="currentColor" strokeWidth="1.5" />
        <path className="SoundButton__icon__path SoundButton__icon__path--3" d="M9 0L9 8" stroke="currentColor" strokeWidth="1.5" />
        <path className="SoundButton__icon__path SoundButton__icon__path--4" d="M13 0L13 8" stroke="currentColor" strokeWidth="1.5" />
        <path className="SoundButton__icon__path SoundButton__icon__path--5" d="M17 0L17 8" stroke="currentColor" strokeWidth="1.5" />
      </svg>
    </div>
  );
};

export default SoundButton;
