export const Zones = {
  '/3d/wholeset/2022-11-30-t-2-26pm/i01.glb': {
    isBox3: true,
    min: {
      x: -4.6271891593933105,
      y: -1.5629079341888428,
      z: -55.5119349409627,
    },
    max: {
      x: 41.32010238577743,
      y: 6.000701546669006,
      z: 0.6058212518692017,
    },
  },
  '/3d/wholeset/2022-11-30-t-2-26pm/i02.glb': {
    isBox3: true,
    min: {
      x: -22.118682861328125,
      y: -4.8040618896484375,
      z: -48.78986675358886,
    },
    max: {
      x: 43.68156814575195,
      y: 6.0003862380981445,
      z: 7.669795036315918,
    },
  },
  '/3d/wholeset/2022-11-30-t-2-26pm/i03.glb': {
    isBox3: true,
    min: {
      x: -9.051521301269531,
      y: -4.2681732177734375,
      z: -24.82991600036621,
    },
    max: {
      x: 43.68156814575195,
      y: 6.0003862380981445,
      z: 7.669795036315918,
    },
  },
  '/3d/wholeset/2022-11-30-t-2-26pm/i04.glb': {
    isBox3: true,
    min: {
      x: -69.28277969360352,
      y: -4.268193244934082,
      z: -55.460514068603516,
    },
    max: {
      x: 5.6271867752075195,
      y: 6.000199556350708,
      z: 32.31719207763672,
    },
  },
  '/3d/wholeset/2022-11-30-t-2-26pm/i05.glb': {
    isBox3: true,
    min: {
      x: -112.30299136042595,
      y: -2.134096145629883,
      z: -55.4605131149292,
    },
    max: {
      x: 7.020113945007324,
      y: 10.268722534179688,
      z: 8.274717330932617,
    },
  },
  '/3d/wholeset/2022-11-30-t-2-26pm/i06.glb': {
    isBox3: true,
    min: {
      x: -72.10866287573211,
      y: -1.4227309226989746,
      z: -49.02719187736511,
    },
    max: {
      x: 13.8446626663208,
      y: 6.781040692564439,
      z: 3.6626815795898438,
    },
  },
};

export const ZoneList = Object.values(Zones);
