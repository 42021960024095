import './Socials.scss';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useUserSessionStore from '@store/_user-session';
import IconFacebook from './icons/IconFacebook/IconFacebook';
import IconTwitter from './icons/IconTwitter/IconTwitter';
import ShareButton from '../ShareButton/ShareButton';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const icons = {
  facebook: IconFacebook,
  twitter: IconTwitter,
};

const Social = ({ icon, href, onClick }) => {
  const IconComponent = icons[icon];
  return (
    <ShareButton onClick={onClick} link={href}>
      <IconComponent />
    </ShareButton>
  );
};
Social.propTypes = {
  icon: PropTypes.string,
  href: PropTypes.string,
};

const Socials = (props) => {
  const { className } = props;
  const baseUrl = process.env.APP_URL || 'http://localhost:5173/';

  const setSocialSharePointsReceived = useUserSessionStore((state) => state.setSocialSharePointsReceived);
  const socialSharePointsReceived = useUserSessionStore((state) => state.socialSharePointsReceived);
  const increasePoints = useUserSessionStore((state) => state.increasePoints);
  const increaseTotalPoints = useUserSessionStore((state) => state.increaseTotalPoints);

  const handleClick = () => {
    if (!socialSharePointsReceived) {
      setSocialSharePointsReceived(true);
      increasePoints(1);
      increaseTotalPoints();
    }
  };

  return (
    <div className={classnames('Socials', className)}>
      <span className="Socials__label">Share</span>

      <Social
        onClick={handleClick}
        icon="facebook"
        // eslint-disable-next-line max-len
        href={`https://www.facebook.com/sharer/sharer.php?u=${baseUrl}&quote=I%20just%20entered%20the%20coolest%20giveaway%20of%20the%20year%20%E2%80%93%20%40tmobile%E2%80%99s%20Game%20of%20Phones%21%20Check%20it%20out.%C2%A0https%3A%2F%2Fgameofphonesvr.com`}
      />
      <Social
        onClick={handleClick}
        icon="twitter"
        // eslint-disable-next-line max-len
        href={`https://twitter.com/intent/tweet?text=I%20just%20entered%20the%20coolest%20giveaway%20of%20the%20year%20%E2%80%93%20%40tmobile%E2%80%99s%20Game%20of%20Phones%21%20Check%20it%20out.%C2%A0https%3A%2F%2Fgameofphonesvr.com&amp;url=${baseUrl}`}
      />
    </div>
  );
};

Socials.propTypes = propTypes;
Socials.defaultProps = defaultProps;

export default Socials;
