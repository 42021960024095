import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Loader.scss';
import gsap from 'gsap';
import useEffectWithPrevious from 'use-effect-with-previous';
import { dispatch } from 'use-bus';
import useAppStore from '@store/_app';
import { useRafPerf, useFramePerf } from '@hooks/use-raf-perf.js';
import { useCanvasStore, useUserSessionStore } from '@store/index.js';
import { EVENT_GL_COMPILE } from '@settings/settings.events.js';
import BrandLogo from '../BrandLogo/BrandLogo';
import FooterSoundCta from '../FooterSoundCta/FooterSoundCta';

const Loader = () => {
  const [loaded, setLoaded] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const logo = useRef(null);
  const footer = useRef(null);
  const loaderInner = useRef(null);
  const loaderOuter = useRef(null);

  const setView = useAppStore((state) => state.setView);
  const setIsLoading = useAppStore((state) => state.setIsLoading);
  const setIsExperienceLoaded = useAppStore((state) => state.setIsExperienceLoaded);

  useEffect(() => {
    if (loaded) return;
    setView('loader');
    animateIn();
    setShowProgress(true);
  }, [loaded]);

  const animateIn = () => {
    const animInTimeline = gsap.timeline({ onComplete: () => {
      animInTimeline.kill();
    } });

    animInTimeline.add([
      gsap.set(loaderOuter.current, { yPercent: 0 }),
      gsap.set(loaderInner.current, { opacity: 1, y: 0 }),

      gsap.fromTo(
        logo.current,
        {
          scale: 0.7,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          ease: 'expo.out',
          duration: 2,
        }
      ),

      gsap.fromTo(
        footer.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'expo.out',
          duration: .5,
        }
      )
    ]);
  };

  const animateOut = () => {
    const ANIM_OUT_DURATION = 0.8;

    gsap.delayedCall(ANIM_OUT_DURATION * 1, () => {
      // Set experience loaded halfway through the anim out animation
      setIsExperienceLoaded(true);
    });

    const animOutTimeline = gsap.timeline({ onComplete: () => {
      animOutTimeline.kill();
    } });

    animOutTimeline.add([
      gsap.to(loaderOuter.current, {
        yPercent: -101,
        ease: 'expo.in',
        duration: ANIM_OUT_DURATION,
        onStart: () => {
          setIsLoading(false);
        },
        onComplete: () => {
          setLoaded(true);
        }
      }),

      gsap.to(loaderInner.current, {
        opacity: 0,
        ease: 'expo.in',
        duration: ANIM_OUT_DURATION,
        y: '50vh',
      }),

      gsap.to(footer.current, {
        opacity: 0,
        ease: 'expo.in',
        duration: ANIM_OUT_DURATION,
      })
    ]);

  };

  return (
    <>
      {!loaded && (
        <div ref={loaderOuter} className="Loader">
          <div ref={loaderInner} className="Loader__inner">
            <div ref={logo} className="Loader__logo">
              <BrandLogo />
            </div>
            <LoaderProgress onAnimateOut={animateOut} />
          </div>
          <div ref={footer} className="Loader__footer">
            <FooterSoundCta />
          </div>
        </div>
      )}
    </>
  );
};

const LoaderProgress = ({ onAnimateOut }) => {
  // doesn't work with data images
  // const { progress } = useProgress();
  const [loaded, setLoaded] = useState(false);
  const loaderBar = useRef(null);
  const loaderBarAmount = useRef(null);
  const loaderText = useRef(null);
  const loadedPercentage = useRef(0.6);
  const loadedPercentageAnimObj = useRef({ progress: 0 });
  const loadedPercentageAddTracker = useRef({
    hologramsLoaded: false,
    canvasAssetsLoaded: false
  });
  const loaderDidCallAnimateOut = useRef(false);
  const isCanvasAssetsLoaded = useAppStore((state) => state.isCanvasAssetsLoaded);
  const isLoaded = useCanvasStore((state) => state.isLoaded);
  // useEffectWithPrevious(([prevIsCanvasAssetsLoaded]) => {
  //   console.info('isCanvasAssetsLoaded:', isCanvasAssetsLoaded);
  //   if (!prevIsCanvasAssetsLoaded && isCanvasAssetsLoaded && !loadedPercentageAddTracker.current.canvasAssetsLoaded){
  //     loadedPercentage.current += 0.30;
  //     loadedPercentageAddTracker.current.canvasAssetsLoaded = true;
  //   }
  // }, [isCanvasAssetsLoaded]);
  useEffectWithPrevious(([prevIsLoaded]) => {
    if (!prevIsLoaded && isLoaded && !loadedPercentageAddTracker.current.canvasAssetsLoaded){
      loadedPercentage.current += 0.30;
      loadedPercentageAddTracker.current.canvasAssetsLoaded = true;
    }
  }, [isLoaded]);

  const loadedHolograms = useAppStore((state) => state.loadedHolograms);
  useEffect(() => {
    if (!useUserSessionStore.getState().activeHolograms.includes('MIKESIEVERT') || loadedHolograms.includes('MIKESIEVERT')){
      // If the user has already watched Mike, don't need for his hologram to be loaded
      if (!loadedPercentageAddTracker.current.hologramsLoaded) {
        loadedPercentage.current += 0;
        loadedPercentageAddTracker.current.hologramsLoaded = true;
      }
    }
  }, [loadedHolograms]);

  useEffect(() => {
    // Setup
    gsap.set(
      loaderBarAmount.current,
      {
        scaleX: 0,
      });
  }, []);

  useEffect(() => {
    animateIn();
    return () => {
      // animateOut()
    };
  }, [loaded]);

  const animateIn = () => {
    gsap.set(loaderBarAmount.current, { scaleX: 0 });
    gsap.fromTo(
      loaderBar.current,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'expo.out',
        duration: 2,
        onComplete: () => {
          loadTempAnimation();
        },
      }
    );
  };

  const loadTempAnimation = () => {
    const temp = { progress: 0 };

  };

  const animateOut = function(){
    if (!loaderDidCallAnimateOut.current) {
      loaderDidCallAnimateOut.current = true;
      stop();
      // Complete the animation
      animateProgressTo(1, onAnimateOut, 1);
      // Compile materials
      setTimeout(() => {
        dispatch(EVENT_GL_COMPILE);
      }, 0);
      console.log('ANIM OUT');
    }
  };

  const animateProgressTo = function(progress, onComplete, animDuration){
    gsap.killTweensOf([loadedPercentageAnimObj.current, loaderBarAmount.current]);
    const tl = gsap.timeline({ onComplete: () => {
      onComplete && onComplete();
      tl.kill();
    } });
    tl.add([
      gsap.to(
        loadedPercentageAnimObj.current,
        {
          progress: progress,
          ease: 'expo.out',
          duration: animDuration,
          onUpdate: () => {
            if (loaderText.current) {
              loaderText.current.innerText = `${Math.ceil(loadedPercentageAnimObj.current.progress * 100)} %`;
            }
          }
        }
      ),
      gsap.to(
        loaderBarAmount.current,
        {
          scaleX: progress,
          ease: 'expo.out',
          duration: animDuration,
        }
      )]);
  };

  const onProgressAnimComplete = function(){
    // Leave 10% for materials compile
    if (loadedPercentageAnimObj.current.progress >= 0.9){
      animateOut();
    }
  };

  const rafLoop = useCallback(() => {
    let animDuration = 3;
    if (loadedPercentage.current > 0.6){
      animDuration = 2;
    }
    if (!loaderDidCallAnimateOut.current) {
      animateProgressTo(loadedPercentage.current, onProgressAnimComplete, animDuration);
    }
  }, []);
  const [start, stop] = useRafPerf(rafLoop, 2);
  useEffect(() => {
    start && start();
    return () => {
      stop();
    };
  }, [start, stop]);

  return (
    <div className="Loader__progress">
      <div ref={loaderBar} className="Loader__progress__bar">
        <div ref={loaderBarAmount} className="Loader__progress__bar__amount"></div>
      </div>
      <div ref={loaderText} className="Loader__progress__text">
        0 %
      </div>
    </div>
  );
};
export default Loader;
