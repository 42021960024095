import './FooterSoundCta.scss';

const FooterSoundCta = (props) => {
  return (
    <div className="FooterSoundCta">
      For the best experience please turn on your sound
    </div>
  );
};

export default FooterSoundCta;
