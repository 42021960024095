import './Sounds.scss';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactHowler from 'react-howler';
import useBus from 'use-bus';
import gsap from 'gsap';
import {
  EVENT_BUTTON_CLICK,
  EVENT_INTRO_TRANSITION,
  EVENT_HOLOGRAM_APPEAR,
  EVENT_HOLOGRAM_DISAPPEAR,
  EVENT_HOLOGRAM_PLAY,
  EVENT_ANSWER_CORRECT,
  EVENT_ANSWER_WRONG,
  EVENT_NAVIGATE_TO_MARKER,
  EVENT_WIN_POINTS
} from '@settings/settings.events.js';
import useAppStore from '@store/_app';
import { detection } from '@utils/device';
import useUserSessionStore from '@store/_user-session';

const transitionVolume = function(sound, volTo, duration = 1){
  if (sound && sound.volume){
    const curVol = sound.volume();
    const volAnimObj = { volume: curVol };
    const gsapAnim = gsap.to(volAnimObj, { volume: volTo,
      duration: duration,
      ease: 'power1.out',
      onComplete: () => {
        // cleanup
        gsapAnim && gsapAnim.kill();
      },
      onUpdate: () => {
        sound.volume(volAnimObj.volume);
      } });
  }
};

const Sounds = () => {
  const buttonSound = useRef();
  const introTransition = useRef();
  const hologramAppear = useRef();
  const hologramDisappear = useRef();
  const answerCorrect = useRef();
  const answerWrong = useRef();
  const movement = useRef();
  const end = useRef();
  const background = useRef();
  const winPoints = useRef();

  const [interactedWithPage, setInteractedWithPage] = useState(false);
  const [buttonPlaying, setButtonPlaying] = useState(false);
  const [introTransitionPlaying, setIntroTransitionPlaying] = useState(false);
  const [hologramAppearPlaying, setHologramAppearPlaying] = useState(false);
  const [hologramDisappearPlaying, setHologramDisappearPlaying] = useState(false);
  const [answerCorrectPlaying, setAnswerCorrectPlaying] = useState(false);
  const [answerWrongPlaying, setAnswerWrongPlaying] = useState(false);
  const [movementPlaying, setMovementPlaying] = useState(false);
  const [endPlaying, setEndPlaying] = useState(false);
  const [soundPlaying, setSoundPlaying] = useState(true);
  const [winPointsPlaying, setWinPointsPlaying] = useState(false);

  const setSound = useAppStore((state) => state.setSound);
  const sound = useAppStore((state) => state.sound);
  const [soundOnVisibility, setSoundOnVisibility] = useState(false);

  const { isMobile } = detection;

  const onVisibilitychange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      setSound(soundOnVisibility);
    } else {
      setSoundOnVisibility(sound);
      setSound(false);
    }
  }, [setSound, soundOnVisibility, sound]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilitychange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilitychange);
    };
  }, [onVisibilitychange]);

  useEffect(() => {
    if (document.visibilityState !== 'visible'){
      window.Howler.mute(true);
    } else {
      window.Howler.mute(false);
      transitionVolume(window.Howler, soundPlaying ? 1 : 0, 0.8);
    }
  }, [soundPlaying]);

  useEffect(() => {
    const unsubscribeCheckSound = useAppStore.subscribe(
      (state) => state.sound,
      (sound) => {
        setSoundPlaying(sound);
      }
    );

    const unsubscribeCancelAllSounds = useAppStore.subscribe(
      (state) => state.view,
      (view) => {
        if (view === 'endScreen') {
          end.current.seek(0);
          setEndPlaying(true);
        } else {
          setEndPlaying(false);
        }
      }
    );

    const unsubscribePointsAdded = useUserSessionStore.subscribe(
      (state) => state.pointsAdded,
      (pointsAdded) => {
        if (pointsAdded.amount > 0) {
          winPoints.current.seek(0);
          setWinPointsPlaying(true);
        }
      }
    );

    return () => {
      unsubscribeCancelAllSounds();
      unsubscribeCheckSound();
      unsubscribePointsAdded();
    };
  }, []);

  useBus(
    EVENT_BUTTON_CLICK,
    (ev) => {
      const payload = ev.payload;
      const vol = payload && payload.volume ? payload.volume : 1;
      buttonSound.current.volume(vol);
      buttonSound.current.seek(0);
      setButtonPlaying(true);
    },
    []
  );
  useBus(
    EVENT_INTRO_TRANSITION,
    () => {
      introTransition.current.seek(0);
      setIntroTransitionPlaying(true);
    },
    []
  );

  useBus(
    EVENT_HOLOGRAM_APPEAR,
    () => {
      hologramAppear.current.seek(0);
      setHologramAppearPlaying(true);
    },
    []
  );

  useBus(
    EVENT_HOLOGRAM_PLAY,
    () => {
      transitionVolume(background.current, 0.25, 1);
    },
    []
  );

  useBus(
    EVENT_HOLOGRAM_DISAPPEAR,
    () => {
      hologramDisappear.current.seek(0);
      setHologramDisappearPlaying(true);
      transitionVolume(background.current, 1, 1);
    },
    [background, setHologramDisappearPlaying]
  );

  useBus(
    EVENT_ANSWER_CORRECT,
    () => {
      answerCorrect.current.seek(0);
      setAnswerCorrectPlaying(true);
    },
    []
  );

  useBus(
    EVENT_ANSWER_WRONG,
    () => {
      answerWrong.current.seek(0);
      setAnswerWrongPlaying(true);
    },
    []
  );

  useBus(
    EVENT_WIN_POINTS,
    () => {
      winPoints.current.seek(0);
      setWinPointsPlaying(true);
    },
    []
  );

  useBus(
    EVENT_NAVIGATE_TO_MARKER,
    () => {
      movement.current.seek(0);
      setMovementPlaying(true);
    },
    []
  );

  return (
    <div className="Sounds">
      <ReactHowler
        ref={buttonSound}
        src={['/sounds/webm/button.webm', '/sounds/mp3/button.mp3']}
        playing={buttonPlaying}
        onEnd={() => setButtonPlaying(false)}
      />
      <ReactHowler
        ref={introTransition}
        src={['/sounds/webm/intro-transition.webm', '/sounds/mp3/intro-transition.mp3']}
        playing={introTransitionPlaying}
        onEnd={() => setIntroTransitionPlaying(false)}
      />
      <ReactHowler
        ref={hologramAppear}
        src={['/sounds/webm/hologram-appear.webm', '/sounds/mp3/hologram-appear.mp3']}
        playing={hologramAppearPlaying}
        onEnd={() => setHologramAppearPlaying(false)}
      />
      <ReactHowler
        ref={hologramDisappear}
        src={['/sounds/webm/hologram-disappear.webm', '/sounds/mp3/hologram-disappear.mp3']}
        playing={hologramDisappearPlaying}
        onEnd={() => setHologramDisappearPlaying(false)}
      />
      <ReactHowler
        ref={answerWrong}
        src={['/sounds/webm/answer-wrong.webm', '/sounds/mp3/answer-wrong.mp3']}
        playing={answerWrongPlaying}
        onEnd={() => setAnswerWrongPlaying(false)}
      />
      <ReactHowler
        ref={answerCorrect}
        src={['/sounds/webm/answer-correct.webm', '/sounds/mp3/answer-correct.mp3']}
        playing={answerCorrectPlaying}
        onEnd={() => setAnswerCorrectPlaying(false)}
      />
      <ReactHowler
        ref={movement}
        src={['/sounds/webm/movement.webm', '/sounds/mp3/movement.mp3']}
        playing={movementPlaying}
        onEnd={() => setMovementPlaying(false)}
      />
      <ReactHowler
        ref={end}
        src={['/sounds/webm/victory.webm', '/sounds/mp3/victory.mp3']}
        playing={endPlaying}
        onEnd={() => setEndPlaying(false)}
      />
      <ReactHowler
        ref={background}
        //html5={isMobile}
        src={['/sounds/webm/background.webm', '/sounds/mp3/background.mp3']}
        volume={1.0}
        loop={true}
        playing={true}
        preload={true}
      />
      <ReactHowler
        ref={winPoints}
        src={['/sounds/webm/win-point.webm', '/sounds/mp3/win-point.mp3']}
        playing={winPointsPlaying}
        onEnd={() => setWinPointsPlaying(false)}
      />
    </div>
  );
};

export default Sounds;
