import { useEffect, useState } from 'react';
import { useCanvasStore } from '@store/index.js';
import useCanvasHooks from '@hooks/use-canvas-hooks.js';
import { CANVAS_MAX_LOAD_PERCENTAGE } from '@settings/settings.app.js';
import useThreeLoadingManager from '@hooks/use-three-loading-manager.js';

export default function useCanvasLoadHandler(){
  const [loadProgress, setLoadProgress] = useState(0);

  const { setIsLoaded } = useCanvasStore((state) => ({
    setIsLoaded: state.setIsLoaded
  }));

  useCanvasHooks();

  useEffect(() => {
    if (loadProgress >= CANVAS_MAX_LOAD_PERCENTAGE) {
      setIsLoaded(true);
    }
  }, [loadProgress, setIsLoaded]);

  useThreeLoadingManager({
    onProgress: (progress) => setLoadProgress(progress),
  });
}
