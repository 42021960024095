const performanceSettings = {
  tier3: {
    camera: {
      fov: 55,
      far: 30
    },
    frustumCullingFovAdd: 20
  },
  tier2: {
    maxOrbitAngle: Math.PI * 0.75,
    camera: {
      fov: 55,
      far: 25
    },
    frustumCullingFovAdd: 10
  }
};

export default performanceSettings;
