import { Vector3 } from 'three';
import useAppStore from '@store/_app.js';
import { useCanvasStore } from '@store/index.js';

const dummyPosVec = new Vector3();
export const getCameraPosition = function(camera){
  if (useAppStore.getState().inXRSession){
    camera.getWorldPosition(dummyPosVec);
    return dummyPosVec;
  } else {
    return camera.position;
  }
};

export const getCameraObject = function(camera){
  if (useAppStore.getState().inXRSession){
    const globalPlayer = useCanvasStore.getState().globalPlayerObject;
    if (globalPlayer) {
      return globalPlayer;
    }
  }
  return camera;
};

const dummyRotVec = new Vector3();
export const getCameraRotation = function(camera){
  if (useAppStore.getState().inXRSession){
    const globalPlayer = useCanvasStore.getState().globalPlayerObject;
    if (globalPlayer) {
      return globalPlayer.rotation;
    }
  }
  return camera.rotation;
};

export const getCameraQuaternion = function(camera){
  if (useAppStore.getState().inXRSession){
    const globalPlayer = useCanvasStore.getState().globalPlayerObject;
    if (globalPlayer) {
      return globalPlayer.quaternion;
    }
  }
  return camera.quaternion;
};
