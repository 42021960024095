import './OnBoarding.scss';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { useLocation } from 'wouter';
import gsap from 'gsap';
import Modal from '@components/elements/Modal/Modal';
import ModalStep from '@components/elements/ModalStep/ModalStep';
import Button from '@components/elements/Button/Button';
import ButtonGroup from '@components/elements/ButtonGroup/ButtonGroup';
import IconArrow from '@components/elements/IconArrow/IconArrow';
import { ROUTE_PATHS } from '@settings/settings.app';
import useAppStore from '@store/_app';
import { DesignToggle } from '@components/helpers/DesignToggle/DesignToggle';
import useMediaQuery from '@hooks/use-media-query';
import { DEBUG_MODE } from '@settings/settings.params';

const OnBoarding = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [location, navigate] = useLocation();
  const setView = useAppStore((state) => state.setView);
  const setOnBoardingCompleted = useAppStore((state) => state.setOnBoardingCompleted);
  const isMobile = useMediaQuery('(max-width: 850px)');
  const rootEl = useRef();

  const { className } = props;
  const [modalIndex, setModalIndex] = useState(0);

  const STEPS = 2;

  const next = () => {
    setModalIndex(modalIndex + 1);
  };

  const prev = () => {
    setModalIndex(modalIndex - 1);
  };

  const start = () => {
    setOnBoardingCompleted(true);
    // navigate(ROUTE_PATHS.metaverse);

    setView('metaverse');
  };

  const animateOut = useCallback(() => {
    if (rootEl) {
      const tl = gsap.timeline({
        onComplete: () => {
          tl.kill();
          start();
        },
      });
      tl.to(rootEl.current, { opacity: 0, duration: 1, ease: 'none' });
    }
  }, [rootEl]);

  return (
    <main ref={rootEl} className={classnames('OnBoarding', className)}>
      <div className="OnBoarding__modal">
        <Modal animInDelay={0.1}>
          <div className="OnBoarding__modal__inner">
            <div className="OnBoarding__modal__main">
              <div>
                <div className="OnBoarding__modal__steps">
                  <div className="OnBoarding__modal__step">
                    <h2>
                      <span>Explore our HQ</span> <br /> & find markers
                    </h2>
                  </div>
                  {!isMobile && (
                    <div className="OnBoarding__modal__step">
                      <h2>
                        <span>Answer trivia</span> <br />& earn points
                      </h2>
                    </div>
                  )}
                </div>
                <div className="OnBoarding__modal__img">
                  {isMobile && (
                    <picture>
                      <source srcSet="/images/on-boarding-m.webp" type="image/webp" />
                      <source srcSet="/images/on-boarding-m.jpg" type="image/jpeg" />
                      <img width="675" height="756" src="/images/on-boarding-m.webp" alt="Prizes" />
                    </picture>
                  )}
                  {!isMobile && (
                    <picture>
                      <source srcSet="/images/on-boarding.webp" type="image/webp" />
                      <source srcSet="/images/on-boarding.jpeg" type="image/jpeg" />
                      <img width="810" height="308" src="/images/on-boarding.webp" alt="Prizes" />
                    </picture>
                  )}
                </div>
                {isMobile && (
                  <div className="OnBoarding__modal__step">
                    <h2>
                      <span>Answer trivia</span> <br />& earn points
                    </h2>
                  </div>
                )}
              </div>
            </div>
            <div className="Modal__footer">
              <div className="Modal__footer__inner">
                <Button onClick={animateOut} playSound={true}>
                  Enter for a Chance to Win
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {DEBUG_MODE && <DesignToggle screen="onboarding-m" />}
    </main>
  );
};

export default OnBoarding;
