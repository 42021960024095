import './WidgetCloseButton.scss';
import React from 'react';
import classnames from 'classnames';
import useMediaQuery from '@hooks/use-media-query';

const WidgetCloseButton = ({ onClick, isOpen }) => {
  const isMobile = useMediaQuery('(max-width: 850px)');
  return (
    <div className={classnames('WidgetCloseButton', { 'WidgetCloseButton--open': isOpen })} onClick={onClick}>
      {(!isMobile || isOpen) && (
        <svg
          className="WidgetCloseButton__arrow"
          width="20"
          height="12"
          viewBox="0 0 20 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18.4853 10.4854L10 2.00014L1.51472 10.4854" stroke="currentColor" strokeWidth="2" />
        </svg>
      )}
      {isMobile && !isOpen && (
        <svg
          className="WidgetCloseButton__marker"
          width="20"
          height="24"
          viewBox="0 0 20 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="4" stroke="white" strokeWidth="1.5" />
          <path
            d="M19 9.8C19 16.4 10 23 10 23C10 23 1 16.95 1 9.8C1 4.93989 5.02944 1 10 1C14.9706 1 19 4.93989 19 9.8Z"
            stroke="white"
            strokeWidth="1.5"
          />
        </svg>
      )}
    </div>
  );
};

export default WidgetCloseButton;
