import { useEffect } from 'react';
import { useNonFunctionalStore, useWindowSizeStore } from '@store';
import { detection } from '@utils/device';
import { checkDeviceSupport } from '@utils/device-support';
import { checkWindowTooSmall, checkWindowTooLarge } from '@utils/device-screen-size';
import { isWebGLAvailable, isWebGL2Available } from '@utils/webgl-support';

const useNonFunctionalCheck = () => {
  const windowSize = useWindowSizeStore(state => state.windowSize);
  const {
    setDeviceSupport,
    setWindowTooSmall,
    setWindowTooLarge,
    setWebglDisabled
  } = useNonFunctionalStore(state => ({
    setDeviceSupport  : state.setDeviceSupport,
    setWindowTooSmall : state.setWindowTooSmall,
    setWindowTooLarge : state.setWindowTooLarge,
    setWebglDisabled  : state.setWebglDisabled,
  }));

  // check browser and os support
  useEffect(() => {
    setDeviceSupport(checkDeviceSupport());

    if (!(isWebGLAvailable() && isWebGL2Available())) {
      setWebglDisabled(true);
    }
  }, []);

  // check window size
  useEffect(() => {
    if (detection.isDesktop) {
      setWindowTooSmall(checkWindowTooSmall(windowSize));
      setWindowTooLarge(checkWindowTooLarge(windowSize));
    }
  }, [windowSize, setWindowTooSmall, setWindowTooLarge]);
};

export default useNonFunctionalCheck;
