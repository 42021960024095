import './Modal.scss';
import React, { useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import { useEffectOnce } from 'use-effect-once';
import { motion } from 'framer-motion';

const Modal = ({ className, children, onClick, animInDelay = 0 }) => {
  const modalRef = useRef(null);
  const modalBg = useRef(null);
  const modalOuterRef = useRef();

  useEffectOnce(() => {
    if (!modalRef) return;
    const tl = gsap.timeline({ delay: animInDelay,
      onComplete: () => {
        tl.kill();
      } });
    tl.add([
      gsap.fromTo(
        modalRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'none',
          duration: 0.5,
        }
      ),
      gsap.fromTo(
        modalOuterRef.current,
        {
          y: 50,
        },
        {
          y: 0,
          ease: 'expo.out',
          duration: 1.8,
        }
      )
    ]);
  }, []);

  return (
    <div ref={modalRef} onClick={onClick}
      className={classnames('Modal', className)}>
      <div ref={modalOuterRef} className="Modal__outer">
        <motion.div className="Modal__inner" onClick={(e) => e.stopPropagation()}>
          <div className="Modal__content">{children}</div>
        </motion.div>
      </div>
    </div>
  );
};

export default Modal;
