
import { useXR } from '@react-three/xr';
import React from 'react';
import { Vector2 } from 'three';
import { COLOR_MAGENTA } from '@settings/settings.consts.js';
import { VRGenericButton } from '@components/canvas/VRCloseButton/VRGenericButton.jsx';
import { VRStickyUI } from '../VRStickyUI/VRStickyUI';

export function VRCloseButton({ inVROnly = true }) {
  const session = useXR((s) => s.session);

  const onSelectHandler = function(){
    session && session.end();
  };

  return (
    <VRStickyUI lerp={1}>
      <VRGenericButton
        bgColor={COLOR_MAGENTA}
        color={'#ffffff'}
        fontSize={30}
        position={[0.51, 0.33, -1]}
        text={'EXIT VR'}
        // visible={!playerIsPlaying}
        onSelectHandler={onSelectHandler}
        size={(new Vector2(0.2, 0.08))} />
    </VRStickyUI>
  );
}
