import React, { createContext, forwardRef, useContext, useEffect, useMemo, useRef } from 'react';
import { BoxGeometry, CylinderBufferGeometry, DoubleSide, InstancedMesh } from 'three';
import { Merged, useGLTF } from '@react-three/drei';
import { commonMaterials } from '@components/canvas/common-materials.js';
import { useCanvasStore } from '@store/index.js';
import { useFramePerf } from '@hooks/use-raf-perf.js';
import useCameraOrXRPlayer from '@hooks/use-camera-or-x-r-player.js';

const useFrameData = {
  giftBoxPosY: 0
};

const context = createContext();
export const EvecoastPlayerInstances = function({ children, ...props }){

  const envMaps = useCanvasStore(s => s.envMaps);
  const counter = useRef(0);

  const [start, stop] = useFramePerf(function EvercoastPlayerSpin(_, dt) {
    counter.current += dt * 2;
    useFrameData.giftBoxPosY = 0.2 + Math.sin(counter.current) * 0.1;
  }, { fps: 25, name: 'gift-box-spin' });
  useEffect(() => {
    start && start();
  }, [start]);

  const { scene: glbScene, nodes } = useGLTF(`/3d/wholeset/2022-12-02-t-12-41pm/gift-box.glb`);
  useEffect(() => {
    if (envMaps && envMaps.studio) {
      glbScene.traverse((it) => {
        if (it.material) {
          it.material.envMap = envMaps.studio;
          it.material.side = DoubleSide;
          it.material.envMapIntensity = 1.5;
        }
      });
    }
  }, [glbScene, envMaps]);

  const cylinderGeom = useMemo(() => {
    return new CylinderBufferGeometry(0.3, 0.3, 2.5, 32, 1, true);
  }, []);
  const cylinderMesh = useMemo(() => {
    return new InstancedMesh(cylinderGeom, commonMaterials.wireframe);
  }, [cylinderGeom]);

  const boxGeom = useMemo(() => {
    //return new BoxGeometry(0.65, 1.75, 0.35);
    return new BoxGeometry(0.7, 1.75, 0.4);
  }, []);
  const boxMesh = useMemo(() => {
    const hitBox = new InstancedMesh(boxGeom, commonMaterials.transparent);
    hitBox.name = 'Hologram Hit Box';
    return hitBox;
  }, [boxGeom]);

  const meshes = useMemo(() => {
    return {
      cylinder: cylinderMesh,
      hitBox: boxMesh,
      GiftBox: nodes.GlowingGiftBox_v01
    };
  }, [cylinderMesh, boxMesh, nodes]);

  return (
    <Merged meshes={meshes} {...props}>
      {(instances) => <context.Provider value={instances} children={children} />}
    </Merged>
  );
};

export const EvecoastPlayerCylinder = function({ material, ...props }){
  const instances = useContext(context);
  return (
    <instances.cylinder material={material.current} {...props}>
    </instances.cylinder>
  );
};

export const EvecoastPlayerBox = forwardRef((props, ref) => {
  const instances = useContext(context);
  return (
    <instances.hitBox ref={ref} {...props} />
  );
});

export const EvecoastPlayerGiftBox = forwardRef((props, ref) => {
  const instances = useContext(context);
  const boxRef = useRef();
  const counter = useRef(0);
  const camera = useCameraOrXRPlayer();
  const [start, stop] = useFramePerf(function TMobileLogoSpin(_, dt) {
    if (boxRef) {
      const camPos = camera.position;
      boxRef.current.lookAt(camPos.x, camPos.y - 1, camPos.z);
      boxRef.current.position.y = useFrameData.giftBoxPosY;
    }
  }, { name: 'gift-box-look-at', fps: 30 });
  useEffect(() => {
    start && start();
    return () => {
      stop();
    };
  }, [start, stop]);
  return (
    <group ref={ref} {...props}>
      <instances.GiftBox ref={boxRef} />
    </group>
  );
});
