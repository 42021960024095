import React, { useMemo, forwardRef, useRef, useEffect } from 'react';
import { Color, AdditiveBlending, DoubleSide } from 'three';
import { useFrame } from '@react-three/fiber';

const vertexShader = /* glsl */ `
varying vec2 vUv;
uniform float uTime;
uniform float uReveal;
void main() {
  vUv = uv;
  vec3 scale = position;

  scale.xz *= sin(uTime * 1.5) * .125 + 1.;
  scale.y -= ((1.-uReveal) * 2.5);

  vec4 modelPosition = modelMatrix * vec4(scale, 1.0);
  vec4 viewPosition = viewMatrix * modelPosition;
  vec4 projectedPosition = projectionMatrix * viewPosition;

  gl_Position = projectedPosition;
}
`;

const fragmentShader = /* glsl */ `
#define S(v) smoothstep(-.5,.5, (v)/fwidth(v))

varying vec2 vUv;
uniform vec3 uColor;
uniform float uTime;
uniform float uReveal;
uniform float uStripeAlpha;

void main() {
    vec3 color = mix(uColor, uColor, sin(uTime) * vUv.y);
    float offset = sin(uTime * 1.5) * .5 + .85;

	  float stripe = S(sin(vUv.y * 100.0 + uTime * 5.0));
    vec3 stripeColor = mix(vec3(0.0,0.0,0.0), vec3(1.,1.,0.0), stripe);

    gl_FragColor = LinearTosRGB(vec4(color, 1.));
    gl_FragColor.a = max(offset * (.5 - vUv.y) * uReveal * max(stripeColor.r, 1. - uStripeAlpha), 0.0);
}
`;

function HologramIndicator({ position, onClick, onHandleHologram }, ref) {
  const uniforms = useMemo(
    () => ({
      uTime: {
        value: 0.0,
      },
      uReveal: {
        value: 1.0,
      },
      uColor: {
        value: new Color('#E20074'),
      },
      uStripeAlpha: {
        value: 1.0,
      },
    }),
    []
  );

  useFrame((state) => {
    const { clock } = state;
    ref.current.material.uniforms.uTime.value = clock.getElapsedTime();
  });

  const shaderMat = useRef();

  return (
    <mesh
      ref={ref}
      userData={{ isHologram: true, onHandleHologram: onHandleHologram }}
      position={position}
      onClick={onClick}
    >
      <cylinderGeometry args={[0.4, 0.4, 2.5, 32, 1, true]} />
      <shaderMaterial
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        transparent={true}
        uniforms={uniforms}
        depthWrite={false}
        blending={AdditiveBlending}
        side={DoubleSide}
        ref={shaderMat}
      />
    </mesh>
  );
}

HologramIndicator = forwardRef(HologramIndicator); // eslint-disable-line no-func-assign

export default HologramIndicator;
