import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './IconArrow.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const IconArrow = (props) => {
  const { className } = props;

  return (
    <div className={classnames('IconArrow', className)}>
      <svg width="12" height="16"
        viewBox="0 0 12 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M5.49296 0L12 7.4359V8.60513L5.49296 16H0L6.84507 8.04103L0 0H5.49296Z" fill="currentColor" />
      </svg>
    </div>
  );
};

IconArrow.propTypes = propTypes;
IconArrow.defaultProps = defaultProps;

export default IconArrow;
