// evercoast
// eslint-disable-next-line import/no-named-as-default
import EvercoastPlayerApi, { EvercoastPlayerApiConfig } from 'evercoast-player-api';
import EvercoastThreeJSRenderSystem from 'evercoast-renderers/lib/evercoast-threejs-rendersystem';
import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Box, Shadow } from '@react-three/drei';
import { Vector3, Plane, Ray, Object3D, Box3, Vector2 } from 'three';
import { useControls } from 'leva';
import gsap from 'gsap';
import useEffectWithPrevious from 'use-effect-with-previous';
import useBus, { dispatch } from 'use-bus';
import { Interactive } from '@react-three/xr';
import _get from 'lodash.get';
import useAppStore from '@store/_app';
import HologramIndicator from '@components/elements/HologramIndicator/HologramIndicator';
import { detection, isMobile } from '@utils/device.js';
import TransformControlsWrapper from '@components/canvas/TransformControlsWrapper/TransformControlsWrapper.jsx';
import { DEBUG_POSITION, DEBUG_MODE, VR_DEBUG } from '@settings/settings.params.js';
import HologramLabel from '@components/elements/HologramLabel/HologramLabel';
import HologramCta from '@components/elements/HologramCta/HologramCta';
import {
  EvecoastPlayerBox,
  EvecoastPlayerGiftBox
} from '@components/canvas/EvercoastPlayer/EvercoastPlayerInstancedMeshes.jsx';
import {
  EVENT_HOLOGRAM_APPEAR,
  EVENT_HOLOGRAM_DISAPPEAR,
  EVENT_HOLOGRAM_PLAY,
  EVENT_OPEN_QUESTION,
  EVENT_XR_SESSION_START
} from '@settings/settings.events';
import { useRafPerf, useFramePerf } from '@hooks/use-raf-perf.js';
import HologramCtas from '@components/elements/HologramCtas/HologramCtas';
import { useUserSessionStore } from '@store/index.js';
import { VRShowQuestionButton } from '@components/canvas/VRCloseButton/VRShowQuestionButton';
import { VRGenericButton } from '@components/canvas/VRCloseButton/VRGenericButton';
import { COLOR_MAGENTA } from '@settings/settings.consts.js';
import useCameraOrXRPlayer from '@hooks/use-camera-or-x-r-player.js';
import { ROUTES, VIEW_METAVERSE } from '@settings/settings.app.js';
import { EvercoastPlayerMaterial } from './EvercoastPlayerMaterial';

const settings = {
  gamma: 1.27,
  exposure: -0.4,
  contrast: 1.02,
  saturation: 1.3,
};

const VISIBLE_DISTANCE_RANGE = 20;
const MAX_DISTANCE_GIFTBOX = 20;
const MAX_OBSTACLES_GIFTBOX = 4;

const DIST_TO_START_LOAD = detection.isMobile && !detection.isVR ? 10 : 15;
const EvercoastPlayer = ({
  id,
  streamURL,
  loop = false,
  scale = 1,
  position = [0, 0, 0],
  rotation = [],
  showQuestionTiming,
  showTipTiming,
  onQuestionOpen,
  onQuestionTipOpen,
  showAtStart,
  name,
  jobTitle,
  limitOrbitRange,
}) => {
  // This reference gives us direct access to the THREE.Mesh object
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isThisHologramActive, setIsThisHologramActive] = useState(false);
  const [playerAPI, setPlayerAPI] = useState(null);
  const [playerReady, setPlayerReady] = useState(false);
  const [playerIsPlaying, setPlayerIsPlaying] = useState(false);
  const [meshAsset, setMeshAsset] = useState(null);
  const [meshShaderReady, setMeshShaderReady] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [ctaActive, setCtaActive] = useState(true);
  const [labelActive, setLabelActive] = useState(false);
  const [enableTransform, setEnableTransform] = useState(false);
  const [skipButtonActive, setSkipButtonActive] = useState(true);
  const [isInVisibleRange, setIsInVisibleRange] = useState(false);
  const distanceToPlayer = useRef(0);

  const giftBoxRef = useRef();

  const { setHologramActive, setLoadedHologram, inXRSession, onBoardingCompleted, question, view } = useAppStore(
    (state) => ({
      setHologramActive: state.setHologramActive,
      setLoadedHologram: state.setLoadedHologram,
      inXRSession: state.inXRSession,
      onBoardingCompleted: state.onBoardingCompleted,
      question: state.question,
      view: state.view,
    })
  );
  const removeHologramFromActiveHolograms = useUserSessionStore((state) => state.removeHologramFromActiveHolograms);

  const { gl: renderer } = useThree((s) => ({
    gl: s.gl,
  }));

  const camera = useCameraOrXRPlayer();

  // const clippingPlane = useMemo(() => {
  //   const plane = new Plane();
  //   plane.normal.set(0, -1, 0);
  //   plane.constant = 0;
  //   return plane;
  // }, []);

  useEffect(() => {
    if (playerReady) {
      if (DEBUG_POSITION) {
        setIsVisible(true);
      }
    }
  }, [playerReady]);

  // Sliders
  const { planeConstant } = useControls('Hologram', {
    planeConstant: {
      min: 0,
      max: 4,
      step: 0.1,
      value: 0,
    },
    gamma: {
      min: 1,
      max: 2,
      step: 0.01,
      value: settings.gamma,
      transient: false,
      onChange: (v) => {
        if (meshAssetRef.current && meshAssetRef.current.material && meshAssetRef.current.material.userData.shader) {
          meshAssetRef.current.material.userData.shader.uniforms.uGamma.value = v;
        }
      },
    },
    exposure: {
      min: -1,
      max: 1,
      step: 0.01,
      value: settings.exposure,
      transient: false,
      onChange: (v) => {
        if (meshAssetRef.current && meshAssetRef.current.material && meshAssetRef.current.material.userData.shader) {
          meshAssetRef.current.material.userData.shader.uniforms.uExposure.value = v;
        }
      },
    },
    contrast: {
      min: 0.5,
      max: 1.1,
      step: 0.01,
      value: settings.contrast,
      transient: false,
      onChange: (v) => {
        if (meshAssetRef.current && meshAssetRef.current.material && meshAssetRef.current.material.userData.shader) {
          meshAssetRef.current.material.userData.shader.uniforms.uContrast.value = v;
        }
      },
    },
    saturation: {
      min: 0,
      max: 2,
      step: 0.01,
      value: settings.saturation,
      transient: false,
      onChange: (v) => {
        if (meshAssetRef.current && meshAssetRef.current.material && meshAssetRef.current.material.userData.shader) {
          meshAssetRef.current.material.userData.shader.uniforms.uSaturation.value = v;
        }
      },
    },
    progress: {
      min: 0,
      max: 1,
      step: 0.01,
      value: 0,
      onChange: (v) => {
        if (meshAssetRef.current && meshAssetRef.current.material && meshAssetRef.current.material.userData.shader) {
          meshAssetRef.current.material.userData.shader.uniforms.uReveal.value = v;
        }
      },
      transient: false,
    },
  });

  // useEffect(() => {
  //   clippingPlane.constant = planeConstant;
  // }, [planeConstant, clippingPlane]);

  const rootObj = useRef();
  const boundObj = useRef();
  const meshAssetRef = useRef(null);
  const shadowRef = useRef(null);
  const hologramIndicatorRef = useRef(null);

  // Listen for events and cleanup when unmounting
  const hologramActiveObjectRef = useRef();
  const activeHologramsRef = useRef();
  const pointsEarnedRef = useRef();
  const loadedHologramsRef = useRef();
  useEffect(() => {
    activeHologramsRef.current = useUserSessionStore.getState().activeHolograms;
    pointsEarnedRef.current = useUserSessionStore.getState().pointsEarned;
    loadedHologramsRef.current = useAppStore.getState().loadedHolograms;
    const subs = [
      useUserSessionStore.subscribe(
        (state) => state.activeHolograms,
        (activeHolograms) => {
          activeHologramsRef.current = activeHolograms;
        }
      ),
      useAppStore.subscribe(
        (state) => state.loadedHolograms,
        (loadedHolograms) => {
          loadedHologramsRef.current = loadedHolograms;
        }
      ),
      useUserSessionStore.subscribe(
        (state) => state.pointsEarned,
        (pointsEarned) => {
          pointsEarnedRef.current = pointsEarned;
        }
      )
    ];
    return () => {
      subs &&
        subs.map((s) => {
          s();
        });
    };
  }, []);

  // Distance Check
  const distCheckCallback = useCallback(() => {
    const distance = camera.position.distanceTo(rootObj.current.position);
    distanceToPlayer.current = distance;
    if (distance <= VISIBLE_DISTANCE_RANGE) {
      if (!isInVisibleRange) {
        setIsInVisibleRange(true);
      }
    } else {
      if (isInVisibleRange) {
        setIsInVisibleRange(false);
      }
    }
  }, [isInVisibleRange, setIsInVisibleRange, camera]);
  const [startDistanceCheck, stopDistanceCheck] = useFramePerf(distCheckCallback, {
    fps: 5,
    name: 'evercoast-dist-check-' + id,
  });
  useEffect(() => {
    if (view === VIEW_METAVERSE) {
      startDistanceCheck && startDistanceCheck();
    } else {
      stopDistanceCheck();
    }
    return () => {
      stopLoadCheck();
    };
  }, [startDistanceCheck, stopDistanceCheck, view]);

  // LOAD CHECK
  const checkIfHologramReadyToLoad = useCallback(() => {
    if (isVisible || isLoaded || readyToLoad || !rootObj.current) return;
    // if (hologramActiveObjectRef && hologramActiveObjectRef.current){
    //   return;
    // }
    if (distanceToPlayer.current < DIST_TO_START_LOAD) {
      // Make sure MIKESIEVERT is loaded first
      if (pointsEarnedRef.current === 0 && id !== 'MIKESIEVERT') {
        if (
          activeHologramsRef &&
          activeHologramsRef.current.includes('MIKESIEVERT') &&
          loadedHologramsRef &&
          !loadedHologramsRef.current.includes('MIKESIEVERT')
        ) {
          //console.log('skipping other hologram load');
          return;
        }
      }
      console.log('Hologram ready to load', id);
      setReadyToLoad(true);
      stopLoadCheck();
    }
  }, [setReadyToLoad, readyToLoad, camera, isLoaded, pointsEarnedRef, isVisible]);
  const [startLoadCheck, stopLoadCheck] = useFramePerf(checkIfHologramReadyToLoad, {
    fps: 5,
    name: 'evercoast-load-check-' + id,
  });
  useEffect(() => {
    if (view === VIEW_METAVERSE) {
      startLoadCheck && startLoadCheck();
    } else {
      stopLoadCheck();
    }
    return () => {
      stopLoadCheck();
    };
  }, [startLoadCheck, stopLoadCheck, view]);

  // Compute bounding box
  const hologramBoundingBox = useMemo(() => {
    return new Box3();
  }, []);
  useEffect(() => {
    if (boundObj && hologramBoundingBox) {
      boundObj.current.geometry.computeBoundingBox();
      boundObj.current.updateMatrix();
      hologramBoundingBox.copy(boundObj.current.geometry.boundingBox);
      hologramBoundingBox.applyMatrix4(boundObj.current.matrix);
    }
  }, [boundObj, hologramBoundingBox]);

  const loadingStatusRef = useRef(false);
  useEffect(() => {
    if (!readyToLoad) return;
    if (playerAPI) return;
    if (loadingStatusRef.current) return;
    loadingStatusRef.current = true;
    const playerApiConfig = new EvercoastPlayerApiConfig();
    const root = location.origin + '/';
    playerApiConfig.root = root;
    console.log(`readyToLoad ${id}`);
    const renderSystem = new EvercoastThreeJSRenderSystem(renderer);
    renderSystem.onAssetCreated = (meshAsset) => {
      console.log(`onAssetCreated ${id}`);
      setIsLoaded(true);

      const size = new Vector3();
      if (hologramIndicatorRef.current && hologramIndicatorRef.current.geometry) {
        hologramIndicatorRef.current.geometry.computeBoundingBox();
        hologramIndicatorRef.current.geometry.boundingBox.getSize(size);
        const gsapAnim = gsap.to(hologramIndicatorRef.current.material.uniforms.uStripeAlpha, {
          value: 0,
          duration: 0.5,
          ease: 'none',
          onComplete: () => {
            gsapAnim.kill();
          },
        });
      } else {
        console.warn('hologramIndicatorRef.current is not available for hologram', id);
      }

      // renderer.localClippingEnabled = true;

      // meshAsset.material.clippingPlanes = [clippingPlane];
      meshAsset.material.transparent = true;
      meshAsset.material.depthTest = true;
      meshAsset.material.onBeforeCompile = function (shader) {
        EvercoastPlayerMaterial(shader, settings, size, position);
        meshAsset.material.userData.shader = shader;
        setMeshShaderReady(true);
      };

      setMeshAsset(meshAsset);
      setPlayerReady(true);
      setLoadedHologram(id);

      setTimeout(() => {
        renderFrame();
      }, 0);
    };
    playerApiConfig.renderSystem = renderSystem;
    playerApiConfig.maxFramerate = detection.isMobile ? 15 : 30;

    const playerApiInstance = new EvercoastPlayerApi(renderer.getContext(), playerApiConfig);
    playerApiInstance.open(streamURL);

    setPlayerAPI(playerApiInstance);
  }, [readyToLoad]);

  const onPausedHanlder = useCallback(() => {
    setPlayerIsPlaying(false);
  }, [setPlayerIsPlaying]);

  const onResumedHandler = useCallback(() => {
    setPlayerIsPlaying(true);
  }, [setPlayerIsPlaying]);

  const onStartedHandler = useCallback(() => {
    setPlayerIsPlaying(true);
    // if (DEBUG_MODE) {
    //   onQuestionOpen && onQuestionOpen(id);
    //   setSkipButtonActive(false);
    //   setTimeout(() => {
    //     playerAPI && playerAPI.pause();
    //   }, 0);
    //}
  }, [setPlayerIsPlaying, playerAPI]);

  const onErrorHandler = useCallback((e) => {
    console.error(`EverCoast Error (${id}):`, e);
  }, []);

  const onEndedHandler = useCallback(
    (e) => {
      onQuestionOpen && onQuestionOpen(id);
      dispatch({ type: EVENT_OPEN_QUESTION, payload: { id } });
      setSkipButtonActive(false);
      // setHologramAsInactive();
    },
    [setPlayerIsPlaying, setSkipButtonActive]
  );

  // Add Events
  useEffect(() => {
    if (!playerAPI) return;
    playerAPI.onPaused.add(onPausedHanlder);
    playerAPI.onResumed.add(onResumedHandler);
    playerAPI.onStarted.add(onStartedHandler);
    playerAPI.onError.add(onErrorHandler);
    playerAPI.onEnded.add(onEndedHandler);
    //console.log(playerAPI);

    if (showAtStart) {
      setIsVisible(true);
      setCtaActive(true);
      setLabelActive(true);
    }

    return () => {
      playerAPI.onPaused.remove(onPausedHanlder);
      playerAPI.onResumed.remove(onResumedHandler);
      playerAPI.onStarted.remove(onStartedHandler);
      playerAPI.onStarted.remove(onErrorHandler);
      playerAPI.onStarted.remove(onEndedHandler);
    };
  }, [playerAPI]);

  useEffect(() => {
    if (playerAPI && playerReady) {
      setTimeout(() => {
        renderFrame();
      }, 100);
      const volumeVal = useAppStore.getState().sound ? 1 : 0;
      playerAPI.setVolume(volumeVal);
      if (isThisHologramActive) {
        if (!isVisible) setIsVisible(true);
      }
    }
  }, [playerReady, isThisHologramActive, isVisible, setIsVisible]);

  const setHologramAsInactive = () => {
    // console.log('setHologramAsInactive');
    // Get the latest Hologram state
    const hologramActiveState = useAppStore.getState().hologramActive;
    // console.info('hologramActiveState:', hologramActiveState);
    if (hologramActiveState && hologramActiveState.id === id) {
      // console.info(`${id} setHologramAsInactive`);
      setHologramActive(false);
    }
  };

  const getWorldCenter = useCallback(() => {
    const center = new Vector3();
    hologramBoundingBox.getCenter(center);
    const worldCenter = rootObj.current.localToWorld(center);
    //console.info('worldCenter:', worldCenter);
    return worldCenter;
  }, [hologramBoundingBox, rootObj]);

  const getHologramLookRay = useCallback(
    (center) => {
      const dummyDirectionObj = new Object3D();
      const direction = new Vector3();
      // Get center
      const worldCenter = center || getWorldCenter();
      // Calculate
      rootObj.current.updateMatrix();
      dummyDirectionObj.applyMatrix4(rootObj.current.matrix);
      dummyDirectionObj.rotateY(-Math.PI * 0.75);
      dummyDirectionObj.getWorldDirection(direction);
      const hologramRay = new Ray(worldCenter, direction);
      return hologramRay;
    },
    [rootObj, hologramIndicatorRef]
  );

  const setHologramAsActive = useCallback(() => {
    const center = getWorldCenter();
    // Raise up a bit
    center.y += 0.5;

    // const rayLookingSlightlyUp = (new Ray()).copy(rayLookingForward);

    const rayLookingForward = getHologramLookRay(center);

    setHologramActive({
      id,
      boundObj: hologramIndicatorRef.current,
      centerPos: center,
      ray: rayLookingForward,
      rotation: rotation,
      playerAPI: playerAPI,
      limitOrbitRange,
    });
  }, [playerAPI, setHologramActive]);

  // useEffectWithPrevious(([prevInteractive]) => {
  //   if (!prevInteractive && isThisHologramActive){
  //     setSkipButtonActive(true);
  //   }
  // }, [isThisHologramActive]);

  useEffect(() => {
    if (playerAPI) {
      const storeSubscriptions = [
        useAppStore.subscribe(
          (state) => state.sound,
          (soundOn) => {
            if (!playerAPI) return;
            const volume = {
              value: soundOn ? 0 : 1,
            };
            const gsapAnim = gsap.to(volume, {
              value: soundOn ? 1 : 0,
              duration: detection.isMobile ? 0 : 1,
              onUpdate: () => {
                playerAPI && playerAPI.setVolume(volume.value);
              },
              onComplete: () => {
                // console.log(gsapAnim);
                // gsapAnim.kill();
              },
            });
          }
        ),
        useAppStore.subscribe(
          (state) => state.hologramActive,
          (hologramActiveObject) => {
            console.info('hologramActiveObject:', hologramActiveObject);
            console.info('id:', id);
            hologramActiveObjectRef.current = hologramActiveObject;
            let thisHologramIsInteractive = false;
            if (hologramActiveObject) {
              thisHologramIsInteractive = id === hologramActiveObject.id;
            }
            // console.info('hologramActiveObject:', hologramActiveObject);
            // console.info('id:', id);
            // console.info('thisHologramIsInteractive:', thisHologramIsInteractive);
            console.info(`setInteractive ${hologramActiveObject.id} ${thisHologramIsInteractive}`);
            setIsThisHologramActive(thisHologramIsInteractive);
          }
        ),
        // useAppStore.subscribe(
        //   (state) => state.view,
        //   (value) => {
        //   }
        // ),
        useAppStore.subscribe(
          (state) => state.triviaHologramComplete,
          (value) => {
            if (value === id) {
              playerAPI && playerAPI.pause();
              setIsThisHologramActive(false);
              close();
            }
          }
        )
      ];

      return () => {
        console.log(`Disposing ${id}`);
        if (playerAPI) {
          playerAPI.pause();
          playerAPI.close();
        }
        setHologramAsInactive();
        //setIsVisible(false);
        // Unsubscribe
        storeSubscriptions.map((sub) => sub());
      };
    }
  }, [playerAPI]);

  useEffect(() => {
    // Dispose of mesh
    if (meshAsset) {
      return () => {
        console.info('Disposing of meshAsset', id);
        meshAsset?.geometry.dispose();
        meshAsset?.material?.map.dispose();
        meshAsset?.material.dispose();
      };
    }
  }, [meshAsset]);

  function renderFrame() {
    if (playerAPI) {
      playerAPI.beginRenderFrame();
      playerAPI.update();
      if (playerAPI.render()) {
        if (playerAPI.disabled) {
          playerAPI.disabled = false;
        }
      }
      playerAPI.endRenderFrame();
    }
  }

  const [startTempRender, stopTempRender] = useFramePerf(
    function EvercoastPlayerRenderLoopUseFrame() {
      if (isVisible && !hologramActiveObjectRef.current) {
        renderFrame();
      }
    },
    { fps: 15, name: 'evercoast-render' }
  );
  useEffect(() => {
    startTempRender();
  }, [startTempRender]);

  // const rafLoop = useCallback(function EvercoastPlayerRenderLoop(){
  //   if (isVisible) {
  //     renderFrame();
  //   }
  // }, [isVisible]);
  // const [start, stop] = useFramePerf(rafLoop, { fps: 30 });
  // useEffect(() => {
  //   start && start();
  //   return () => {
  //     stop();
  //   };
  // }, [start, stop]);

  const close = useCallback(() => {
    if (shadowRef.current) shadowRef.current.visible = false;
    setLabelActive(false);
    setCtaActive(false);
    setHologramAsInactive();
    dispatch(EVENT_HOLOGRAM_DISAPPEAR);
    const tl = gsap.timeline({
      onComplete: () => {
        removeHologramFromActiveHolograms(id);
        // Cleanup
        tl.kill();
      },
    });

    tl.add(
      gsap.to(giftBoxRef.current.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.5,
        ease: 'power1.out',
      }),
      0
    );

    if (meshAssetRef.current?.material?.userData?.shader?.uniforms?.uReveal) {
      tl.to(
        meshAssetRef.current.material.userData.shader.uniforms.uReveal,
        {
          value: 0,
          duration: 2.4,
          ease: 'expo.out',
        },
        0
      );
    } else {
      console.warn('meshAssetRef.current.material.userData.shader.uniforms.uReveal is not set');
    }
  }, [setCtaActive, setLabelActive]);

  const setShaderUniformsToVisible = function () {
    // Make sure to set shader visibilities
    if (hologramIndicatorRef.current?.material?.uniforms?.uReveal) {
      hologramIndicatorRef.current.material.uniforms.uReveal.value = 0;
    }
    if (meshAssetRef.current?.material?.userData?.shader?.uniforms?.uReveal) {
      meshAssetRef.current.material.userData.shader.uniforms.uReveal.value = 1;
      shadowRef.current.material.opacity = meshAssetRef.current.material.userData.shader.uniforms.uReveal.value * 0.7;
    }
  };

  useEffect(() => {
    if (!meshAssetRef.current) return;
    if (!hologramIndicatorRef.current) return;
    if (!meshShaderReady) return;

    if (isVisible && !showAtStart) {
      dispatch(EVENT_HOLOGRAM_APPEAR);
    }

    console.log('SETTING VISIBLE', isVisible);

    const tl = gsap.timeline({
      paused: false,
      onStart: () => {
        console.log('gsap isVisible onStart', isVisible);
      },
      onComplete: () => {
        console.log('gsap isVisible onComplete', isVisible);
        if (!isVisible) {
          setHologramAsInactive();
          // cleanup
          tl.kill();
        } else {
          // Visible
          setShaderUniformsToVisible();
        }
      },
    });

    const giftBoxScale = !isVisible ? 1 : 0;
    tl.add([
      gsap.to(giftBoxRef.current.scale, {
        x: giftBoxScale,
        y: giftBoxScale,
        z: giftBoxScale,
        duration: 0.5,
        ease: 'power1.out',
      })
    ]);
    if (hologramIndicatorRef.current?.material?.uniforms?.uReveal) {
      tl.add(
        gsap.to(hologramIndicatorRef.current.material.uniforms.uReveal, {
          value: !isVisible ? 1 : 0,
          duration: 2.4,
          ease: 'expo.out',
        }),
        0
      );
    }
    if (meshAssetRef.current?.material?.userData?.shader?.uniforms?.uReveal) {
      tl.add(
        gsap.to(meshAssetRef.current.material.userData.shader.uniforms.uReveal, {
          value: isVisible ? 1 : 0,
          duration: 2.4,
          ease: 'expo.out',
          onUpdate: () => {
            shadowRef.current.material.opacity =
              _get(meshAssetRef, 'current.material.userData.shader.uniforms.uReveal.value', 0) * 0.7;
          },
        }),
        0
      );
    }
    return () => {
      tl && tl.kill();
    };
  }, [isVisible, meshShaderReady]);

  const clickToPlay = useCallback(
    (ev) => {
      // if (ev.distance > MAX_DISTANCE_GIFTBOX || ev.intersections.length > MAX_OBSTACLES_GIFTBOX) return;
      console.log('clickToPlay');
      if (enableTransform) return;
      if (!playerAPI) return;
      if (!onBoardingCompleted) return;
      if (playerIsPlaying) return;
      if (question) return;
      playerAPI.play();
      setHologramAsActive();
      setIsVisible(true);
      setCtaActive(false);
      dispatch(EVENT_HOLOGRAM_PLAY);
    },
    [setEnableTransform, enableTransform, playerAPI, setIsVisible, setHologramAsActive, onBoardingCompleted, question]
  );

  const clickToSkip = useCallback(
    (ev) => {
      onQuestionOpen && onQuestionOpen(id);
      dispatch({ type: EVENT_OPEN_QUESTION, payload: { id } });
      setSkipButtonActive(false);
      setTimeout(() => {
        playerAPI && playerAPI.pause();
      }, 0);
    },
    [playerAPI]
  );

  const ifMikeIsStillActive = function () {
    return activeHologramsRef && activeHologramsRef.current.includes('MIKESIEVERT');
  };

  const clickToOpen = useCallback(
    (ev) => {
      // console.log('OI THE DISTANCE ', ev.distance);
      // console.log('OI THE OBSTACLES ', ev.intersections.length);
      // console.log(ev.distance > MAX_DISTANCE_GIFTBOX || ev.intersections.length > MAX_OBSTACLES_GIFTBOX);

      if (ev.distance > MAX_DISTANCE_GIFTBOX || ev.intersections.length > MAX_OBSTACLES_GIFTBOX) return;
      if (ev && ev.nativeEvent) {
        if (ev.nativeEvent.shiftKey) {
          setEnableTransform((val) => !val);
          return;
        }
      }
      if (id !== 'MIKESIEVERT') {
        if (ifMikeIsStillActive()) {
          return;
        }
      }
      if (hologramActiveObjectRef.current && hologramActiveObjectRef.current.id !== id) {
        return;
      }
      if (enableTransform) return;
      if (!onBoardingCompleted) return;
      if (question) return;
      setHologramAsActive();
      setLabelActive(true);
      if (!playerIsPlaying) setCtaActive(true);
      if (isLoaded) {
        setIsVisible(true);
      }
    },
    [
      isLoaded,
      setEnableTransform,
      enableTransform,
      playerAPI,
      setIsVisible,
      setHologramAsActive,
      playerIsPlaying,
      onBoardingCompleted,
      question
    ]
  );

  // Set render order on the rootObj
  useEffect(() => {
    if (rootObj && rootObj.current) {
      rootObj.current.renderOrder = 1;
    }
  }, [rootObj]);

  const debugBox = useRef();
  useFrame(() => {
    if (DEBUG_MODE && debugBox && rootObj && playerReady) {
      // debugBox.current.position.fromArray(position);
      // debugBox.current.rotation.set(rotation[0], rotation[1] + Math.PI * 0.75, rotation[2]);
      const ray = getHologramLookRay();
      ray.at(1, debugBox.current.position);
    }
  });

  const showLabel = useMemo(() => {
    if (detection.isMobile && ((labelActive && playerIsPlaying) || question)) {
      return false;
    } else {
      return labelActive;
    }
  }, [labelActive, playerIsPlaying, question]);

  return (
    <>
      {rootObj && enableTransform && <TransformControlsWrapper name={id} object={rootObj.current} />}
      {DEBUG_MODE && (
        <Box ref={debugBox} args={[0.1, 0.1, 0.1]}>
          <meshBasicMaterial color="blue" />
        </Box>
      )}
      <group position={position}>
        <EvecoastPlayerGiftBox scale={1.2} ref={giftBoxRef} />
      </group>

      <group visible={isInVisibleRange} ref={rootObj} rotation={rotation} scale={scale} position={position}>
        {inXRSession && isLoaded && isVisible && skipButtonActive && (
          <group rotation={[0, -Math.PI * 0.75, 0]}>
            <group position={[0, 0, 0.35]}>
              <VRGenericButton
                bgColor={COLOR_MAGENTA}
                color={'#ffffff'}
                fontSize={30}
                position={[0, 0.8, 0]}
                text={'PLAY'}
                visible={!playerIsPlaying}
                onSelectHandler={clickToPlay}
                size={new Vector2(0.65, 0.2).multiplyScalar(0.7)}
              />
              <VRGenericButton
                bgColor={'#ffffff'}
                color={'#000000'}
                fontSize={25}
                position={[0, 0.6, 0]}
                text={'SELECT ANSWER'}
                onSelectHandler={clickToSkip}
                size={new Vector2(0.65, 0.2).multiplyScalar(0.6)}
              />
            </group>
          </group>
        )}
        <Interactive
          onSelect={(ev) => {
            console.log('ON SELECT', id);
            clickToPlay();
          }}
          onSqueeze={() => {
            console.log('ON SQUEZE');
          }}
          onHover={() => {
            console.log('ON HOVER');
          }}
          onSqueezeStart={() => {
            console.log('ON SQUEEZE START');
          }}
        >
          <EvecoastPlayerBox
            onClick={(ev) => {
              ev.stopPropagation();
              clickToOpen(ev);
            }}
            ref={boundObj}
            position={[0, 0.875, 0]}
            rotation={[0, Math.PI / 4, 0]}
          />
        </Interactive>
        <HologramIndicator position={[0, 1.25, 0]} ref={hologramIndicatorRef} />
        {onBoardingCompleted && (
          <HologramCtas
            isLoaded={isLoaded}
            isVisible={isVisible}
            interactive={isThisHologramActive}
            ctaActive={ctaActive}
            skipButtonActive={skipButtonActive}
            clickToPlay={clickToPlay}
            clickToSkip={clickToSkip}
          />
        )}
        {meshAsset && isVisible && (
          <>
            <primitive ref={meshAssetRef} object={meshAsset} />
            <Shadow
              visible={true}
              scale={0.9}
              ref={shadowRef}
              color="#222222"
              position={[0, 0.01, 0]}
              colorStop={0}
              fog={false}
            />
            {showLabel && <HologramLabel name={name} jobTitle={jobTitle} />}
          </>
        )}
      </group>
    </>
  );
};

export default EvercoastPlayer;
