import { useDetectGPU } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useMemo } from 'react';
import performanceSettings from '@settings/settings.performance.js';
import { GPU_TIER } from '@settings/settings.params.js';

let gpuInfoCached;

export default function usePerformanceSettings(){
  const gl = useThree(s => s.gl);
  const gpuDetectInfo = useDetectGPU({ glContext: gl.getContext() });
  const settings = useMemo(() => {
    const gpu = gpuDetectInfo;
    const tier = GPU_TIER || gpu.tier;
    if (tier >= 3){
      return performanceSettings['tier3'];
    } else {
      return performanceSettings['tier2'];
    }
  }, [gpuDetectInfo]);
  return settings;
}
