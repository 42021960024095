export const ROUTES = {
  landing: {
    path: '/',
  },
  metaverse: {
    path: '/metaverse',
  },
  onBoarding: {
    path: '/on-boarding',
  },
  endScreen: {
    path: '/end',
  },
  bridge: {
    path: '/bridge',
  },
  bridge2: {
    path: '/bridge2',
  },
};

export const VIEW_METAVERSE = 'metaverse';
export const VIEW_ONBAORDING = 'onboarding';

export const ROUTE_PATHS = (() => {
  const paths = {};
  Object.keys(ROUTES)
    .forEach((routeKey) => {
      paths[routeKey] = ROUTES[routeKey].path;
    });
  return paths;
})();

export const CANVAS_MAX_LOAD_PERCENTAGE = 100;
