/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useContext, createContext, useEffect } from 'react';
import { useGLTF, Merged } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import useEffectWithPrevious from 'use-effect-with-previous';
import gsap from 'gsap';
import { BoxGeometry, InstancedMesh } from 'three';
import { commonMaterials } from '@components/canvas/common-materials.js';
import { useFramePerf } from '@hooks/use-raf-perf.js';
import { useFrameData } from '../use-frame-data';

const context = createContext();
export function NavigationMarkerInstances({ children, ...props }) {
  const { nodes } = useGLTF('/3d/models/navigation-marker-transformed.glb');

  const boxGeom = useMemo(() => {
    //return new BoxGeometry(0.65, 1.75, 0.35);
    return new BoxGeometry(1, 1.75, 1);
  }, []);
  const boxMesh = useMemo(() => {
    const hitBox = new InstancedMesh(boxGeom, commonMaterials.transparent);
    hitBox.name = 'Hologram Hit Box';
    return hitBox;
  }, [boxGeom]);
  const instances = useMemo(
    () => ({
      NavigationMarkeranimated: nodes.NavigationMarker_animated,
      NavigationMarkerstatic: nodes.NavigationMarker_static,
      hitBox: boxMesh,
    }),
    [nodes]
  );
  const { navMarker, navArrow } = useFrameData;
  const { NavigationMarkeranimated, NavigationMarkerstatic } = instances;
  useFrame(({ clock }, delta) => {
    //console.info('_:', _);
    navMarker.rotationY -= delta * 1;
    navMarker.positionY = (1 + Math.sin(clock.elapsedTime * 1.5)) * 0.2;
    navMarker.positionY = (1 + Math.sin(clock.elapsedTime * 1.5)) * 0.2;

    navArrow.scaleX = NavigationMarkerstatic.scale.z = 1 + (1 + Math.sin(clock.elapsedTime * 1.5)) * 0.1;
  });

  return (
    <Merged meshes={instances} {...props}>
      {(instances) => <context.Provider value={instances} children={children} />}
    </Merged>
  );
}

export function NavigationMarkerModel({ active, ...props }) {
  const instances = useContext(context);
  const markerRef = useRef();
  const arrowRef = useRef();
  const markerGroupRef = useRef();
  const BASE_SCALE = 0.9;

  const { navMarker, navArrow } = useFrameData;
  useFrame(() => {
    markerRef.current.rotation.y = navMarker.rotationY * 0.5;
    markerRef.current.position.y = navMarker.positionY;
    arrowRef.current.scale.x = navArrow.scaleX;
  });

  // useEffectWithPrevious(([prevActive]) => {
  //   console.info('active:', active);
  //   if (active === false) {
  //     gsap.to(markerGroupRef.current.scale, { x: BASE_SCALE, y: BASE_SCALE, z: BASE_SCALE, duration: 0.5, delay: 0.3, ease: 'power1.out' });
  //   } else {
  //     gsap.to(markerGroupRef.current.scale, { x: 0, y: 0, z: 0, duration: 0.5, delay: 0, ease: 'power1.out' });
  //   }
  // }, [active]);

  useEffect(() => {
    if (markerRef && arrowRef) {
      const material = markerRef.current.instance.current.material;
      material.envMapIntensity = 1;
      material.metalness = 0;
      material.roughness = 0.3;
      //material.envMap = scene.environment;
    }
  }, [markerRef, arrowRef]);
  return (
    <group {...props} dispose={null}>
      <instances.hitBox scale={active ? 0 : 1} poswsition={[0, 1, 0]} />
      <group ref={markerGroupRef} scale={!active ? BASE_SCALE : 0} position={[0, 0.15, 0]}>
        <instances.NavigationMarkeranimated ref={markerRef} name="NavigationMarker_animated" position={[0, 0.2, 0]} />
        <instances.NavigationMarkerstatic
          ref={arrowRef}
          name="NavigationMarker_static"
          position={[0, 0, 0]}
          scale={[1, 0.5, 1]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/3d/models/navigation-marker-transformed.glb');
